// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.voucher-create-content {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  
  .voucher-create-title {
    color: var(--Dark-600, #090a0a);
  
    /* Large/Normal/Bold */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
  }


  .voucher-create-one-field {
    margin-top: 20px;
    display: flex;
    flex: 1 1;
  }
  .voucher-create-one-field-name {
    margin-bottom: 8px;
  }
  
  .voucher-create-content-container {
    display: flex;
  }
  .voucher-create-content-left {
    display: flex;
    flex-direction: column;
    flex: 6 1;
  }
  .voucher-create-content-right {
    flex: 4 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/voucher-create/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,+BAA+B;;IAE/B,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,aAAa;EAClC;;;EAGA;IACE,gBAAgB;IAChB,aAAa;IACb,SAAO;EACT;EACA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAO;EACT;EACA;IACE,SAAO;EACT","sourcesContent":[".voucher-create-content {\n    background-color: white;\n    padding: 24px;\n    border-radius: 12px;\n    margin-bottom: 20px;\n  }\n  \n  .voucher-create-title {\n    color: var(--Dark-600, #090a0a);\n  \n    /* Large/Normal/Bold */\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 24px; /* 133.333% */\n  }\n\n\n  .voucher-create-one-field {\n    margin-top: 20px;\n    display: flex;\n    flex: 1;\n  }\n  .voucher-create-one-field-name {\n    margin-bottom: 8px;\n  }\n  \n  .voucher-create-content-container {\n    display: flex;\n  }\n  .voucher-create-content-left {\n    display: flex;\n    flex-direction: column;\n    flex: 6;\n  }\n  .voucher-create-content-right {\n    flex: 4;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
