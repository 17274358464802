/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import NewsCreatePage from "../news-create/NewsCreatePage.js";
import { useDispatch } from "react-redux";
import { NewsActions } from "../../app/services/news/news.slice.js";

function NewsEditPage() {
  const dispatch = useDispatch();
  const params = useParams();

  const getDetail = () => {
    dispatch(
      NewsActions.getNewsById({
        id: params.id,
      })
    );
  };

  useEffect(() => {
    if (params.id) {
      getDetail();
    }

    return () => {
      dispatch(NewsActions.getNewByIdSuccess(undefined));
    };
  }, [dispatch, params]);

  return <NewsCreatePage />;
}

export default NewsEditPage;
