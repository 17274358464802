import React from "react";
import { useChat } from "./ChatProvider";
import { useMemo } from "react";
import {
  Badge,
  Popover,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  styled,
} from "@mui/material";
import SVGChat from "../../images/icons/SVGChat";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const ChatList = () => {
  const {
    setActiveChats,
    pendingChats,
    handleBadgeClick,
    activeChats,
    currentChat,
    setCurrentChat,
    joinChat,
    acceptChat,
    truncateText,
    searchText,
    seenChat,
    handleClose,
    anchorEl,
    handleSearch,
    pendingUsersCount,
  } = useChat();

  const open = Boolean(anchorEl);
  const handleChatClick = (chat) => {
    setCurrentChat(chat);
    if (!chat.support) {
      acceptChat(chat);
    } else {
      setActiveChats((prev) => {
        prev.map((item) => {
          if (item?._id === chat?._id) {
            return { ...item, status: "active" };
          } else if (
            item?.messages[item?.messages?.length - 1]?.sender === "user"
          ) {
            return { ...item, status: "pending" };
          }
          return item;
        });
        return prev;
      });
    }
    seenChat(chat);
    joinChat(chat);
    handleClose();
  };

  //Đây là CSS
  const StyledBadge = useMemo(
    () =>
      styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
          top: "8px !important",
          right: "3px !important",
        },
      })),
    []
  );

  const StyledPopover = useMemo(
    () =>
      styled(Popover)(({ theme }) => ({
        "& .MuiPopover-paper": {
          top: "61px !important",
        },
      })),
    []
  );

  const allChats = useMemo(() => {
    const allChats = [...pendingChats, ...activeChats];
    const uniqueChatsMap = new Map();

    allChats.forEach((chat) => {
      const email = chat?.user?.email;
      if (!email) return; // Skip if no email

      if (!uniqueChatsMap.has(email) || chat?.status === "pending") {
        uniqueChatsMap.set(email, {
          ...chat,
          user: { ...chat.user, email }, // Ensure email is included
        });
      }
    });

    return Array.from(uniqueChatsMap.values());
  }, [pendingChats, activeChats]);

  const filteredChats = useMemo(() => {
    if (!searchText.trim()) {
      return [...pendingChats, ...activeChats];
    }
    return allChats.filter((chat) =>
      chat?.user?.email?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [allChats, searchText]);

  return (
    <div>
      <StyledBadge badgeContent={pendingUsersCount} color="error">
        <div
          onClick={handleBadgeClick}
          className="bg-[#FF8900] w-[45px] h-[45px] rounded-full cursor-pointer flex items-center justify-center"
        >
          <SVGChat className="p-2.5" />
        </div>
      </StyledBadge>
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="w-[368px]">
          <h1 className="w-[90%] text-left font-bold text-2xl mx-auto my-2">
            Đoạn Chat
          </h1>
          <div className="w-[90%] flex justify-center mx-auto mt-4 rounded-full bg-[#f2f4f5]">
            <InputBase
              className="flex-1"
              sx={{ ml: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "Tìm kiếm" }}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <IconButton
              className="bg-red-600"
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div>
            <List sx={{ overflow: "auto", maxHeight: "80%" }}>
              {filteredChats.map((item, index) => (
                <ListItem
                  key={item._id}
                  alignItems="flex-start"
                  className="hover:bg-[#f2f4f5] cursor-pointer"
                  onClick={() => handleChatClick(item)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={item?.user?.email?.toUpperCase()}
                      src="/static/images/avatar/1.jpg"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ fontWeight: "bold" }}
                    primary={item?.user?.email}
                    secondary={
                      <Typography
                        sx={{
                          display: "inline",
                          fontWeight:
                            currentChat && currentChat._id === item._id
                              ? "normal"
                              : item?.status === "pending"
                              ? "700"
                              : "500",
                          paddingRight: "5px",
                          maxWidth: "100%",
                          display: "inline-block",
                          overflowWrap: "break-word",
                        }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item?.messages[item?.messages?.length - 1]?.sender ===
                          "support" && (
                          <span style={{ flexShrink: 0, marginRight: "2px" }}>
                            Bạn:
                          </span>
                        )}
                        <span
                          style={{
                            minWidth: 0,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {truncateText(
                            item?.messages[item?.messages?.length - 1]?.content,
                            50
                          )}
                        </span>
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </StyledPopover>
    </div>
  );
};

export default ChatList;
