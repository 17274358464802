import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/base/pagination/Pagination";
import Table from "../../components/base/table/Table";
import {
  ApiKeyActions,
  ApiKeySelectors,
} from "../../app/services/api-key/api-key.slice";
import ApiKeyOption from "./ApiKeyOption";
import ApiKeySearch from "./ApiKeySearch";

const ApiKeyList = () => {
  const dispatch = useDispatch();
  const apiKeyList = useSelector(ApiKeySelectors.apiKeyList);
  const pagination = useSelector(ApiKeySelectors.pagination);
  const keySearch = useSelector(ApiKeySelectors.keySearch);

  useEffect(() => {
    dispatch(ApiKeyActions.getAllApiKey());
  }, [dispatch, pagination.page, keySearch]);

  const thead = [
    {
      name: "STT",
      style: { width: 20 },
      className: "",
    },
    {
      name: "Key",
      style: { width: 120 },
    },
    {
      name: "Phân loại",
      style: { width: 120 },
    },
    {
      name: "Thao tác",
      style: { width: 100 },
    },
  ];

  const genDataTable = () => {
    try {
      return apiKeyList.map((x, index) => {
        return [
          { value: (pagination.page - 1) * pagination.pageSize + (index + 1) },
          {
            value: `${x?.key}`,
          },
          {
            value: `${x?.type}`,
          },
          {
            value: <ApiKeyOption id={x._id} value={x} />,
          },
        ];
      });
    } catch (error) {
      return [];
    }
  };

  return (
    <div className="news-list">
      <ApiKeySearch />
      <Table head={thead} body={genDataTable()} />
      <div className="service-pagination">
        <Pagination
          {...pagination}
          onChange={(page) => {
            dispatch(
              ApiKeyActions.setPagination({
                ...pagination,
                page,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default ApiKeyList;
