import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  SupportAccountActions,
  SupportAccountSelectors,
} from "../../app/services/support-account/support-account.slice";
import MST from "../../components";
import { adminMenu } from "../../components/menu/MainMenu";
import "./style.css";
function SupportAccountCreatePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const supportAccountDetail = useSelector(SupportAccountSelectors.detail);
  const params = useParams();
  const id = params?.id;
  const isEdit = !!id;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedPages, setSelectedPages] = useState([]);
  const [errorMessage, setErrorMessage] = useState({
    username: "",
    password: "",
  });
  // console.log(errorMessage);

  useEffect(() => {
    if (id) {
      dispatch(SupportAccountActions.getSupportAccountById({ id }));
    } else {
      dispatch(SupportAccountActions.setSupportAccountById(undefined));
    }
  }, [id]);

  useEffect(() => {
    setUsername(supportAccountDetail?.username || "");
    setPassword(supportAccountDetail?.password || "");
    setSelectedPages(supportAccountDetail?.allowedPages || []);
  }, [supportAccountDetail]);
  const handleCheckboxChange = (pathName) => {
    if (selectedPages.includes(pathName)) {
      setSelectedPages(selectedPages.filter((p) => p !== pathName));
    } else {
      setSelectedPages([...selectedPages, pathName]);
    }
  };
  const validate = (callback) => {
    const tempEM = {
      username: "",
      password: "",
    };

    let errorCount = 0;

    if (isEmpty(username)) {
      errorCount++;
      tempEM.username = "username không được để trống";
    }
    if (isEmpty(password) && !id) {
      errorCount++;
      tempEM.password = "password không được để trống";
    }

    if (errorCount === 0) {
      callback();
    } else {
      setErrorMessage(tempEM);
    }
  };

  const onCreate = () => {
    dispatch(
      SupportAccountActions.create({
        onSuccess: (id) => {
          toast.success("Tạo mới support account thành công");
          navigate(`/services/settings/support-account/edit/${id}`);
        },
        body: {
          username,
          password,
          allowedPages: selectedPages,
        },
      })
    );
  };

  const onEdit = () => {
    dispatch(
      SupportAccountActions.update({
        onSuccess: () => {
          toast.success("Cập nhật support account thành công");
        },
        id: supportAccountDetail._id,
        body: {
          username,
          password,
          allowedPages: selectedPages,
        },
      })
    );
  };

  return (
    <MST.Container
      title={
        supportAccountDetail
          ? "Cập nhật support account"
          : "Tạo mới support account"
      }
      right={
        <div className="d-flex">
          <MST.Button
            onClick={() => navigate("/services/settings/support-account")}
            type="outlined"
            className="mr-8"
          >
            Huỷ
          </MST.Button>
          <MST.Button
            onClick={
              supportAccountDetail
                ? () => validate(onEdit)
                : () => validate(onCreate)
            }
          >
            Lưu lại
          </MST.Button>
        </div>
      }
    >
      <div className="service-create-content-container">
        <div className="service-create-content-left">
          <div className="service-create-content">
            <div className="service-create-title">
              Thông tin support account
            </div>

            <div className="service-create-one-field">
              <div className="service-create-one-field-name">
                Username<span className="color-red"> *</span>
              </div>
              <div>
                <MST.Input
                  errorMessage={errorMessage?.username}
                  placeholder="Nhập username"
                  maxLength={225}
                  value={username}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      username: "",
                    });
                    setUsername(e.target.value);
                  }}
                  disabled={isEdit}
                />
              </div>
            </div>

            <div className="service-create-one-field">
              <div className="service-create-one-field-name">
                Password<span className="color-red"> *</span>
              </div>
              <div>
                <MST.Input
                  errorMessage={errorMessage?.password}
                  placeholder={
                    id
                      ? "Nhập mật khẩu mới khi cần đổi password"
                      : "Nhập password"
                  }
                  value={password}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      password: "",
                    });
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="service-create-one-field">
              <div className="service-create-one-field-name">
                Chọn các trang mà tài khoản được phép sử dụng
              </div>
              <div className="space-y-6">
                {adminMenu?.services?.map((group) => (
                  <div key={group.name} className="p-4 border rounded-lg">
                    <div className="flex items-center gap-4 mb-3">
                      <h3 className="text-lg font-semibold">{group.name}</h3>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                      {group.children?.map((page) => (
                        <div key={page.path}>
                          <label
                            htmlFor={page.path}
                            className="flex items-center gap-4 cursor-pointer"
                          >
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                className="border-gray-300 rounded size-4"
                                id={page.path}
                                checked={selectedPages.includes(page.path)}
                                onChange={() => handleCheckboxChange(page.path)}
                              />
                            </div>
                            <div>
                              <strong className="font-medium text-gray-900">
                                {page.name}
                              </strong>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MST.Container>
  );
}

export default SupportAccountCreatePage;
