import React from "react";

function CogIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.39556 2.6267C6.45584 2.26506 6.76873 2 7.13536 2H8.86467C9.2313 2 9.54419 2.26506 9.60446 2.6267L9.7468 3.48073C9.78836 3.7301 9.95501 3.93781 10.1762 4.06024C10.2256 4.08761 10.2745 4.11589 10.3228 4.14505C10.5395 4.27589 10.8031 4.31658 11.0401 4.22777L11.8513 3.92385C12.1947 3.79523 12.5807 3.93367 12.764 4.25118L13.6286 5.74881C13.8119 6.06632 13.7388 6.46982 13.4558 6.70284L12.7862 7.25405C12.5912 7.41459 12.4945 7.66248 12.4992 7.91502C12.4998 7.94328 12.5 7.97161 12.5 8C12.5 8.02839 12.4998 8.05671 12.4992 8.08497C12.4946 8.33751 12.5912 8.58541 12.7862 8.74594L13.4558 9.29715C13.7388 9.53017 13.8119 9.93367 13.6286 10.2512L12.764 11.7488C12.5807 12.0663 12.1947 12.2048 11.8513 12.0761L11.0401 11.7722C10.8031 11.6834 10.5395 11.7241 10.3228 11.8549C10.2745 11.8841 10.2256 11.9124 10.1762 11.9398C9.95501 12.0622 9.78836 12.2699 9.7468 12.5193L9.60446 13.3733C9.54419 13.7349 9.2313 14 8.86467 14H7.13536C6.76873 14 6.45584 13.7349 6.39556 13.3733L6.25322 12.5193C6.21166 12.2699 6.04502 12.0622 5.82384 11.9398C5.77439 11.9124 5.72551 11.8841 5.67722 11.855C5.46053 11.7241 5.19697 11.6834 4.95993 11.7722L4.14869 12.0761C3.80536 12.2048 3.41937 12.0663 3.23605 11.7488L2.3714 10.2512C2.18808 9.93368 2.26118 9.53018 2.54424 9.29716L3.21381 8.74595C3.40881 8.58542 3.50548 8.33752 3.5008 8.08498C3.50028 8.05672 3.50001 8.02839 3.50001 8C3.50001 7.97161 3.50028 7.94329 3.5008 7.91503C3.50548 7.66249 3.40881 7.41459 3.21381 7.25406L2.54424 6.70285C2.26118 6.46983 2.18808 6.06633 2.3714 5.74882L3.23605 4.25119C3.41937 3.93368 3.80536 3.79524 4.14869 3.92386L4.95992 4.22778C5.19697 4.31658 5.46052 4.2759 5.67721 4.14505C5.7255 4.11589 5.77438 4.08761 5.82384 4.06024C6.04501 3.93781 6.21166 3.7301 6.25322 3.48073L6.39556 2.6267Z"
        stroke="#FF8900"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99986 7.99995C9.99986 9.10452 9.10443 9.99995 7.99986 9.99995C6.89529 9.99995 5.99986 9.10452 5.99986 7.99995C5.99986 6.89538 6.89529 5.99995 7.99986 5.99995C9.10443 5.99995 9.99986 6.89538 9.99986 7.99995Z"
        stroke="#FF8900"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CogIcon;
