import React, { useMemo, useState } from "react";
import Modal from "../../components/base/modal/Modal";
import MST from "../../components";
import { toast } from "react-toastify";
import SysFetch from "../../app/fetch";
import { useDispatch } from "react-redux";
import { ApiKeyActions } from "../../app/services/api-key/api-key.slice";

const ApiKeyImport = ({ isShow, setIsShow }) => {
  const dispatch = useDispatch();
  const [csv, setCsv] = useState(null);

  const handleClose = () => {
    setIsShow(false);
    setCsv(null);
  };

  const handleFileChange = (event) => {
    setCsv(event.target.files[0]);
  };

  const handleSubmit = async () => {
    try {
      if (!csv) {
        toast.warn("Vui lòng nhập file csv");
        return;
      }
      const formData = new FormData();
      formData.append("csv", csv);
      const response = await SysFetch.post("api-key/upload-csv", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.success) {
        toast.success(response.message);
        dispatch(ApiKeyActions.getAllApiKey());
        handleClose();
      }
    } catch (error) {
      toast.error(
        error.response.data.message || "Có lỗi xảy ra khi tạo API KEY"
      );
    }
  };

  const contentModal = useMemo(() => {
    return (
      <div>
        <div className="modal-header">Import CSV</div>
        <div className="modal-body">
          <div className="flex items-center justify-center text-center">
            <label
              htmlFor="csvFile"
              className={`${
                csv ? "w-full" : "w-32"
              } py-3 px-2 border-solid border rounded-md border-[#feb705] cursor-pointer flex items-center gap-2 justify-center`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                />
              </svg>
              <div className="w-full truncate">{csv ? csv.name : "Upload"}</div>
            </label>
            <input
              id="csvFile"
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex jc-between">
            <div />
            <div className="d-flex">
              <MST.Button
                type="outlined"
                className="mr-8"
                onClick={handleClose}
              >
                Huỷ
              </MST.Button>
              <MST.Button onClick={handleSubmit}>Thêm</MST.Button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [csv]);

  return (
    <Modal
      contentWidth={400}
      isShow={isShow}
      onHide={() => {
        handleClose();
      }}
      content={contentModal}
    />
  );
};

export default ApiKeyImport;
