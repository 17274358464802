import React from "react";

const SVGChat = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 42 42"
      fill="none"
      {...props}
    >
      <path
        d="M13.3125 14.5938H28.6875M13.3125 19.7188H21M4.34375 22.2972C4.34375 25.0324 6.26291 27.4132 8.96899 27.8111C10.8969 28.0945 12.8465 28.3109 14.8156 28.4577C15.4138 28.5024 15.9607 28.8161 16.2935 29.3152L21 36.375L25.7065 29.3153C26.0392 28.8162 26.5861 28.5024 27.1844 28.4578C29.1534 28.311 31.1031 28.0947 33.0309 27.8113C35.737 27.4134 37.6562 25.0326 37.6562 22.2974V12.0151C37.6562 9.27991 35.737 6.89912 33.0309 6.50126C29.1044 5.92398 25.0874 5.625 21.0005 5.625C16.9133 5.625 12.8959 5.92403 8.969 6.50142C6.26292 6.8993 4.34375 9.28008 4.34375 12.0153V22.2972Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SVGChat;
