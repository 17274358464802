import "./style.css";

export const languages = ["en", "vi", "hi", "es", "ko", "id", "ur", "ar"];

function LanguageSelect({ selectedLang, onSelectLanguage }) {
  return (
    <div className="language-select">
      {languages.map((lang) => (
        <button
          key={lang}
          onClick={() => onSelectLanguage(lang)}
          className={`language-button mb-2 ${
            selectedLang === lang ? "selected" : ""
          }`}
        >
          <span>{lang.toUpperCase()} &nbsp;</span>
          <span>
            <img
              src={`/images/${lang}.webp`}
              alt={`${lang} flag`}
              className="flag-icon"
            />
          </span>
        </button>
      ))}
    </div>
  );
}

export default LanguageSelect;
