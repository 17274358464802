import * as React from "react";
const SVGSetting = (props) => (
  <div className="icon-setting">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M7.6129 10.1129L11.5 14C12.1904 14.6904 13.3096 14.6904 14 14C14.6904 13.3096 14.6904 12.1904 14 11.5L10.0822 7.58219M7.6129 10.1129L9.27699 8.09223C9.48766 7.83641 9.76944 7.67546 10.0822 7.58219M7.6129 10.1129L4.50955 13.8813C4.18691 14.273 3.70598 14.5 3.19846 14.5C2.26043 14.5 1.5 13.7396 1.5 12.8015C1.5 12.294 1.72696 11.8131 2.11874 11.4905L6.67641 7.73707M10.0822 7.58219C10.4485 7.47295 10.8572 7.45653 11.2439 7.48922C11.3283 7.49636 11.4137 7.5 11.5 7.5C13.1569 7.5 14.5 6.15685 14.5 4.5C14.5 4.05982 14.4052 3.64178 14.2349 3.26519L12.0508 5.44931C11.307 5.27857 10.7215 4.69309 10.5508 3.94931L12.7349 1.76516C12.3583 1.59482 11.9402 1.5 11.5 1.5C9.84314 1.5 8.5 2.84315 8.5 4.5C8.5 4.58626 8.50364 4.67166 8.51078 4.75607C8.57138 5.47304 8.46319 6.26561 7.90777 6.72301L7.83972 6.77906M6.67641 7.73707L3.93934 5H3L1.5 2.5L2.5 1.5L5 3V3.93934L7.83972 6.77906M6.67641 7.73707L7.83972 6.77906M12.25 12.25L10.5 10.5M3.24482 12.75H3.24982V12.755H3.24482V12.75Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
export default SVGSetting;
