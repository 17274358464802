  import React from "react";

  import { CKEditor } from "@ckeditor/ckeditor5-react";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

  import { createCKEditorUploadAdapter } from "./EditorUpload";

function Editor({ onBlur, data, placeholder, onChange, disabled, height }) {
  return (
    <CKEditor
      placeholder={placeholder}
      editor={ClassicEditor}
      data={data}
      onReady={(editor) => {
        editor.editing.view.change((writer) => {
          editor.editing.view.document.getRoot().placeholder = placeholder;
          return writer.setStyle(
            `${height ? "min-height" : "height"}`,
            `${height || "250px"}`,
            editor.editing.view.document.getRoot()
          );
        });

          editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return createCKEditorUploadAdapter(loader);
          };
        }}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  export default Editor;
