import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VoucherActions } from "../../app/services/voucher/voucher.slice";
import { toast } from "react-toastify";
import MST from "../../components";
import EditIcon from "../../components/table-create-service/icons/EditIcon";
import DeleteIcon from "../../components/table-create-service/icons/DeleteIcon";
import Modal from "../../components/base/modal/Modal";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  PromotionActions,
  PromotionSelectors,
} from "../../app/services/promotions/promotion.slice";

function PromotionOptions({ id, handleModal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const promotions = useSelector(PromotionSelectors.promotionList);
  const onHide = () => {
    setIsShow(false);
  };

  const onShow = () => {
    setIsShow(true);
  };

  const onDelete = () => {
    dispatch(
      PromotionActions.delete({
        id,
        onSuccess: () => {
          const updatedPromotions = promotions.filter(
            (promotion) => promotion._id !== id
          );
          dispatch(PromotionActions.setPromotions(updatedPromotions));
          toast.success("Xoá Promotion thành công!");
        },
      })
    );
    onHide()
  };

  const contentModal = useMemo(() => {
    return (
      <div>
        <div className="modal-header">Xoá Promotion</div>
        <div className="modal-body">Bạn có muốn xoá promotion này?</div>
        <div className="modal-footer">
          <div className="d-flex jc-between">
            <div />
            <div className="d-flex">
              <MST.Button type="outlined" className="mr-8" onClick={onHide}>
                Huỷ
              </MST.Button>
              <MST.Button onClick={onDelete}>Xác nhận</MST.Button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [id]);

  return (
    <div className="d-flex jc-center">
      <button
        className="btn-edit mr-8"
        onClick={() => navigate(`/services/promotions/${id}/edit`)}
      >
        <EditIcon />
      </button>
      <button
        className="btn-edit !hover:bg-primary-400 mr-8"
        onClick={() => {
          handleModal({
            open: true,
            promotion: promotions?.find((promotion) => promotion._id === id),
          });
        }}
      >
        <RemoveRedEyeOutlinedIcon fontSize="small" color="primary" />
      </button>
      <button className="btn-delete" onClick={onShow}>
        <DeleteIcon />
      </button>
      <Modal content={contentModal} isShow={isShow} onHide={onHide} />
    </div>
  );
}

export default PromotionOptions;
