import SysFetch from "../../fetch";

const VipLevelRequest = {
  getVipPrices: (servicePackageId) => {
    return SysFetch.get(`vip-levels/${servicePackageId}`);
  },
  updateVipPrices: (servicePackageId, vipPrices) => {
    return SysFetch.put(`vip-levels/${servicePackageId}`, { vipPrices });
  },
  deleteVipPrices: (servicePackageId) => {
    return SysFetch.delete(`vip-levels/${servicePackageId}`);
  },
};

export default VipLevelRequest;
