import React from "react";
import { useDispatch, useSelector } from "react-redux";

import MST from "../../components";
import SearchIcon from "../../images/icons/SearchIcon";
import {
  CustomerActions,
  CustomerSelectors,
} from "../../app/services/customer/customer.slice";

function CustomerDetailSearch() {
  const value = useSelector(CustomerSelectors.searchCodeOrder);
  const dispatch = useDispatch();

  return (
    <div className="search-service-container">
      <MST.Input
        value={value}
        onChange={(e) =>
          dispatch(CustomerActions.setSearchCodeOrder(e.target.value))
        }
        placeholder={"Nhập từ mã đơn hàng tìm kiếm"}
        right={
          <div className="service-search-btn">
            <SearchIcon />
          </div>
        }
      />
    </div>
  );
}

export default CustomerDetailSearch;
