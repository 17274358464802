import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  promotionList: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  promotionDetail: undefined,
  servicePromotionList: undefined,
  search: "",
};

const PromotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    getPromotions: (state, { payload }) => {},
    setPromotions: (state, { payload }) => {
      state.promotionList = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    getPromotionById: (state, { payload }) => {},
    getCreateInfo: () => {},
    createPromotion: (state, { payload }) => {},
    edit: (state, { payload }) => {},
    setPromotionDetail: (state, { payload }) => {
      state.promotionDetail = payload;
    },
    setSearch: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.search = payload;
    },
    setServicesSearch: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.servicesSearch = payload;
    },
    resetSession: (state) => {
      state.promotionList = [];
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.search = "";
    },
    delete: (state, { payload }) => {},
  },
});

const PromotionReducer = PromotionSlice.reducer;
export default PromotionReducer;

export const PromotionActions = PromotionSlice.actions;
export const PromotionSelectors = {
  promotionList: (state) => state.promotion.promotionList,
  pagination: (state) => state.promotion.pagination,
  search: (state) => state.promotion.search,
  servicesSearch: (state) => state.promotion.servicesSearch,
  promotionDetail: (state) => state.promotion.promotionDetail,
  status: (state) => state.promotion.status,
};
