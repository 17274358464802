import * as React from "react";
const SVGMoneyGreen = (props) => (
  <div className="icon-money-green">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        d="M6.97574 6.16385C7.12721 6.04352 7.30714 5.95602 7.5 5.90133V7.76464C7.30345 7.70883 7.12402 7.61996 6.97574 7.50216C6.71325 7.29364 6.61168 7.04831 6.61168 6.83301C6.61168 6.61771 6.71325 6.37237 6.97574 6.16385Z"
        fill="white"
      />
      <path
        d="M8.5 10.7741V8.8919C8.7315 8.94819 8.94252 9.04353 9.11423 9.17232C9.39853 9.38556 9.5 9.62957 9.5 9.83299C9.5 10.0364 9.39853 10.2804 9.11423 10.4937C8.94252 10.6224 8.7315 10.7178 8.5 10.7741Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.83301C4.41015 1.83301 1.5 4.74316 1.5 8.33301C1.5 11.9229 4.41015 14.833 8 14.833C11.5899 14.833 14.5 11.9229 14.5 8.33301C14.5 4.74316 11.5899 1.83301 8 1.83301ZM8.5 4.33301C8.5 4.05687 8.27614 3.83301 8 3.83301C7.72386 3.83301 7.5 4.05687 7.5 4.33301V4.87676C7.08207 4.9508 6.68355 5.11883 6.35372 5.38085C5.87881 5.75811 5.61168 6.28054 5.61168 6.83301C5.61168 7.38547 5.87881 7.9079 6.35372 8.28517C6.68869 8.55127 7.08844 8.71635 7.5 8.78923V10.774C7.26856 10.7177 7.0576 10.6224 6.88592 10.4937L6.30001 10.0542C6.0791 9.88851 5.7657 9.93328 5.60001 10.1542C5.43432 10.3751 5.47908 10.6885 5.69999 10.8542L6.28591 11.2936C6.64138 11.5603 7.06529 11.7234 7.5 11.7932V12.333C7.5 12.6091 7.72386 12.833 8 12.833C8.27614 12.833 8.5 12.6091 8.5 12.333V11.7932C8.93476 11.7234 9.35873 11.5603 9.71424 11.2936C10.211 10.9211 10.5 10.3973 10.5 9.83299C10.5 9.26864 10.211 8.74488 9.71424 8.37233C9.35873 8.10569 8.93476 7.94256 8.5 7.87273V5.90139C8.69279 5.95607 8.87265 6.04356 9.02408 6.16385L9.30067 6.38358C9.51689 6.55534 9.83142 6.5193 10.0032 6.30308C10.1749 6.08686 10.1389 5.77234 9.92269 5.60057L9.64609 5.38085C9.31631 5.11887 8.91786 4.95085 8.5 4.87679V4.33301Z"
        fill="white"
      />
    </svg>
  </div>
);
export default SVGMoneyGreen;
