import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  OrderActions,
  OrderSelectors,
} from "../../app/services/order/order.slice";
import { formatPriceVND } from "../../app/utils/format";
import MST from "../../components";
import Pagination from "../../components/base/pagination/Pagination";
import OrderSearch from "./OrderSearch";
import EyeIcon from "./icons/EyeIcon";
import CheckIcon from "./icons/CheckIcon"; // Add an icon for the "Complete" button
import Modal from "../../components/base/modal/Modal"; // Import modal
import { toast } from "react-toastify";
import ActionStatus from "./ActionStatus";
import {
  CheckLinkActions,
  CheckLinkSelectors,
} from "../../app/services/check-link/check-link.slice";
import OrderRefundAction from "./OrderRefundAction";

const CopyIcon = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const YouTubeChannelInfo = ({ channelInfo }) => {
  const [copied, setCopied] = useState(false);

  if (!channelInfo) return null;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(channelInfo.id).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="youtube-channel-info w-full max-w-2xl mx-auto bg-gradient-to-r from-red-500 to-red-700 rounded-xl shadow-2xl overflow-hidden">
      <div className="channel-header p-2 flex items-center space-x-4">
        <img
          src={channelInfo.thumbnailUrl}
          alt={channelInfo.title}
          className="w-20 h-20 rounded-full border-4 border-white shadow-lg"
        />
        <div className="flex flex-row flex-grow justify-between">
          <div>
            <h2 className="text-2xl font-bold text-white">
              {channelInfo.title}
            </h2>
            <p className="text-red-100 text-sm">YouTube Channel</p>
          </div>
          <div className="flex items-center justify-between bg-white bg-opacity-10 rounded p-2 relative group">
            <div>
              <p className="text-xs text-red-100">Channel ID</p>
              <p className="text-sm text-white font-semibold">
                {channelInfo.id}
              </p>
            </div>
            <button
              onClick={copyToClipboard}
              className="text-white opacity-50 group-hover:opacity-100 transition-opacity duration-200 ml-2"
              title="Copy Channel ID"
            >
              <CopyIcon />
            </button>
            {copied && (
              <div className="absolute -bottom-6 left-0 right-0 text-center">
                <p className="text-xs text-green-300 bg-black bg-opacity-50 rounded py-1 px-2 inline-block">
                  Copied to clipboard!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="channel-body bg-white p-2">
        <p className="text-gray-600 text-sm mb-4 line-clamp-3">
          {channelInfo.description}
        </p>
        <div className="channel-stats grid grid-cols-3 gap-4 mb-2">
          <div className="stat-item text-center">
            <p className="text-2xl font-bold text-red-600">
              {parseInt(channelInfo.subscriberCount).toLocaleString()}
            </p>
            <p className="text-xs text-gray-500 uppercase">Subscribers</p>
          </div>
          <div className="stat-item text-center">
            <p className="text-2xl font-bold text-red-600">
              {parseInt(channelInfo.videoCount).toLocaleString()}
            </p>
            <p className="text-xs text-gray-500 uppercase">Videos</p>
          </div>
          <div className="stat-item text-center">
            <p className="text-2xl font-bold text-red-600">
              {parseInt(channelInfo.viewCount).toLocaleString()}
            </p>
            <p className="text-xs text-gray-500 uppercase">Views</p>
          </div>
        </div>
        <a
          href={`https://www.youtube.com/channel/${channelInfo.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full text-center bg-red-600 text-white py-3 rounded-lg font-semibold transition duration-300 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
        >
          Visit Channel
        </a>
      </div>
    </div>
  );
};

function OrderList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderList = useSelector(OrderSelectors.orderList);
  const pagination = useSelector(OrderSelectors.pagination);
  const [isShow, setIsShow] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isRefundShow, setIsRefundShow] = useState(false); // State for refund modal
  const [isReSendShow, setIsReSendShow] = useState(false); // State for refund modal
  const [quantityRefund, setQuantityRefund] = useState(0);
  const [quantityReSend, setQuantityReSend] = useState(0);
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [errorMessageRefundModal, setErrorMessageRefundModal] = useState({
    quantityRefund: "",
  });
  const [errorMessageReSendModal, setErrorMessageReSendModal] = useState({
    quantityReSend: "",
  });
  const [open, setOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});

  const channelInfo = useSelector(CheckLinkSelectors.channelInfo);
  const loading = useSelector(CheckLinkSelectors.loading);
  const error = useSelector(CheckLinkSelectors.error);

  const [isYoutubeChannelInfoModalOpen, setIsYoutubeChannelInfoModalOpen] =
    useState(false);

  useEffect(() => {
    return () => {
      dispatch(CheckLinkActions.resetState());
    };
  }, [dispatch, isYoutubeChannelInfoModalOpen]);

  const validate = (callback) => {
    const tempEM = { quantityRefund: "" };
    let errorCount = 0;

    if (!quantityRefund) {
      errorCount++;
      tempEM.quantityRefund = "Số lượng hoàn lại không được để trống";
    } else if (quantityRefund && quantityRefund < 0) {
      errorCount++;
      tempEM.quantityRefund = "Số lượng nhập phải lớn hơn 0";
    } else if (quantityRefund && quantityRefund > orderQuantity) {
      errorCount++;
      tempEM.quantityRefund = "Số lượng vượt quá số lượng đã đặt";
    }

    if (errorCount === 0) {
      callback();
    } else {
      setErrorMessageRefundModal(tempEM);
    }
  };

  const validateReSend = (callback) => {
    const tempEM = { quantityReSend: "" };
    let errorCount = 0;

    if (!quantityReSend) {
      errorCount++;
      tempEM.quantityReSend = "Số lượng hoàn lại không được để trống";
    } else if (quantityReSend && quantityReSend < 0) {
      errorCount++;
      tempEM.quantityReSend = "Số lượng nhập phải lớn hơn 0";
    }
    if (errorCount === 0) {
      callback();
    } else {
      setErrorMessageReSendModal(tempEM);
    }
  };

  const handleCompleteOrder = () => {
    dispatch(OrderActions.completeOrder(selectedOrderId));
    setIsShow(false);
    getOrderList();
  };

  const confirmCompleteOrder = (orderId) => {
    setSelectedOrderId(orderId);
    setIsShow(true);
  };

  useEffect(() => {
    dispatch(OrderActions.getOrders());
  }, [pagination.page, navigate, dispatch]);

  useEffect(() => {
    getOrderList();

    return () => {
      dispatch(OrderActions.resetSession());
    };
  }, [navigate, dispatch]);

  const getOrderList = () => {
    dispatch(OrderActions.getOrders());
  };

  const handleRefundOrder = () => {
    const quantityRefundNumber = Number(quantityRefund);
    if (!Number.isInteger(quantityRefundNumber)) {
      toast.error("Số lượng hoàn lại không thể là số lẻ!");
      return;
    }
    dispatch(
      OrderActions.refundOrder({
        id: selectedOrderId,
        quantity: quantityRefundNumber,
      })
    );
    setIsRefundShow(false);
    getOrderList();
  };

  const handleReSendOrder = () => {
    dispatch(
      OrderActions.reSendOrder({
        id: selectedOrderId,
        quantity: quantityReSend,
      })
    );
    setIsReSendShow(false);
    getOrderList();
  };

  const confirmRefundOrder = (orderId, quantity) => {
    setOrderQuantity(quantity);
    setQuantityRefund(quantity);
    setErrorMessageRefundModal({
      quantityRefund: "",
    });
    setSelectedOrderId(orderId);
    setIsRefundShow(true);
  };

  const confirmReSendOrder = (orderId, quantity) => {
    setOrderQuantity(quantity);
    setQuantityReSend(quantity);
    setErrorMessageReSendModal({
      quantityReSend: "",
    });
    setSelectedOrderId(orderId);
    setIsReSendShow(true);
  };

  const thead = [
    {
      name: "Mã đơn hàng",
      style: { width: 120 },
      className: "",
    },
    {
      name: "Gói dịch vụ",
      style: {
        textAlign: "left",
        width: 150,
      },
    },
    {
      name: "Thông tin",
      style: { width: 200 },
    },
    {
      name: "Trạng thái",
      style: {
        width: 140,
      },
    },
    {
      name: "Đơn vị xử lý",
      style: { width: 120 },
    },
    {
      name: "Giá trị",
      style: {
        width: 140,
      },
    },
    {
      name: "Ngày tạo",
      style: {
        width: 150,
      },
    },
    {
      name: "Thao tác",
      style: {
        width: 150, // Increase width to accommodate the new button
      },
    },
  ];

  const handleOpenModalYoutubeChannelInfo = (link) => {
    dispatch(CheckLinkActions.checkLinkYoutube(link));
    setIsYoutubeChannelInfoModalOpen(true);
  };

  const genRenderList = useCallback(() => {
    return (orderList || []).map((x, index) => {
      return [
        {
          value: (
            <div>
              <p>{x?.code}</p>
              <p>
                Order Number:
                <label className="text-primary"> {x?.orderNumber}</label>
              </p>
            </div>
          ),
        },
        {
          value: x?.servicePackage?.name,
          style: {
            textAlign: "left",
          },
        },
        {
          value: (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              <p>{x?.customer?.email}</p>

              <div className="flex gap-4 justify-center">
                <a
                  style={{
                    color: "#72777A",
                    textDecoration: "none",
                  }}
                  href={x?.servicePackage?.serverInfo?.serverUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getPortFromUrl(x?.servicePackage?.serverInfo?.serverUrl)}
                </a>
                <p
                  style={{
                    color: "#72777A",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleOpenModalYoutubeChannelInfo(
                      x?.servicePackage?.customerEnteredValues[0]?.enteredValue
                    )
                  }
                >
                  xem Kênh
                </p>
                <a
                  href={
                    x?.servicePackage?.customerEnteredValues[0]?.enteredValue
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  check
                </a>
              </div>

              <p className=" text-xs">
                {x?.servicePackage?.startAt &&
                  "Start: " + x?.servicePackage?.startAt + " | "}
                Số lượng: {x?.servicePackage?.qty}
              </p>
            </div>
          ),
        },
        // {
        //   value: odGenStatus(x.status),
        //   style: {
        //     color: odGenColor(x.status),
        //   },
        // },
        {
          value: (
            <div className="flex flex-col" key={x?._id}>
              <ActionStatus
                defaultStatus={x.status}
                orderId={x?._id}
                quantity={x?.servicePackage?.qty}
                startAt={x?.servicePackage?.startAt}
              />

              {((x?.status === "cancelled" &&
                x?.servicePackage?.qty !== undefined) ||
                (x?.status === "partially_cancelled" &&
                  x?.servicePackage?.refundedQuantity !== undefined)) && (
                <div>
                  SL đã hoàn:{" "}
                  <span className="text-red-600">
                    {x?.status === "cancelled"
                      ? `${x?.servicePackage?.qty}`
                      : `${x?.servicePackage?.refundedQuantity}`}
                  </span>
                </div>
              )}
            </div>
          ),
        },
        {
          value:
            x?.servicePackage?.partnerCode === "local"
              ? "Nội bộ"
              : x?.servicePackage?.partnerCode,
        },
        { value: `${formatPriceVND(x?.totalPrice)}đ` },
        {
          value: moment(x.createdAt).format("HH:mm DD/MM/YYYY"),
          style: {
            fontSize: 12,
          },
        },
        {
          value: (
            <div className="order-btn-area">
              <button
                className="order-icon-edit"
                onClick={() => navigate(`/services/orders/${x._id}`)}
              >
                <EyeIcon />
              </button>
              {/* {x.status === "running" && (
                <>
                  <button
                    className="order-icon-edit"
                    onClick={() => confirmCompleteOrder(x._id)}
                    disabled={x.status === "completed"}
                    title={
                      x.status === "completed"
                        ? "Đơn hàng đã hoàn thành"
                        : "Đánh dấu hoàn thành"
                    }
                  >
                    <CheckIcon />
                  </button>
                </>
              )} */}
              {x.status === "processing" && (
                <>
                  <button
                    className="order-icon-edit"
                    onClick={() =>
                      confirmReSendOrder(x._id, x?.servicePackage?.qty)
                    }
                    title="Đặt lại đơn"
                  >
                    Resend
                  </button>
                </>
              )}
              <button
                className="order-icon-edit"
                title="Hoàn lại tiền"
                onClick={() => {
                  setOrderDetail(x);
                  setOpen(true);
                }}
              >
                Refund
              </button>
            </div>
          ),
        },
      ];
    });
  }, [orderList, navigate, dispatch]);

  const onChangePage = (page) => {
    dispatch(
      OrderActions.setPagination({
        ...pagination,
        page,
      })
    );
  };

  const youtubeChannelInfoModal = (
    <div style={{ width: "600px", height: "fit-content" }}>
      <div className="modal-header">Thông tin kênh</div>
      <div className="modal-body">
        {loading && <p>Loading...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {channelInfo && (
          <div>
            <YouTubeChannelInfo channelInfo={channelInfo} />
          </div>
        )}
      </div>
    </div>
  );

  const modalContent = (
    <div>
      <div className="modal-header">Xác nhận hoàn thành</div>
      <div className="modal-body">
        Bạn có chắc chắn muốn đánh dấu đơn hàng này là hoàn thành?
      </div>
      <div className="modal-body">
        <div className="d-flex jc-between">
          <MST.Button onClick={handleCompleteOrder}>Xác nhận</MST.Button>
          <MST.Button onClick={() => setIsShow(false)}>Hủy</MST.Button>
        </div>
      </div>
    </div>
  );
  const refundModalContent = (
    <div>
      <div className="modal-header">Xác nhận hoàn lại tiền</div>
      <div className="modal-body">
        <p>Bạn có chắc chắn muốn hoàn lại tiền cho đơn hàng này?</p>
        <p>
          Hãy dừng đơn này bên server sau khi bạn đồng ý hoàn lại tiền cho khách
          hàng.
        </p>
        <div>Nhập số lượng:</div>
        <p
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <MST.Input
            errorMessage={errorMessageRefundModal?.quantityRefund}
            type="number"
            placeholder="Nhập số lượng hoàn lại"
            value={quantityRefund}
            onChange={(e) => {
              setQuantityRefund(e.target.value);
            }}
          />
        </p>
      </div>
      <div className="modal-body ">
        <div className="d-flex gap-6 justify-center">
          <MST.Button onClick={() => setIsRefundShow(false)}>Hủy</MST.Button>
          <MST.Button onClick={() => validate(handleRefundOrder)}>
            Xác nhận
          </MST.Button>
        </div>
      </div>
    </div>
  );
  const reSendModalContent = (
    <div>
      <div className="modal-header">Xác nhận gửi lại đơn</div>
      <div className="modal-body">
        <p>Bạn có chắc chắn muốn gửi lại đơn hàng này?</p>
        <p>Hãy thêm đơn này bên server sau khi bạn đồng ý đặt lại đơn hàng.</p>
      </div>
      <div className="modal-body">
        <div className="d-flex jc-between">
          <MST.Button onClick={() => validateReSend(handleReSendOrder)}>
            Xác nhận
          </MST.Button>
          <MST.Button onClick={() => setIsReSendShow(false)}>Hủy</MST.Button>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <OrderRefundAction
        {...{
          open,
          setOpen,
          order: orderDetail,
          setOrder: setOrderDetail,
        }}
      />
      <OrderSearch />
      <MST.Table head={thead} body={genRenderList()} />
      <div className="order-pagination">
        <Pagination
          onChange={onChangePage}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalPage={pagination.totalPage}
          total={pagination.total}
        />
      </div>

      <Modal
        content={modalContent}
        isShow={isShow}
        onHide={() => setIsShow(false)}
      />

      {/* hoàn lại tiền */}
      <Modal
        content={refundModalContent}
        isShow={isRefundShow}
        onHide={() => setIsRefundShow(false)}
      />
      <Modal
        content={reSendModalContent}
        isShow={isReSendShow}
        onHide={() => setIsReSendShow(false)}
      />
      <Modal
        content={youtubeChannelInfoModal}
        isShow={isYoutubeChannelInfoModalOpen}
        onHide={() => setIsYoutubeChannelInfoModalOpen(false)}
      />
    </div>
  );
}

function getPortFromUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.port;
  } catch (error) {
    return null;
  }
}

export default OrderList;

export const odGenColor = (content) => {
  switch (content) {
    case "running":
      return "#FF8900";
    case "completed":
      return "#23C16B";
    default:
      return "#000";
  }
};
export const odGenStatus = (status) => {
  switch (status) {
    case "running":
      return "Đang chạy";
    case "completed":
      return "Hoàn tất";
    case "cancelled":
      return "Đã huỷ";
    default:
      return status || "...";
  }
};
