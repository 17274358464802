const PromotionIcon = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g>
        <path
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.7498,6.18695c.38595-.04825,.77185,.2412,.77185,.62715l1.25425,11.9156c.04825,.38595-.2412,.77185-.62715,.77185l-11.86735,1.25425c-.38595,.04825-.77185-.2412-.77185-.62715l-1.25425-11.86735c-.04825-.38595,.2412-.77185,.62715-.77185l11.86735-1.3025Z"
        />
        <path
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.45225,9.46735c-.86835,.0965-1.5437,.86835-1.44725,1.78495,.0965,.86835,.86835,1.5437,1.78495,1.44725,.86835-.0965,1.5437-.86835,1.44725-1.78495s-.9166-1.5437-1.78495-1.44725Zm4.38995,2.798l-2.3156,2.84625c-.0965,.0965-.2412,.1447-.3377,.04825l-.53065-.43415c-.0965-.0965-.28945-.04825-.3377,.04825l-2.50855,3.08745c-.0965,.0965-.0965,.28945,.04825,.3377-.04825,.04825,.04825,.04825,.0965,.04825l9.98595-1.0613c.1447,0,.2412-.1447,.2412-.28945,0-.04825-.04825-.0965-.04825-.1447l-3.90755-4.5347c-.1447-.04825-.28945-.04825-.38595,.04825Z"
        />
        <path
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.1407,6.91055l.3377-3.0392c.04825-.38595,.38595-.6754,.77185-.62715l11.86735,1.25425c.38595,.04825,.6754,.38595,.62715,.77185l-1.25425,11.86735c0,.3377-.38595,.62715-.77185,.62715l-3.08745-.3377"
        />
        <path
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.38995,14.96685l2.65325,.28945c.1447,0,.2412-.0965,.28945-.2412,0-.04825,0-.0965-.04825-.1447l-2.8945-5.2583c-.04825-.0965-.2412-.1447-.3377-.0965l-.19295,.19295"
        />
      </g>
    </svg>
  );
};

export default PromotionIcon;
