import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PromotionActions,
  PromotionSelectors,
} from "../../app/services/promotions/promotion.slice";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import MST from "../../components";
import { useNavigate, useParams } from "react-router-dom";

// Styled component cho IOSSwitch
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const PromotionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const promotionDetail = useSelector(PromotionSelectors.promotionDetail);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    path: "",
    image: null,
    is_hidden: false,
  });

  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(PromotionActions.getPromotionById({ id }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && promotionDetail) {
      setFormData({
        title: promotionDetail.title || "",
        content: promotionDetail.content || "",
        path: promotionDetail.path || "",
        image: promotionDetail.image || null,
        is_hidden: promotionDetail.is_hidden || false,
      });

      if (promotionDetail.image && !(promotionDetail.image instanceof File)) {
        const apiOrigin = process.env.REACT_APP_SC_BACKEND_API ? new URL(process.env.REACT_APP_SC_BACKEND_API).origin : '';
        setPreview(`${apiOrigin}/${promotionDetail.image}`);
      }
    }
  }, [id, promotionDetail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSwitchChange = (e) => {
    const newHiddenState = e.target.checked;
    setFormData((prevState) => ({
      ...prevState,
      is_hidden: newHiddenState,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }));
      setPreview(URL.createObjectURL(file));

      if (errors.image) {
        setErrors((prev) => ({ ...prev, image: "" }));
      }
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title.trim()) newErrors.title = "Tiêu đề là bắt buộc";
    if (!formData.content.trim()) newErrors.content = "Nội dung là bắt buộc";
    if (!formData.path.trim()) newErrors.path = "Đường dẫn là bắt buộc";
    if (!formData.image) newErrors.image = "Hình ảnh là bắt buộc";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Vui lòng điền đầy đủ thông tin");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("content", formData.content);
    formDataToSend.append("path", formData.path);
    formDataToSend.append("is_hidden", formData.is_hidden);

    if (formData.image instanceof File) {
      formDataToSend.append("file", formData.image);
    }

    if (id) {
      dispatch(
        PromotionActions.edit({
          id,
          body: formDataToSend,
          onSuccess: () => {
            toast.success("Cập nhật Promotion thành công!");
            navigate("/services/promotions");
          },
          onError: (error) => {
            toast.error("Lỗi khi cập nhật Promotion: " + error.message);
          },
        })
      );
    } else {
      dispatch(
        PromotionActions.createPromotion({
          body: formDataToSend,
          onSuccess: () => {
            toast.success("Tạo mới Promotion thành công!");
            navigate("/services/promotions");
          },
          onError: (error) => {
            toast.error("Lỗi khi tạo mới Promotion: " + error.message);
          },
        })
      );
    }
  };

  return (
    <MST.Container
      title={id ? "Cập nhật Promotion" : "Tạo mới Promotion"}
      right={
        <div className="d-flex">
          <MST.Button
            onClick={() => navigate("/services/promotions")}
            type="outlined"
            className="mr-8"
          >
            Hủy
          </MST.Button>
          <MST.Button onClick={handleSubmit}>Lưu lại</MST.Button>
        </div>
      }
    >
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box
          component="form"
          id="promotion-form"
          onSubmit={handleSubmit}
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Tiêu đề"
                name="title"
                value={formData.title}
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                rows={4}
                label="Nội dung"
                name="content"
                value={formData.content}
                onChange={handleChange}
                error={!!errors.content}
                helperText={errors.content}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Đường dẫn"
                name="path"
                value={formData.path}
                onChange={handleChange}
                error={!!errors.path}
                helperText={errors.path}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  {id ? "Cập nhật ảnh" : "Thêm ảnh"}
                </Button>
              </label>
              {formData.image && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {formData.image instanceof File
                    ? formData.image.name
                    : formData.image}
                </Typography>
              )}
              {errors.image && (
                <Typography color="error" variant="caption">
                  {errors.image}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {preview && (
                <Box
                  component="img"
                  src={preview}
                  alt="Image Preview"
                  sx={{
                    width: "100%",
                    maxHeight: "400px",
                    objectFit: "contain",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={formData.is_hidden}
                    onChange={handleSwitchChange}
                  />
                }
                label="Ẩn Promotion"
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </MST.Container>
  );
};

export default PromotionForm;
