import * as React from "react";
const SVGMoney = (props) => (
  <div className="icon-money">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        d="M8 4.66699V12.667M6 10.7882L6.58592 11.2277C7.36693 11.8134 8.63322 11.8134 9.41424 11.2277C10.1953 10.6419 10.1953 9.69212 9.41424 9.10633C9.02369 8.81341 8.5118 8.66696 7.99992 8.66699C7.51664 8.66702 7.03347 8.52059 6.66473 8.22767C5.92733 7.64188 5.92733 6.69214 6.66473 6.10635C7.40213 5.52056 8.59769 5.52056 9.33509 6.10635L9.61168 6.32608M14 8.66699C14 11.9807 11.3137 14.667 8 14.667C4.68629 14.667 2 11.9807 2 8.66699C2 5.35328 4.68629 2.66699 8 2.66699C11.3137 2.66699 14 5.35328 14 8.66699Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
export default SVGMoney;
