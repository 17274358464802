import { delay, call, put, takeLatest, select } from "redux-saga/effects";
import { NewsActions, NewsSelectors } from "./news.slice";
import { toast } from "react-toastify";
import NewsRequest from "./news.request";
import slugify from "react-slugify";

function* NewsSaga() {
  yield takeLatest(NewsActions.createNews, createNews);
  yield takeLatest(NewsActions.updateNews, updateNews);
  yield takeLatest(NewsActions.getAllNews, getAllNews);
  yield takeLatest(NewsActions.createNewsCategory, createNewsCategory);
  yield takeLatest(NewsActions.deleteNewsCategory, deleteNewsCategory);
  yield takeLatest(NewsActions.getNewsCategories, getNewsCategories);
  yield takeLatest(NewsActions.getNewsById, getNewsById);
  yield takeLatest(NewsActions.deleteNews, deleteNews);
}

function* createNews({ payload }) {
  try {
    const { onSuccess, newsData } = payload;

    const response = yield call(NewsRequest.createNews, newsData);
    if (response.success) {
      toast.success("Tạo tin tức thành công");
      onSuccess();
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    yield put(NewsActions.createNewsFailure(error.message));
    toast.error("Có lỗi xảy ra khi tạo tin tức");
  }
}

function* updateNews({ payload }) {
  try {
    const { onSuccess, newsData, id } = payload;

    const response = yield call(NewsRequest.updateNews, { newsData, id });
    if (response.success) {
      toast.success("Cập nhật tin tức thành công");
      onSuccess();
    } else {
      yield put(NewsActions.updateNewsFailure(response.message));
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Có lỗi xảy ra khi Cập nhật tin");
  }
}

function* getAllNews() {
  try {
    const pagination = yield select(NewsSelectors.pagination);
    const newsCategory = yield select(NewsSelectors.newsCategory);

    const keySearch = yield select(NewsSelectors.keySearch);

    const response = yield call(NewsRequest.getAllNews, {
      category: newsCategory,
      page: pagination.page,
      pageSize: pagination.pageSize,
      search: slugify(keySearch) || "",
    });

    if (response.success) {
      yield put(NewsActions.getAllNewsSuccess(response.data.news));
      yield put(NewsActions.setPagination(response.data.pagination));
    } else {
      yield put(NewsActions.getAllNewsFailure(response.message));
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Có lỗi xảy ra khi lấy tin");
  }
}

function* getNewsById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(NewsRequest.getNewsById, id);
    if (response.success) {
      yield put(NewsActions.getNewByIdSuccess(response.data));
    } else {
      yield put(NewsActions.getNewByIdFailure(response.message));
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Có lỗi xảy ra khi lấy tin");
  }
}

function* deleteNews({ payload }) {
  try {
    const { id, onSuccess } = payload;
    const response = yield call(NewsRequest.deleteNews, id);

    if (response.success) {
      onSuccess();
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.error("Có lỗi xảy ra khi xóa tin");
  }
}

function* createNewsCategory({ payload }) {
  try {
    yield delay(100);
    const { onSuccess, name } = payload;

    const rs = yield NewsRequest.createNewsCategory(name);
    if (rs.success) {
      toast.success("Thêm danh mục thành công");

      onSuccess(rs.data);
    } else {
      throw rs.message;
    }
  } catch (error) {
    console.log({ error });
    toast.error(error.toString());
  }
}

function* deleteNewsCategory({ payload }) {
  try {
    yield delay(100);
    const { id, onSuccess } = payload;
    const rs = yield NewsRequest.deleteNewsCategory(id);

    if (rs.success) {
      yield getNewsCategories();

      onSuccess();
    } else {
      throw rs.message;
    }
  } catch (error) {
    console.log({ error });
    toast.error(error.toString());
  }
}

function* getNewsCategories() {
  try {
    yield delay(100);

    const rs = yield NewsRequest.getNewsCategories();
    if (rs.success) {
      // toast.success("Lấy news categories mới thành công");

      yield put(
        NewsActions.getNewsCategoriesSuccess(
          rs.data.map((item) => {
            return {
              name: item.name,
              value: item._id,
              // _id: item._id,
            };
          })
        )
      );

      // yield put(NewsActions.setPagination(rs.data.pagination));
    } else {
      throw rs.message;
    }
  } catch (error) {
    console.log({ error });
    toast.error(error.toString());
  }
}

export default NewsSaga;
