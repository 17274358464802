import { toast } from "react-toastify";
import { put, takeLatest, call } from "redux-saga/effects";
import VipLevelRequest from "./request.js";
import { VipLevelActions } from "./slice.js";

function* VipLevelSaga() {
  yield takeLatest(VipLevelActions.getVipPrices, getVipPrices);
  yield takeLatest(VipLevelActions.updateVipPrices, updateVipPrices);
  yield takeLatest(VipLevelActions.deleteVipPrices, deleteVipPrices);
}

function* getVipPrices({ payload }) {
  try {
    const { servicePackageId } = payload;
    const response = yield call(VipLevelRequest.getVipPrices, servicePackageId);
    if (response.success) {
      yield put(VipLevelActions.getVipPricesSuccess(response.data));
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(VipLevelActions.getVipPricesFailure(error.message));
    toast.error("Failed to fetch VIP levels");
  }
}

function* updateVipPrices({ payload }) {
  try {
    const { servicePackageId, vipPrices } = payload;
    const response = yield call(
      VipLevelRequest.updateVipPrices,
      servicePackageId,
      vipPrices
    );
    if (response.success) {
      yield put(VipLevelActions.updateVipPricesSuccess(response.data));
      toast.success("VIP levels updated successfully");
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(VipLevelActions.updateVipPricesFailure(error.message));
    toast.error("Failed to update VIP levels");
  }
}

function* deleteVipPrices({ payload }) {
  try {
    const { servicePackageId } = payload;
    const response = yield call(
      VipLevelRequest.deleteVipPrices,
      servicePackageId
    );
    if (response.success) {
      yield put(VipLevelActions.deleteVipPricesSuccess());
      toast.success("VIP levels deleted successfully");
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(VipLevelActions.deleteVipPricesFailure(error.message));
    toast.error("Failed to delete VIP levels");
  }
}

export default VipLevelSaga;
