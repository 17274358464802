import React, { useMemo, useState } from "react";
import Modal from "../../components/base/modal/Modal";
import EditIcon from "../../components/table-create-service/icons/EditIcon";
import DeleteIcon from "../../components/table-create-service/icons/DeleteIcon";
import MST from "../../components";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ApiKeyActions } from "../../app/services/api-key/api-key.slice";
import ApiKeyAction from "./ApiKeyAction";

const ApiKeyOption = ({ id, value }) => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    key: "",
    type: "",
  });
  const [input, setInput] = useState({
    key: value?.key || "",
    type: value?.type || "",
  });

  const onHide = () => {
    setIsShow(false);
  };

  const onShow = () => {
    setIsShow(true);
  };

  const onDelete = () => {
    dispatch(
      ApiKeyActions.deleteApiKey({
        id,
        onSuccess: () => {
          toast.success("Xoá API KEY thành công");
          dispatch(ApiKeyActions.getAllApiKey());
          onHide();
        },
      })
    );
  };

  const validate = () => {
    let isValid = true;
    const newErrorMessage = {
      key: "",
      type: "",
    };

    if (!input.key.trim()) {
      newErrorMessage.key = "Key không được để trống";
      isValid = false;
    }

    if (!input.type.trim()) {
      newErrorMessage.type = "Phân loại không được để trống";
      isValid = false;
    }

    setErrorMessage(newErrorMessage);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    setInput((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrorMessage((prev) => ({
      ...prev,
      key: "",
      type: "",
    }));
  };

  const handleSubmit = () => {
    if (validate()) {
      dispatch(
        ApiKeyActions.updateApiKey({
          input,
          id,
          onSuccess: () => {
            setIsShowUpdate(false);
            dispatch(ApiKeyActions.getAllApiKey());
          },
        })
      );
    }
  };

  const handleClose = () => {
    setIsShowUpdate(false);
    setInput((prev) => ({
      ...prev,
      key: value?.key,
      type: value?.type,
    }));
    setErrorMessage((prev) => ({
      ...prev,
      key: "",
      type: "",
    }));
  };

  const contentModal = useMemo(() => {
    return (
      <div>
        <div className="modal-header">Xoá API KEY</div>
        <div className="modal-body">Bạn có muốn xoá API KEY này?</div>
        <div className="modal-footer">
          <div className="d-flex jc-between">
            <div />
            <div className="d-flex">
              <MST.Button type="outlined" className="mr-8" onClick={onHide}>
                Huỷ
              </MST.Button>
              <MST.Button onClick={onDelete}>Xác nhận</MST.Button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [id]);

  return (
    <div className="d-flex jc-center">
      <Modal
        contentWidth={600}
        isShow={isShowUpdate}
        onHide={() => {
          handleClose();
        }}
        content={
          <ApiKeyAction
            input={input}
            errorMessage={errorMessage}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            isUpdate={true}
          />
        }
      />
      <button onClick={() => setIsShowUpdate(true)} className="btn-edit mr-8">
        <EditIcon />
      </button>
      <button className="btn-delete" onClick={onShow}>
        <DeleteIcon />
      </button>
      <Modal content={contentModal} isShow={isShow} onHide={onHide} />
    </div>
  );
};

export default ApiKeyOption;
