import { isEmpty, set } from "lodash";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ServiceActions,
  ServiceSelectors,
} from "../../app/services/service/service.slice";
import {
  VoucherActions,
  VoucherSelectors,
} from "../../app/services/voucher/voucher.slice";
import MST from "../../components";
import Editor from "../../components/base/editor/Editor";
import Select from "../../components/base/select/Select";
import Table from "../../components/base/table/Table";
import moment from "moment";
import DeleteIcon from "../../components/table-create-service/icons/DeleteIcon";
import Modal from "../../components/base/modal/Modal";
import { useParams } from "react-router-dom";
import Pagination from "../../components/base/pagination/Pagination";
import "./style.css";

const DownIcon = ({ size = 20 }) => {
  return (
    <svg viewBox="0 0 320 512" width={size} height={size}>
      <path
        fill="currentColor"
        d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
      />
    </svg>
  );
};

const CopyIcon = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function VoucherCreatePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const voucherDetail = useSelector(VoucherSelectors.voucherDetail);
  const voucherCodes = useSelector(VoucherSelectors.voucherCodes);
  const serviceLocalList = useSelector(ServiceSelectors.serviceLocalList);
  const pagination = useSelector(VoucherSelectors.pagination);
  const voucherCodesCopy = useSelector(VoucherSelectors.voucherCodesCopy);
  const currentListPage = useSelector(VoucherSelectors.currentListPage);
  const params = useParams();

  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [services, setServices] = useState([]);
  const [isDisable, setIsDisable] = useState(Boolean(params.id));
  const [createDateVoucher, setCreateDateVoucher] = useState("");
  const [searchByVoucherCode, setSearchByVoucherCode] = useState("");
  const [startDateSearch, setStartDateSearch] = useState();
  const [endDateSearch, setEndDateSearch] = useState();

  const thead = [
    {
      name: "STT",
      style: { width: 20 },
      className: "",
    },
    {
      name: "Mã Code",
    },
    {
      name: "Ngày bắt đầu",
      style: {
        width: 200,
      },
    },
    {
      name: "Ngày kết thúc",
      style: {
        width: 200,
      },
    },
    {
      name: "Ngày tạo",
      style: {
        width: 200,
      },
    },
    {
      name: "Trạng thái",
      style: {
        width: 100,
      },
    },
    {
      name: "Mã Order",
      style: {
        width: 150,
      },
    },
    {
      name: "Sử dụng ngày",
      style: {
        width: 150,
      },
    },
    {
      name: "Thao tác",
      style: {
        width: 100,
      },
    },
  ];
  const sGenColor = (content) => {
    switch (content) {
      case "redeemed": //
        return { name: "Đã sử dụng", color: "#72777A" };
      case "active":
        return { name: "Đang hoạt động", color: "#23C16B" };
      case "expired":
        return { name: "Hết hạn", color: "#ff0000" };
      default:
        return { name: "Vô hiệu hóa", color: "#000000" };
    }
  };


  const handleStartDateChange = (date) => {
    const newStartDate = setDateWithTime(date, 0, 0, 0, 0);
    setStartDateSearch(newStartDate);
    if (endDateSearch < newStartDate) {
      setEndDateSearch(setDateWithTime(newStartDate, 23, 59, 59, 999));
    }
  };

  const handleEndDateChange = (date) => {
    setEndDateSearch(setDateWithTime(date, 23, 59, 59, 999));
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (voucherCodesListCopy) => {
    navigator.clipboard.writeText(voucherCodesListCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const genRenderList = useCallback(() => {
    return voucherCodes.map((x, index) => {
      return [
        {
          value:
            pagination.page === 1
              ? index + 1
              : 10 * pagination.page - (9 - index),
        },
        { value: x?.code, style: { textAlign: "center" } },
        {
          value: moment(x?.startDate).format("DD/MM/YYYY"),
          style: { width: 100 },
        },
        {
          value: moment(x?.endDate).format("DD/MM/YYYY"),
          style: { width: 100 },
        },
        {
          value: moment(x?.createdAt).format("DD/MM/YYYY"),
          style: { width: 100 },
        },
        {
          value: sGenColor(x?.status).name,
          style: {
            width: 100,
            color: sGenColor(x?.status).color,
          },
        },
        { value: x?.order?.code, style: { width: 100 } },
        {
          value: x.usedAt ? moment(x.usedAt).format("DD/MM/YYYY") : "",
          style: { width: 100 },
        },
        {
          value: <VouchereDeleteModal id={x._id} voucher={params.id} />,
        },
      ];
    });
  }, [voucherCodes, searchByVoucherCode]);

  const [errorMessage, setErrorMessage] = useState({
    name: "",
    percentReduce: "",
    quantity: "",
    minAmount: "",
  });

  const [name, setName] = useState("");
  const [percentReduce, setPercentReduce] = useState("");

  //Set time format
  const setDateWithTime = useCallback(
    (date, hours, minutes, seconds, milliseconds) => {
      const newDate = new Date(date);
      newDate.setHours(hours, minutes, seconds, milliseconds);
      return newDate;
    },
    []
  );

  //validate number input
  const handleNonNegativeIntegerInput = useCallback((value, field, setter) => {
    // Allow empty string or non-negative integers
    if (value === "" || (/^\d+$/.test(value) && parseInt(value, 10) >= 0)) {
      setter(value);
      setErrorMessage((prevState) => ({
        ...prevState,
        [field]: "",
      }));
    }
  }, []);

  const handlePercentReduceChange = (e) =>
    handleNonNegativeIntegerInput(
      e.target.value,
      "percentReduce",
      setPercentReduce
    );

  const handleMinAmountChange = (e) =>
    handleNonNegativeIntegerInput(e.target.value, "minAmount", setMinAmount);

  const handleMaxAmountChange = (e) =>
    handleNonNegativeIntegerInput(e.target.value, "maxAmount", setMaxAmount);

  const validateNonNegativeInteger = (value, fieldName, errorMessage) => {
    if (value === "") {
      return `${errorMessage} không được để trống`;
    } else {
      const numberValue = parseInt(value, 10);
      if (isNaN(numberValue) || numberValue < 0) {
        return `${errorMessage} phải là số nguyên dương lớn hơn hoặc bằng 0`;
      }
    }
    return "";
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedFetchData = useRef(
    debounce((searchQuery) => {
      dispatch(
        VoucherActions.getVoucherCodesBySearch({
          voucher: params.id,
          status: status,
          startDate: startDateSearch
            ? moment(startDateSearch).subtract(1, "days").format("YYYY-MM-DD")
            : null,
          endDate: endDateSearch
            ? moment(endDateSearch).add(1, "days").format("YYYY-MM-DD")
            : null,
          search: searchQuery,
        })
      );
    }, 300)
  ).current;

  useEffect(() => {
    debouncedFetchData(searchByVoucherCode);
  }, [
    searchByVoucherCode,
    startDateSearch,
    endDateSearch,
    status,
    debouncedFetchData,
  ]);

  useEffect(() => {
    dispatch(VoucherActions.getCreateInfo());
    dispatch(ServiceActions.getServicesLocal());
  }, [dispatch]);

  useEffect(() => {
    dispatch(VoucherActions.getAllVoucherCodes({ id: params.id }));
  }, [pagination?.page, dispatch, params.id]);

  useEffect(() => {
    if (voucherDetail) {
      setName(voucherDetail.name);
      setPercentReduce(voucherDetail.discount);
      setCreateDateVoucher(voucherDetail.createdAt);
      setMinAmount(voucherDetail.minAmount);
      setMaxAmount(voucherDetail.maxAmount);
      setDescription(voucherDetail.description);
      setStatus(voucherDetail.isActive);
    }
  }, [voucherDetail, setDateWithTime]);

  useEffect(() => {
    try {
      setServices(voucherDetail.service);
    } catch (error) {}
  }, [voucherDetail]);

  const onChangeStatus = (status) => {
    setStatus(status);
  };

  const validate = (callback) => {
    const tempEM = {
      code: "",
      percentReduce: "",
      minAmount: "",
      maxAmount: "",
    };

    let errorCount = 0;
    if (!name) {
      errorCount++;
      tempEM.name = "Mã voucher không được để trống";
    }

    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(name)) {
      errorCount++;
      tempEM.name = "Mã voucher không chứa kí tự đặc biệt";
    }

    if (!/[a-zA-ZÀ-ỹ]/.test(name)) {
      errorCount++;
      tempEM.name = tempEM.name
        ? tempEM.name + ", ít nhất một chữ cái"
        : "Mã voucher phải chứa ít nhất một chữ cái";
    }
    const percentReduceError = validateNonNegativeInteger(
      percentReduce,
      "percentReduce",
      "Phần trăm giảm giá"
    );
    if (percentReduceError) {
      errorCount++;
      tempEM.percentReduce = percentReduceError;
    }

    const minAmountError = validateNonNegativeInteger(
      minAmount,
      "minAmount",
      "Chi tiêu tối thiểu"
    );
    if (minAmountError) {
      errorCount++;
      tempEM.minAmount = minAmountError;
    }

    if (errorCount === 0) {
      callback();
    } else {
      setErrorMessage(tempEM);
    }

    const maxAmountError = validateNonNegativeInteger(
      maxAmount,
      "maxAmount",
      "Chi tiêu tối đa"
    );

    if (maxAmountError) {
      errorCount++;
      tempEM.maxAmount = maxAmountError;
    }

    if (errorCount === 0) {
      callback();
    } else {
      setErrorMessage(tempEM);
    }
  };

  const onChangePage = (page) => {
    dispatch(VoucherActions.setPagination({ ...pagination, page }));
  };

  const handleFilterDayAndStatus = () => {
    dispatch(
      VoucherActions.getVoucherCodesBySearch({
        voucher: params.id,
        status: status,
        startDate: startDateSearch
          ? moment(startDateSearch).subtract(1, "days").format("YYYY-MM-DD")
          : null,
        endDate: endDateSearch
          ? moment(endDateSearch).add(1, "days").format("YYYY-MM-DD")
          : null,
        search: searchByVoucherCode,
      })
    );
  };

  const onCreate = () => {
    dispatch(
      VoucherActions.createVoucher({
        onSuccess: (id) => {
          toast.success("Tạo mới voucher thành công");
          navigate(`/services/vouchers`);
        },
        body: {
          name,
          discount: percentReduce,
          description,
          minAmount,
          maxAmount,
          // service: services.map((service) => service.value),
          service: services.map((service) => service._id),
        },
      })
    );
  };

  const handleNavigateBack = () => {
    navigate(`/services/vouchers?page=${currentListPage}`);
  };

  const onEdit = () => {

    dispatch(
      VoucherActions.edit({
        onSuccess: () => {
          toast.success("Cập nhật voucher thành công");
          handleNavigateBack();
        },
        id: params.id,
        body: {
          name,
          discount: percentReduce,
          description,
          maxAmount,
          minAmount,
          // service: services.map((service) => service.value || service._id),
          service: services.map((service) => service._id),
        },
      })
    );
  };

  return (
    <MST.Container
      title={voucherDetail ? "Cập nhật voucher" : "Tạo mới voucher"}
      right={
        <div className="d-flex">
          <MST.Button
            onClick={handleNavigateBack}
            type="outlined"
            className="mr-8"
          >
            Hủy
          </MST.Button>
          <MST.Button
            onClick={
              params.id ? () => validate(onEdit) : () => validate(onCreate)
            }
            className="mr-8"
          >
            Lưu lại
          </MST.Button>
          {params.id && (
            <MST.Button onClick={() => setIsDisable((prev) => !prev)}>
              Cập nhật
            </MST.Button>
          )}
        </div>
      }
    >
      <div className="voucher-create-content-container">
        <div className="voucher-create-content-left">
          <div className="voucher-create-content">
            <div className="voucher-create-title">Thông tin voucher</div>
            <div className="voucher-create-one-field">
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <div className="voucher-create-one-field-name">
                  Tên voucher<span className="color-red"> *</span>
                </div>
                <div>
                  <MST.Input
                    errorMessage={errorMessage?.name}
                    placeholder="Nhập code voucher"
                    maxLength={50}
                    value={name}
                    onChange={(e) => {
                      setErrorMessage({
                        ...errorMessage,
                        name: "",
                      });
                      setName(e.target.value);
                    }}
                    disabled={isDisable}
                  />
                  <div>{name?.length}/20 </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  marginLeft: 16,
                }}
              >
                <div className="voucher-create-one-field-name">
                  % Giảm giá<span className="color-red"> *</span>
                </div>
                <div>
                  <MST.Input
                    type="number"
                    errorMessage={errorMessage?.percentReduce}
                    placeholder="Nhập % giảm giá"
                    value={percentReduce}
                    onChange={handlePercentReduceChange}
                    disabled={isDisable}
                  />
                </div>
              </div>
            </div>
            <div className="voucher-create-one-field">
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <div className="voucher-create-one-field-name">
                  Ngày tạo<span className="color-red"> *</span>
                </div>
                <div>
                  <MST.CustomDatePicker
                    label=""
                    selectedDate={
                      params.id
                        ? createDateVoucher
                        : new Date().setHours(0, 0, 0, 0)
                    }
                    disabled={true}
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <div className="voucher-create-one-field-name">
                  Chọn dịch vụ
                </div>
                <div>
                  <Select.Multiple
                    placeholder="Chọn dịch vụ"
                    setSelectedList={setServices}
                    selectedList={services}
                    width={"100%"}
                    data={serviceLocalList}
                    disabled={isDisable}
                  />
                </div>
              </div>
            </div>
            <div className="voucher-create-one-field">
              {/* Chi tiêu tối thiểu */}
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <div className="voucher-create-one-field-name">
                  Chi tiêu tối thiểu<span className="color-red"> *</span>
                </div>
                <div>
                  <MST.Input
                    type="number"
                    errorMessage={errorMessage?.minAmount}
                    placeholder="Nhập chi tiêu tối thiểu"
                    value={minAmount}
                    onChange={handleMinAmountChange}
                    disabled={isDisable}
                  />
                </div>
              </div>

              {/* Chi tiêu tối đa */}
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  marginLeft: 16,
                }}
              >
                <div className="voucher-create-one-field-name">
                  Giảm tối đa<span className="color-red"> *</span>
                </div>
                <div>
                  <MST.Input
                    type="number"
                    errorMessage={errorMessage?.maxAmount}
                    placeholder="Nhập chi tiêu tối đa"
                    value={maxAmount}
                    onChange={handleMaxAmountChange}
                    disabled={isDisable}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="voucher-create-content">
            <div className="voucher-create-title">Mô tả voucher</div>
            <div className="voucher-create-one-field">
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <div className="voucher-create-one-field-name">Mô tả</div>
                <div>
                  <Editor
                    placeholder="Nhập mô tả"
                    onBlur={(eventInfo, editor) => {
                      const data = editor?.getData();
                      const valueTrim = data?.trim();
                      if (isEmpty(data)) {
                        editor.setData("");
                        return;
                      }
                      setDescription(valueTrim);
                    }}
                    data={description || ""}
                    disabled={isDisable}
                    height="200px"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Số lượng voucher */}
          {params.id && (
            <div className="voucher-create-content">
              <div className="voucher-create-title">Voucher Code</div>
              <div className="flex w-full">
                <div className="search-voucher-status my-5">
                  <StatusFilter onStatusChange={onChangeStatus} />
                </div>
                <div className="my-5 ml-5 w-[50%]">
                  <MST.Input
                    placeholder={"Nhập mã voucher tìm kiếm"}
                    value={searchByVoucherCode}
                    onChange={(e) => setSearchByVoucherCode(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-start gap-5 items-center my-5 ">
                <MST.CustomDatePicker
                  label="Ngày bắt đầu"
                  selectedDate={startDateSearch}
                  maxDate={endDateSearch}
                  onChange={handleStartDateChange}
                />
                <MST.CustomDatePicker
                  label="Ngày kết thúc"
                  selectedDate={endDateSearch}
                  minDate={startDateSearch}
                  onChange={handleEndDateChange}
                />
                <MST.Button
                  className="service-search-btn max-h-[42px] mt-[30px]"
                  onClick={handleFilterDayAndStatus}
                >
                  Lọc
                </MST.Button>
                <div className="mt-[30px]">Số lượng: {pagination.total}</div>

                <div>
                  <AddVoucherCodes id={params.id} />
                </div>
                <button
                  onClick={() =>
                    copyToClipboard(
                      voucherCodesCopy.map((item) => item.code).join(", ")
                    )
                  }
                  className="text-black opacity-50 group-hover:opacity-100 transition-opacity duration-200 ml-2 mt-[30px]"
                  title="Copy Channel ID"
                >
                  <CopyIcon />
                </button>
                {copied && (
                  <div className="absolute bottom-6 left-0 right-0 text-center">
                    <p className="text-xs text-green-300 bg-black bg-opacity-50 rounded py-1 px-2 inline-block">
                      Copied to clipboard!
                    </p>
                  </div>
                )}
              </div>
              <Table head={thead} body={genRenderList()} />
              <div className="voucher-pagination">
                <Pagination
                  onChange={onChangePage}
                  page={pagination?.page}
                  pageSize={pagination?.limit}
                  totalPage={pagination?.totalPage}
                  total={pagination?.total}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </MST.Container>
  );
}

function VouchereDeleteModal({ id, voucher }) {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);

  const onHide = () => {
    setIsShow(false);
  };

  const onShow = () => {
    setIsShow(true);
  };

  const onDelete = () => {
    dispatch(
      VoucherActions.deleteVoucherCode({
        id,
        onSuccess: () => {
          toast.success("Xóa voucher con thành công");
          dispatch(VoucherActions.getAllVoucherCodes({ id: voucher }));
          onHide();
        },
      })
    );
  };

  const contentModal = useMemo(() => {
    return (
      <div>
        <div className="modal-header">Xoá dịch vụ</div>
        <div className="modal-body">Bạn có muốn xoá voucher này?</div>
        <div className="modal-footer">
          <div className="d-flex jc-between">
            <div />
            <div className="d-flex">
              <MST.Button type="outlined" className="mr-8" onClick={onHide}>
                Huỷ
              </MST.Button>
              <MST.Button onClick={onDelete}>Xác nhận</MST.Button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [id, onDelete]);

  return (
    <div className="d-flex jc-center">
      <button className="btn-delete" onClick={onShow}>
        <DeleteIcon />
      </button>
      <Modal content={contentModal} isShow={isShow} onHide={onHide} />
    </div>
  );
}

function AddVoucherCodes({ id }) {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [quantity, setQuantity] = useState(0);

  const setDateWithTime = useCallback(
    (date, hours, minutes, seconds, milliseconds) => {
      const newDate = new Date(date);
      newDate.setHours(hours, minutes, seconds, milliseconds);
      return newDate;
    },
    []
  );

  const handleStartDateChange = (date) => {
    const newStartDate = setDateWithTime(date, 0, 0, 0, 0);
    setStartDate(newStartDate);
    if (endDate < newStartDate) {
      setEndDate(setDateWithTime(newStartDate, 0, 0, 0, 0));
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(setDateWithTime(date, 0, 0, 0, 0));
  };

  const onHide = () => {
    setIsShow(false);
  };

  const onShow = () => {
    setIsShow(true);
  };

  const onConfirm = () => {
    dispatch(
      VoucherActions.getQuantityVoucherCodes({
        amount: quantity,
        startDate,
        endDate,
        voucher: id,
        onSuccess: () => {
          onHide();
        },
      })
    );
  };

  const contentModal = useMemo(() => {
    return (
      <div>
        <div className="modal-header">Thêm số lượng Voucher</div>
        <div className="modal-footer">
          <div className="flex justify-start gap-5 items-center my-5 ">
            <MST.CustomDatePicker
              label="Ngày bắt đầu"
              selectedDate={startDate}
              maxDate={endDate}
              onChange={handleStartDateChange}
            />
            <MST.CustomDatePicker
              label="Ngày kết thúc"
              selectedDate={endDate}
              minDate={startDate}
              onChange={handleEndDateChange}
            />
          </div>
          <div>
            <MST.Input
              title="Số lượng"
              type="number"
              placeholder="Nhập số lượng"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>
          <div className="flex justify-start gap-5 items-center my-5 "></div>
          <div className="flex justify-end my-3">
            <MST.Button type="outlined" className="mr-8" onClick={onHide}>
              Huỷ
            </MST.Button>
            <MST.Button onClick={onConfirm}>Xác nhận</MST.Button>
          </div>
        </div>
      </div>
    );
  }, [endDate, startDate, quantity]);

  return (
    <div>
      <MST.Button
        className="service-search-btn max-h-[42px] mt-[30px]"
        onClick={onShow}
      >
        Thêm code
      </MST.Button>
      <Modal content={contentModal} isShow={isShow} onHide={onHide} />
    </div>
  );
}

const StatusFilter = ({ onStatusChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [hoveredStatus, setHoveredStatus] = useState(null);
  const ref = useRef();

  const statusOptions = [
    { value: "", label: "Tất cả" },
    { value: "active", label: "Hoạt động" },
    { value: "disabled", label: "Không hoạt động" },
    { value: "expired", label: "Hết hạn" },
    { value: "redeemed", label: "Đã sử dụng" },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    onStatusChange(status);
    setIsOpen(false);
  };

  const getOptionStyle = (optionValue) => {
    const baseStyle = {
      padding: "10px 12px",
      cursor: "pointer",
      transition: "background-color 0.2s",
    };

    if (selectedStatus === optionValue) {
      return {
        ...baseStyle,
        backgroundColor: "#f0f0f0",
      };
    }

    if (hoveredStatus === optionValue) {
      return {
        ...baseStyle,
        backgroundColor: "#f8f8f8",
      };
    }

    return baseStyle;
  };

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "200px",
        height: "50px",
        boxSizing: "border-box",
        padding: "0 12px",
        borderRadius: "8px",
        border: `1px solid ${isOpen ? `#ff8900` : `#e3e5e5`}`,
        transition: "all 0.2s",
        cursor: "pointer",
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        style={{
          position: "absolute",
          top: "-10px",
          left: "10px",
          padding: "0 5px",
          backgroundColor: "white",
          fontSize: "12px",
          color: isOpen ? "#ff8900" : "#72777A",
          zIndex: 1,
        }}
      >
        Trạng thái
      </div>
      <div
        style={{
          flex: 1,
          fontSize: "14px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {statusOptions.find((option) => option.value === selectedStatus)
          ?.label || "Chọn trạng thái"}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: isOpen ? "#ff8900" : "#e3e5e5",
          transition: "all 0.2s",
        }}
      >
        <DownIcon />
      </div>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "calc(100% + 8px)",
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
          }}
        >
          {statusOptions.map((option) => (
            <div
              key={option.value}
              style={getOptionStyle(option.value)}
              onClick={() => handleStatusChange(option.value)}
              onMouseEnter={() => setHoveredStatus(option.value)}
              onMouseLeave={() => setHoveredStatus(null)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VoucherCreatePage;
