import * as React from "react";
const SVGCustomer = (props) => (
  <div className="icon-customer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        d="M12.5002 12.4794C12.6652 12.4931 12.8321 12.5 13.0006 12.5C13.6995 12.5 14.3705 12.3805 14.9943 12.1608C14.9985 12.1078 15.0006 12.0541 15.0006 12C15.0006 10.8954 14.1052 10 13.0006 10C12.5822 10 12.1938 10.1285 11.8727 10.3482M12.5002 12.4794C12.5002 12.4863 12.5002 12.4931 12.5002 12.5C12.5002 12.65 12.492 12.7981 12.4759 12.9438C11.3047 13.6158 9.94736 14 8.50024 14C7.05313 14 5.69576 13.6158 4.52458 12.9438C4.5085 12.7981 4.50024 12.65 4.50024 12.5C4.50024 12.4932 4.50026 12.4863 4.5003 12.4795M12.5002 12.4794C12.4963 11.6951 12.2665 10.9641 11.8727 10.3482M11.8727 10.3482C11.1621 9.23679 9.91722 8.5 8.50024 8.5C7.08344 8.5 5.83868 9.23661 5.12804 10.3477M5.12804 10.3477C4.80706 10.1283 4.41888 10 4.00073 10C2.89616 10 2.00073 10.8954 2.00073 12C2.00073 12.0541 2.00288 12.1078 2.00711 12.1608C2.63086 12.3805 3.30184 12.5 4.00073 12.5C4.16896 12.5 4.33557 12.4931 4.5003 12.4795M5.12804 10.3477C4.73405 10.9638 4.50423 11.6949 4.5003 12.4795M10.5002 4.5C10.5002 5.60457 9.60481 6.5 8.50024 6.5C7.39567 6.5 6.50024 5.60457 6.50024 4.5C6.50024 3.39543 7.39567 2.5 8.50024 2.5C9.60481 2.5 10.5002 3.39543 10.5002 4.5ZM14.5002 6.5C14.5002 7.32843 13.8287 8 13.0002 8C12.1718 8 11.5002 7.32843 11.5002 6.5C11.5002 5.67157 12.1718 5 13.0002 5C13.8287 5 14.5002 5.67157 14.5002 6.5ZM5.50024 6.5C5.50024 7.32843 4.82867 8 4.00024 8C3.17182 8 2.50024 7.32843 2.50024 6.5C2.50024 5.67157 3.17182 5 4.00024 5C4.82867 5 5.50024 5.67157 5.50024 6.5Z"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
export default SVGCustomer;
