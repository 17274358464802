import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  NotificationActions,
  NotificationSelectors,
} from "../../app/services/notification/notification.slice";
import MST from "../../components";
import Editor from "../../components/base/editor/Editor";
import "./style.css";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

function NotificationCreatePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationDetail = useSelector(
    NotificationSelectors.notificationDetail
  );
  const [content, setContent] = useState(notificationDetail?.content);
  const [important, setImportant] = useState(
    notificationDetail?.important || false
  );

  useEffect(() => {
    if (notificationDetail?.content) {
      setContent(notificationDetail?.content);
      setImportant(notificationDetail?.important);
    }
  }, [notificationDetail?.content, notificationDetail?.important]);

  const validate = () => {
    let isValid = true;

    if (!content) {
      toast.error("Nội dung thông báo không được để trống");
      isValid = false;
    }

    return isValid;
  };

  const onCreate = () => {
    if (validate()) {
      dispatch(
        NotificationActions.createNotification({
          onSuccess: (id) => {
            toast.success("Tạo mới thông báo thành công");
            navigate(`/services/notifications`);
          },
          body: {
            content,
            important,
          },
        })
      );
    }
  };

  const onEdit = () => {
    if (validate()) {
      dispatch(
        NotificationActions.edit({
          onSuccess: () => {
            toast.success("Cập nhật thông báo thành công");
            navigate(`/services/notifications`);
          },
          id: notificationDetail._id,
          body: {
            content,
            important,
          },
        })
      );
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <MST.Container
      title={notificationDetail ? "Cập nhật thông báo" : "Thêm thông báo"}
      right={
        <div className="d-flex">
          <MST.Button
            onClick={() => navigate("/services/notifications")}
            type="outlined"
            className="mr-8"
          >
            Huỷ
          </MST.Button>
          <MST.Button onClick={notificationDetail ? onEdit : onCreate}>
            Lưu lại
          </MST.Button>
        </div>
      }
    >
      <div className="notification-create-content">
        <div className="notification-create-one-field">
          <div className="flex gap-6 items-center">
            <div className="notification-create-one-field-name">
              Đặt thông báo quan trọng
            </div>
            <IOSSwitch
              sx={{ m: 1 }}
              checked={important}
              onChange={() => setImportant(!important)}
            />
          </div>

          <div className="notification-create-one-field-name">
            Nội dung thông báo
          </div>
          <div>
            <Editor
              placeholder="Nhập nội dung thông báo"
              onBlur={(_, editor) => {
                const data = editor?.getData();
                const valueTrim = data?.trim();
                if (isEmpty(data)) {
                  editor.setData("");
                  return;
                }
                setContent(valueTrim);
              }}
              data={content}
              onChange={(_, editor) => setContent(editor?.getData())}
            />
          </div>
        </div>
      </div>
    </MST.Container>
  );
}

export default NotificationCreatePage;
