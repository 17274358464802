import SysFetch from "../../fetch";
import qs from "qs";

const VoucherRequest = {
  getAllVoucherCodes: (id, body) => {
    return SysFetch.get(
      `voucher-code/?voucher=${id}&${qs.stringify(body, { encode: false })}`
    );
  },

  getVouchers: (body) => {
    return SysFetch.get(`voucher?${qs.stringify(body, { encode: false })}`);
  },
  getVoucherHistories: (body) => {
    return SysFetch.get(
      `voucher-history?${qs.stringify(body, { encode: false })}`
    );
  },
  getVoucherById: (id) => SysFetch.get(`voucher/${id}`),
  create: (body) => {
    return SysFetch.post(`voucher`, body);
  },
  edit: (id, body) => {
    return SysFetch.patch(`voucher/${id}`, body);
  },
  delete: (id) => {
    return SysFetch.delete(`voucher/${id}`);
  },
  deleteVoucherCode: (id) => {
    return SysFetch.delete(`voucher-code/${id}`);
  },
  searchVoucherByStatusAndDate: ({
    voucher,
    status,
    startDate,
    endDate,
    limit,
    page,
    search
  }) => {
    return SysFetch.get(
      `voucher-code/?voucher=${voucher}&page=${page}&limit=${limit}${
        startDate ? `&startDate[gte]=${startDate}` : ""
      }${endDate ? `&endDate[lte]=${endDate}` : ""}${
        status ? `&status=${status}` : ""
      }${search ? `&search=${search}` : ""}`
    );
  },
  generateVoucherCodes: (body) => {
    return SysFetch.post(`voucher-code/generate-codes`, body);
  },
};
export default VoucherRequest;
