import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
  useMemo,
} from "react";
import axios from "axios";
import io from "socket.io-client";
import CONST from "../../app/services/const";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [pendingChats, setPendingChats] = useState([]);
  const [activeChats, setActiveChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSeen, setIsSeen] = useState(false);

  const getCurrentSupportId = useCallback(() => {
    return JSON.parse(localStorage.getItem("USER_INFO") || "{}")?.id;
  }, []);

  const pendingUsersCount = useMemo(() => {
    const supportId = getCurrentSupportId();
    const pendingCount = pendingChats.length;
    const unansweredActiveCount = activeChats?.filter(
      (chat) =>
        (chat?.supportId === supportId || chat?.support?._id === supportId) &&
        chat.messages.length > 0 &&
        chat.messages[chat.messages.length - 1]?.sender === "user" &&
        chat?.status === "pending" &&
        chat?._id !== currentChat?._id
    ).length;

    return pendingCount + unansweredActiveCount;
  }, [pendingChats, activeChats, getCurrentSupportId, currentChat]);

  useEffect(() => {
    const supportId = getCurrentSupportId();
    if (supportId && socket) {
      socket.emit("joinSupportRoom", supportId);
    }
  }, [getCurrentSupportId]);

  useEffect(() => {
    if (socket && activeChats?.length > 0) {
      // Join all active chats when socket is ready and active chats are loaded
      activeChats?.forEach((chat) => {
        socket.emit("joinChat", { chatId: chat._id });
      });
    }
  }, [socket, activeChats]);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SC_SOCKET_API); // Adjust the URL as needed
    setSocket(newSocket);

    const supportId = JSON.parse(localStorage.getItem("USER_INFO") || "{}")?.id;
    if (supportId) {
      newSocket.emit("joinSupportRoom", supportId);
    }

    newSocket.on("chatAccepted", ({ chatId, supportId, chatData }) => {
      const currentSupportId = getCurrentSupportId();

      setPendingChats((prev) => prev?.filter((chat) => chat._id !== chatId));

      if (supportId === currentSupportId) {
        setActiveChats((prev) => {
          if (chatData) {
            return [
              { ...chatData, status: "active", supportId },
              ...prev.filter((chat) => chat._id !== chatId),
            ];
          }
          return prev;
        });
      } else {
        setActiveChats((prev) => prev.filter((chat) => chat._id !== chatId));
        setCurrentChat((prev) => (prev && prev._id === chatId ? null : prev));
      }
    });

    newSocket.on("chatAssigned", (chat) => {

      setActiveChats((prev) => [
        ...prev.filter((c) => c._id !== chat._id),
        prev.find((item) => item._id === chat._id),
      ]);
    });

    newSocket.on("message", (message) => {

      setActiveChats((prev) => {
        const updatedChats = prev.filter((item) => item._id !== message.chatId);
        const activeChat = prev.find((item) => item._id === message.chatId);

        if (activeChat) {
          const updatedChat = {
            ...activeChat,
            status: message.sender === "support" ? "active" : "pending",
            messages: [...activeChat.messages, message],
          };

          return [updatedChat, ...updatedChats];
        }

        return prev;
      });

      setCurrentChat((prev) => {
        if (prev && prev._id === message.chatId) {
          return {
            ...prev,
            messages: [...prev.messages, message],
            status: message.sender === "user" ? "pending" : "active",
          };
        }
        return prev;
      });
    });

    newSocket.on("pendingMessage", (data) => {
      setPendingChats((prev) => {
        const existingChatIndex = prev.findIndex(
          (chat) => chat._id === data._id
        );
        if (existingChatIndex !== -1) {
          const updatedChat = {
            ...prev[existingChatIndex],
            messages: [...prev[existingChatIndex].messages, data.message],
          };
          const updatedChats = prev.filter(
            (_, index) => index !== existingChatIndex
          );
          return [updatedChat, ...updatedChats];
        } else {
          return [
            { ...data, messages: [data.message], status: "pending" },
            ...prev,
          ];
        }
      });
    });

    newSocket.on("seenChat", (messages) => {
      if (messages[messages.length - 1]?.seenAt) {
        setIsSeen(true);
      }
    });

    newSocket.on("messageDeleted", ({ chatId, messageId }) => {
      updateChatsAfterDelete(chatId, messageId);
    });

    // Fetch initial pending and active chats
    fetchPendingChats();
    fetchActiveChats();

    return () => newSocket.close();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBadgeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const fetchPendingChats = async () => {
    try {
      const token = localStorage.getItem(CONST.STORAGE.ACCESS_TOKEN);
      if (token) {
        const res = await axios.get(
          `${process.env.REACT_APP_SC_BACKEND_API}/chat/pending`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPendingChats(res?.data?.data);
      }
    } catch (error) {}
  };

  const fetchActiveChats = async () => {
    try {
      const token = localStorage.getItem(CONST.STORAGE.ACCESS_TOKEN);
      if (token) {
        const res = await axios.get(
          `${process.env.REACT_APP_SC_BACKEND_API}/chat/active`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setActiveChats((prevChats) => {
          const updatedChats = res?.data?.data.map((item) => {
            const lastMessage = item.messages[item.messages.length - 1];
            if (lastMessage && lastMessage.sender !== "support") {
              return { ...item, status: "pending" };
            }
            return item;
          });

          const pendingChats = updatedChats.filter(
            (chat) => chat.status === "pending"
          );
          const nonPendingChats = updatedChats.filter(
            (chat) => chat.status !== "pending"
          );

          const sortedPendingChats = pendingChats.sort(
            (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
          );

          return [...sortedPendingChats, ...nonPendingChats];
        });
      }
    } catch (error) {
      console.log("Lỗi khi lấy người dùng đang hoạt động.", error);
    }
  };

  const closeChat = (chatItem) => {
    if (chatItem) {
      socket.emit("closeChat", { chatId: chatItem._id });
      setCurrentChat(null);
      setActiveChats((prev) => {
        const newArrayActiveChat = prev.filter(
          (chat) => chat._id !== chatItem._id
        );
        return newArrayActiveChat;
      });
    }
  };

  const acceptChat = (chatItem) => {
    const idAdmin = JSON.parse(localStorage.getItem("USER_INFO") || "{}");
    if (idAdmin.id && chatItem.status === "pending" && !chatItem.supportId) {
      socket.emit("acceptChat", {
        chatId: chatItem._id,
        supportId: idAdmin.id,
      });

      setPendingChats((prev) => {
        const newArrayPendingChat = prev.filter(
          (chat) => chat._id !== chatItem._id
        );
        return newArrayPendingChat;
      });
      setActiveChats((prev) => {
        const updatedChat = {
          ...chatItem,
          status: "active",
          supportId: idAdmin.id,
        };
        return [
          updatedChat,
          ...prev.filter((chat) => chat._id !== chatItem._id),
        ];
      });

      setCurrentChat({
        ...chatItem,
        status: "active",
        supportId: idAdmin.id,
      });
    }
  };

  const seenChat = (chat) => {
    if (currentChat) {
      socket.emit("seenChat", { chatId: chat._id, seenBy: "support" });
    }
  };

  const sendMessage = (content) => {
    if (currentChat) {
      socket.emit("message", {
        chatId: currentChat._id,
        sender: "support",
        content,
      });
      setIsSeen(false);
    }
  };

  const joinChat = (chatId) => {
    socket.emit("joinChat", { chatId: chatId._id });
  };

  const updateChatsAfterDelete = useCallback((chatId, messageId) => {
    setActiveChats((prevChats) =>
      prevChats.map((chat) => {
        if (chat._id === chatId) {
          return {
            ...chat,
            messages: chat.messages.filter((msg) => msg._id !== messageId),
          };
        }
        return chat;
      })
    );

    setCurrentChat((prevChat) => {
      if (prevChat && prevChat._id === chatId) {
        return {
          ...prevChat,
          messages: prevChat.messages.filter((msg) => msg._id !== messageId),
        };
      }
      return prevChat;
    });
  })

  const deleteMessage = useCallback((chatId, messageId) => {
    updateChatsAfterDelete(chatId, messageId);
    socket.emit("deleteMessage", { chatId, messageId });
  }, [socket, updateChatsAfterDelete]);

  //Hàm để rút gọn tin nhắn ở Popover
  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) return text;
    return text?.substr(0, maxLength) + "...";
  };

  return (
    <ChatContext.Provider
      value={{
        pendingChats,
        activeChats,
        currentChat,
        messages,
        seenChat,
        searchText,
        anchorEl,
        handleClose,
        closeChat,
        handleBadgeClick,
        setCurrentChat,
        acceptChat,
        sendMessage,
        joinChat,
        truncateText,
        handleSearch,
        isSeen,
        pendingUsersCount,
        setActiveChats,
        deleteMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
