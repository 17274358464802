import React from "react";

function MoneyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M8 4.5V12.5M6 10.6212L6.58592 11.0607C7.36694 11.6464 8.63322 11.6464 9.41424 11.0607C10.1953 10.4749 10.1953 9.52513 9.41424 8.93934C9.02369 8.64642 8.5118 8.49997 7.99993 8.5C7.51664 8.50003 7.03347 8.3536 6.66473 8.06068C5.92733 7.47489 5.92733 6.52514 6.66473 5.93936C7.40213 5.35357 8.59769 5.35357 9.33509 5.93936L9.61168 6.15908M14 8.5C14 11.8137 11.3137 14.5 8 14.5C4.68629 14.5 2 11.8137 2 8.5C2 5.18629 4.68629 2.5 8 2.5C11.3137 2.5 14 5.18629 14 8.5Z"
        stroke="#FF8900"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MoneyIcon;
