import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apiKeyList: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  keySearch: "",
  loading: false,
  error: null,
};

const apiKeySlice = createSlice({
  name: "api-key",
  initialState,
  reducers: {
    createApiKey: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllApiKey: (state, { payload }) => {
      state.loading = true;
      state.error = null;
    },
    getAllApiKeySuccess: (state, { payload }) => {
      state.loading = false;
      state.apiKeyList = payload;
    },
    getAllApiKeyFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    updateApiKey: (state) => {
      state.loading = true;
      state.error = null;
    },
    getApiKeyById: (state) => {
      state.loading = true;
      state.error = null;
    },
    getApiKeyByIdSuccess: (state, { payload }) => {
      state.contentPromptDetail = payload;
      state.loading = false;
    },
    getApiKeyByIdFailure: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    deleteApiKey: (state, { payload }) => {},
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    setKeySearch: (state, { payload }) => {
      state.keySearch = payload;
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
    },
  },
});

const ApiKeyReducer = apiKeySlice.reducer;
export default ApiKeyReducer;

export const ApiKeyActions = apiKeySlice.actions;
export const ApiKeySelectors = {
  apiKeyList: (state) => state.apiKey.apiKeyList,
  loading: (state) => state.apiKey.loading,
  error: (state) => state.apiKey.error,
  pagination: (state) => state.apiKey.pagination,
  keySearch: (state) => state.apiKey.keySearch,
};
