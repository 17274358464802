// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes home-an-container {
  from {
    transform: translateY(20px);
    opacity: 0.1;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.home-container {
  display: flex;
  background-color: white;
}

.home-left-container {
  position: relative;
}
.home-right-container {
  animation: home-an-container 0.4s ease-in-out;
  padding-left: 348px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,2BAA2B;IAC3B,YAAY;EACd;EACA;IACE,0BAA0B;IAC1B,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,6CAA6C;EAC7C,mBAAmB;EACnB,WAAW;AACb","sourcesContent":["@keyframes home-an-container {\n  from {\n    transform: translateY(20px);\n    opacity: 0.1;\n  }\n  to {\n    transform: translateY(0px);\n    opacity: 1;\n  }\n}\n\n.home-container {\n  display: flex;\n  background-color: white;\n}\n\n.home-left-container {\n  position: relative;\n}\n.home-right-container {\n  animation: home-an-container 0.4s ease-in-out;\n  padding-left: 348px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
