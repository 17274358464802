import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contentPromptList: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  keySearch: "",
  loading: false,
  error: null,
};

const ContentPromptSlice = createSlice({
  name: "content-prompt",
  initialState,
  reducers: {
    createContentPrompt: (state) => {
      state.loading = true;
      state.error = null;
    },
    postNewsByContentPrompt: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllContentPrompt: (state, { payload }) => {
      state.loading = true;
      state.error = null;
    },
    getAllContentPromptSuccess: (state, { payload }) => {
      state.loading = false;
      state.contentPromptList = payload;
    },
    getAllContentPromptFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    updateContentPrompt: (state) => {
      state.loading = true;
      state.error = null;
    },
    getContentPromptById: (state) => {
      state.loading = true;
      state.error = null;
    },
    getContentPromptByIdSuccess: (state, { payload }) => {
      state.contentPromptDetail = payload;
      state.loading = false;
    },
    getContentPromptByIdFailure: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    deleteContentPrompt: (state, { payload }) => {},
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    setKeySearch: (state, { payload }) => {
      state.keySearch = payload;
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

const ContentPromptReducer = ContentPromptSlice.reducer;
export default ContentPromptReducer;

export const ContentPromptActions = ContentPromptSlice.actions;
export const ContentPromptSelectors = {
  contentPromptList: (state) => state.contentPrompt.contentPromptList,
  loading: (state) => state.contentPrompt.loading,
  error: (state) => state.contentPrompt.error,
  pagination: (state) => state.contentPrompt.pagination,
  keySearch: (state) => state.contentPrompt.keySearch,
};
