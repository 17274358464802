import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomerActions } from "../../app/services/customer/customer.slice";
import MST from "../../components";

const vipLevelList = [
  { name: "0", value: 0, des: "KH cá nhân", color: "bg-gray-200" },
  { name: "1", value: 1, des: "KH nhà phân phối", color: "bg-blue-200" },
  { name: "2", value: 2, des: "KH đại lý", color: "bg-green-200" },
  { name: "3", value: 3, des: "KH đặc biệt", color: "bg-purple-200" },
];

function UpdateVipLevelModal({ id, customerDetail, onHide }) {
  const dispatch = useDispatch();
  const [vipLevel, setVipLevel] = useState(vipLevelList[0]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setVipLevel(
      vipLevelList.find(
        (level) => level.value === (customerDetail?.vipLevel || 0)
      ) || vipLevelList[0]
    );
  }, [customerDetail]);

  const onSubmit = () => {
    dispatch(
      CustomerActions.update({
        id,
        body: {
          vipLevel: vipLevel.value,
        },
        onSuccess: onHide,
      })
    );
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectVipLevel = (level) => {
    setVipLevel(level);
    setIsOpen(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 w-96 shadow-2xl transform transition-all duration-300 ease-in-out">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">
          Thay đổi cấp độ VIP
        </h2>

        <div className="relative mb-6">
          <div
            className={`border rounded-lg p-3 cursor-pointer flex justify-between items-center transition-all duration-300 ${vipLevel.color} hover:shadow-md`}
            onClick={toggleDropdown}
          >
            <span className="font-semibold">
              {vipLevel.name} - {vipLevel.des}
            </span>
            <span
              className={`transform transition-transform duration-300 ${
                isOpen ? "rotate-180" : ""
              }`}
            >
              ▼
            </span>
          </div>
          {isOpen && (
            <div className="absolute top-full left-0 right-0 bg-white rounded-lg mt-1 shadow-lg z-10 overflow-hidden">
              {vipLevelList.map((level, index) => (
                <div
                  key={level.value}
                  className={`
                    p-3 hover:bg-opacity-80 cursor-pointer flex justify-between items-center transition-all duration-200 
                    ${level.color}
                    ${index !== 0 ? "border-t border-gray-200" : ""}
                    ${index === 0 ? "[&:first-child]:rounded-t-lg" : ""}
                    ${
                      index === vipLevelList.length - 1
                        ? "[&:last-child]:rounded-b-lg"
                        : ""
                    }
                  `}
                  onClick={() => selectVipLevel(level)}
                >
                  <span className="font-medium">{level.name}</span>
                  <span className="text-gray-600">{level.des}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <MST.Button
            onClick={onHide}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors duration-300"
          >
            Hủy
          </MST.Button>
          <MST.Button
            onClick={onSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300"
          >
            Xác nhận
          </MST.Button>
        </div>
      </div>
    </div>
  );
}

export default UpdateVipLevelModal;
