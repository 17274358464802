import React, { useEffect, useState, useCallback } from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import ServiceCreatePage from "../service-create/ServiceCreatePage";
import { useDispatch } from "react-redux";
import { ServiceActions } from "../../app/services/service/service.slice";
function ServiceEditPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const getDetail = useCallback(() => {
    dispatch(
      ServiceActions.getServiceById({
        id: params.id,
        onSuccess: (data) => {
          setIsLoading(false);
        },
      })
    );
  }, [dispatch, params.id]);

  useEffect(() => {
    let isMounted = true;

    const fetchServiceDetail = async () => {
      if (params.id) {
        try {
          await getDetail();
          if (isMounted) {
            setIsLoading(false);
          }
        } catch (error) {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      }
    };

    fetchServiceDetail();

    return () => {
      isMounted = false;
      dispatch(ServiceActions.resetServiceDetail());
    };
  }, [params.id, getDetail, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <ServiceCreatePage />;
}

export default ServiceEditPage;
