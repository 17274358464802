import React from "react";
import './style.css'
function IconMenu() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        data-slot="trigger"
        color="#FF8900"
        width="31"
        className="icon-mobile"
        aria-expanded="false"
      >
        <path
          d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
        ></path>
      </svg>
    </div>
  );
}

export default IconMenu;
