import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MST from "../../components";
import Pagination from "../../components/base/pagination/Pagination";
import {
  VoucherActions,
  VoucherSelectors,
} from "../../app/services/voucher/voucher.slice";
import { useEffect, useCallback, useState } from "react";
import moment from "moment/moment";
import VoucherSearch from "./VoucherSearch";
import VouchereDeleteModal from "./Voucher.Option";

const thead = [
  {
    name: "STT",
    style: { width: 20 },
    className: "",
  },
  {
    name: "Tên Voucher",
  },
  {
    name: "Giảm",
    style: {
      width: 50,
    },
  },
  {
    name: "Dịch vụ áp dụng",
    style: {
      width: 280,
    },
  },
  {
    name: "Ngày tạo",
    style: {
      width: 280,
    },
  },
  {
    name: "Thao tác",
    style: {
      width: 100,
    },
  },
];

function VoucherList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const voucherList = useSelector(VoucherSelectors.voucherList);
  const pagination = useSelector(VoucherSelectors.pagination);
  const currentListPage = useSelector(VoucherSelectors.currentListPage);

  const [textSearch, setTextSearch] = useState("");
  const [serviceList, setServiceList] = useState([]);

  const handleSearchByService = (value) => {
    setServiceList(value);
  };

  const handleSearchByText = (value) => {
    setTextSearch(value);
  };

  const genListService = (services) => {
    let stringService = "";
    services.forEach((element, index) => {
      stringService += `${element.name}${
        services.length - 1 === index ? "" : ", "
      } `;
    });

    return stringService;
  };

  const genRenderList = useCallback(() => {
    return voucherList.map((x, index) => {
      return [
        {
          value:
            pagination.page === 1
              ? index + 1
              : 10 * pagination.page - (9 - index),
        },
        { value: x?.name, style: { textAlign: "center" } },
        { value: `${x.discount}%`, style: { width: 50 } },
        {
          value: x?.service
            ? genListService(x?.service)
            : "Chưa áp dụng dịch vụ nào",
          style: { width: 400 },
        },
        {
          value: moment(x?.createdAt).format("DD/MM/YYYY"),
          style: { width: 100 },
        },
        {
          value: (
            <VouchereDeleteModal
              id={x._id}
              data={{
                search: textSearch,
                "service[in]": serviceList,
                page: currentListPage,
              }}
            />
          ),
        },
      ];
    });
  }, [voucherList, pagination.page]);

  useEffect(() => {
    dispatch(
      VoucherActions.getVouchers({
        search: textSearch,
        "service[in]": serviceList,
        page: currentListPage,
      })
    );
    <MST.Table head={thead} body={genRenderList()} />;
  }, [location.search, dispatch, textSearch, serviceList]);

  const onChangePage = (page) => {
    dispatch(VoucherActions.setCurrentListPage(page));
    navigate(`/services/vouchers/?page=${page}`);
  };

  return (
    <div>
      <div className="mb-2">
        <VoucherSearch
          searchText={handleSearchByText}
          searchService={handleSearchByService}
        />
      </div>
      <MST.Table head={thead} body={genRenderList()} />
      <div className="voucher-pagination">
        <Pagination
          onChange={onChangePage}
          page={pagination?.page}
          pageSize={pagination?.limit}
          totalPage={pagination?.totalPage}
          total={pagination?.total}
        />
      </div>
    </div>
  );
}

export default VoucherList;

export const sGenColor = (content) => {
  switch (content) {
    case "expired":
      return "red";
    case "active":
      return "#23C16B";
    case "unactive":
      return "#72777A";
    case "outOfStock":
      return "#FFA500"; // Or any color you prefer for out of stock
    default:
      return "black";
  }
};
