// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container {
}
.title-content {
  color: var(--Dark-600, #090a0a);
  text-align: center;

  /* Title 3 */
  font-size: 24px;
  font-style: normal;
  line-height: 32px; /* 133.333% */
}
`, "",{"version":3,"sources":["webpack://./src/components/base/title/style.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,+BAA+B;EAC/B,kBAAkB;;EAElB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,iBAAiB,EAAE,aAAa;AAClC","sourcesContent":[".title-container {\n}\n.title-content {\n  color: var(--Dark-600, #090a0a);\n  text-align: center;\n\n  /* Title 3 */\n  font-size: 24px;\n  font-style: normal;\n  line-height: 32px; /* 133.333% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
