// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes sw-enable {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes sw-unenable {
  from {
    transform: translateX(20px);
  }
  to {
    transform: translateX(0px);
  }
}
.switch-container {
  display: flex;
  cursor: pointer;
}

.switch-content-enable {
  background-color: #ff8900;
  height: 24px;
  width: 43px;
  border-radius: 50px;
  position: relative;
  transition-duration: 1s;
}

.switch-content-unenable {
  background-color: #cccccc80;
  height: 24px;
  width: 43px;
  border-radius: 50px;
  position: relative;
  transition-duration: 1s;
}

.switch-point-enable {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  right: 2px;
  top: 2px;
  transition-duration: 0.3s;
  animation: sw-enable 0.3s ease-in-out;
}

.switch-point-unenable {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  left: 2px;
  top: 2px;
  transition-duration: 0.3s;
  animation: sw-unenable 0.3s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/base/switch/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,0BAA0B;EAC5B;AACF;AACA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,0BAA0B;EAC5B;AACF;AACA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;EAC3B,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,yBAAyB;EACzB,qCAAqC;AACvC;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,yBAAyB;EACzB,uCAAuC;AACzC","sourcesContent":["@keyframes sw-enable {\n  from {\n    transform: translateX(-20px);\n  }\n  to {\n    transform: translateX(0px);\n  }\n}\n@keyframes sw-unenable {\n  from {\n    transform: translateX(20px);\n  }\n  to {\n    transform: translateX(0px);\n  }\n}\n.switch-container {\n  display: flex;\n  cursor: pointer;\n}\n\n.switch-content-enable {\n  background-color: #ff8900;\n  height: 24px;\n  width: 43px;\n  border-radius: 50px;\n  position: relative;\n  transition-duration: 1s;\n}\n\n.switch-content-unenable {\n  background-color: #cccccc80;\n  height: 24px;\n  width: 43px;\n  border-radius: 50px;\n  position: relative;\n  transition-duration: 1s;\n}\n\n.switch-point-enable {\n  border-radius: 20px;\n  width: 20px;\n  height: 20px;\n  background-color: white;\n  position: absolute;\n  right: 2px;\n  top: 2px;\n  transition-duration: 0.3s;\n  animation: sw-enable 0.3s ease-in-out;\n}\n\n.switch-point-unenable {\n  border-radius: 20px;\n  width: 20px;\n  height: 20px;\n  background-color: white;\n  position: absolute;\n  left: 2px;\n  top: 2px;\n  transition-duration: 0.3s;\n  animation: sw-unenable 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
