import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { PromotionActions } from "../../app/services/promotions/promotion.slice";
import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function PromotionSwitch({ id, isHidden }) {
  const [localIsHidden, setLocalIsHidden] = useState(isHidden);
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalIsHidden(isHidden);
  }, [isHidden]);

  const handleToggle = useCallback(() => {
    const newHiddenState = !localIsHidden;
    setLocalIsHidden(newHiddenState);
    dispatch(
      PromotionActions.edit({
        id,
        body: { is_hidden: newHiddenState },
        onSuccess: () => {
          toast.success("Cập nhật Promotion thành công!");
        },
        onError: (error) => {
          toast.error("Lỗi khi cập nhật Promotion: " + error.message);
          setLocalIsHidden(!newHiddenState); // Revert if failed
        },
      })
    );
  }, [dispatch, id, localIsHidden]);

  return (
    <IOSSwitch sx={{ m: 1 }} checked={localIsHidden} onChange={handleToggle} />
  );
}
