// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes tab-one-item-line-active-an {
  from {
    opacity: 0.1;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.tab-container {
  width: 100%;
  display: flex;
}

.tab-one-item {
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
  transition-duration: 0.5s;
}

.tab-one-item-line-active {
  transition-duration: 0.3s;
  animation: tab-one-item-line-active-an 0.5s ease-in-out;
  background-color: #ff8900;
  height: 2px;
  border-radius: 20px;
}

.tab-one-item-line-unactive {
  background-color: white;
  height: 2px;
  border-radius: 20px;
}

.tab-one-item-label-unactive {
  color: #72777a;
  padding-block: 8px;
}
.tab-one-item-label-active {
  color: #ff8900;
  padding-block: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/tab/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,mBAAmB;EACrB;EACA;IACE,UAAU;IACV,mBAAmB;EACrB;AACF;AACA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,uDAAuD;EACvD,yBAAyB;EACzB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["@keyframes tab-one-item-line-active-an {\n  from {\n    opacity: 0.1;\n    transform: scale(0);\n  }\n  to {\n    opacity: 1;\n    transform: scale(1);\n  }\n}\n.tab-container {\n  width: 100%;\n  display: flex;\n}\n\n.tab-one-item {\n  font-size: 14px;\n  margin-right: 16px;\n  cursor: pointer;\n  transition-duration: 0.5s;\n}\n\n.tab-one-item-line-active {\n  transition-duration: 0.3s;\n  animation: tab-one-item-line-active-an 0.5s ease-in-out;\n  background-color: #ff8900;\n  height: 2px;\n  border-radius: 20px;\n}\n\n.tab-one-item-line-unactive {\n  background-color: white;\n  height: 2px;\n  border-radius: 20px;\n}\n\n.tab-one-item-label-unactive {\n  color: #72777a;\n  padding-block: 8px;\n}\n.tab-one-item-label-active {\n  color: #ff8900;\n  padding-block: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
