// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-detail-general {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 20px;
}
.order-detail-content {
  display: flex;
}
.order-detail-detail {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
}

.order-detail-one-general {
  flex: 1 1;
}
.order-detail-one-general-label {
  color: var(--Dark-600, #090a0a);
  line-height: 24px; /* 150% */
}
.order-detail-one-general-value {
  color: var(--Primary-500, #ff8900);
  line-height: 24px; /* 133.333% */
}

.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-start {
  justify-content: flex-start;
}
.item-start {
  align-items: flex-start;
}

.gap-4 {
  gap: 16px;
}

.text-primary {
  color: #ff8900;
}

.text-none {
  text-decoration: none;
}

.text-unset {
  color: unset;
}
`, "",{"version":3,"sources":["webpack://./src/pages/order-detail/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT;AACA;EACE,+BAA+B;EAC/B,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,kCAAkC;EAClC,iBAAiB,EAAE,aAAa;AAClC;;AAEA;EACE,WAAW;AACb;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;AACjB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".order-detail-general {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n  margin-bottom: 20px;\n}\n.order-detail-content {\n  display: flex;\n}\n.order-detail-detail {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n}\n\n.order-detail-one-general {\n  flex: 1;\n}\n.order-detail-one-general-label {\n  color: var(--Dark-600, #090a0a);\n  line-height: 24px; /* 150% */\n}\n.order-detail-one-general-value {\n  color: var(--Primary-500, #ff8900);\n  line-height: 24px; /* 133.333% */\n}\n\n.w-full {\n  width: 100%;\n}\n.flex {\n  display: flex;\n}\n.flex-wrap {\n  flex-wrap: wrap;\n}\n.justify-start {\n  justify-content: flex-start;\n}\n.item-start {\n  align-items: flex-start;\n}\n\n.gap-4 {\n  gap: 16px;\n}\n\n.text-primary {\n  color: #ff8900;\n}\n\n.text-none {\n  text-decoration: none;\n}\n\n.text-unset {\n  color: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
