import React from "react";

function CustomersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.0002 18.7191C18.2477 18.7396 18.498 18.75 18.7508 18.75C19.7992 18.75 20.8056 18.5708 21.7413 18.2413C21.7476 18.1617 21.7508 18.0812 21.7508 18C21.7508 16.3431 20.4077 15 18.7508 15C18.1232 15 17.5406 15.1927 17.059 15.5222M18.0002 18.7191C18.0002 18.7294 18.0002 18.7397 18.0002 18.75C18.0002 18.975 17.9879 19.1971 17.9637 19.4156C16.207 20.4237 14.1709 21 12.0002 21C9.82957 21 7.79352 20.4237 6.03675 19.4156C6.01263 19.1971 6.00024 18.975 6.00024 18.75C6.00024 18.7397 6.00027 18.7295 6.00032 18.7192M18.0002 18.7191C17.9943 17.5426 17.6497 16.4461 17.059 15.5222M17.059 15.5222C15.9931 13.8552 14.1257 12.75 12.0002 12.75C9.87504 12.75 8.0079 13.8549 6.94193 15.5216M6.94193 15.5216C6.46047 15.1925 5.8782 15 5.25098 15C3.59412 15 2.25098 16.3431 2.25098 18C2.25098 18.0812 2.2542 18.1617 2.26054 18.2413C3.19617 18.5708 4.20264 18.75 5.25098 18.75C5.50332 18.75 5.75323 18.7396 6.00032 18.7192M6.94193 15.5216C6.35095 16.4457 6.00623 17.5424 6.00032 18.7192M15.0002 6.75C15.0002 8.40685 13.6571 9.75 12.0002 9.75C10.3434 9.75 9.00024 8.40685 9.00024 6.75C9.00024 5.09315 10.3434 3.75 12.0002 3.75C13.6571 3.75 15.0002 5.09315 15.0002 6.75ZM21.0002 9.75C21.0002 10.9926 19.9929 12 18.7502 12C17.5076 12 16.5002 10.9926 16.5002 9.75C16.5002 8.50736 17.5076 7.5 18.7502 7.5C19.9929 7.5 21.0002 8.50736 21.0002 9.75ZM7.50024 9.75C7.50024 10.9926 6.49288 12 5.25024 12C4.0076 12 3.00024 10.9926 3.00024 9.75C3.00024 8.50736 4.0076 7.5 5.25024 7.5C6.49288 7.5 7.50024 8.50736 7.50024 9.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CustomersIcon;
