// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  border-radius: 8px;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  overflow: hidden;
}
.input-container-no-error {
  border: 1px solid #e3e5e5;
  transition-duration: 0.5s;
}
.input-container-has-error {
  border: 1px solid #ff0000;
  transition-duration: 0.5s;
}

.input-content {
  border: 0px solid #e3e5e5;
  width: 100%;
  padding-inline: 12px;
  padding-block: 8px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Cabin", sans-serif;
  height: 50px;
  outline: none;
}

.input-right {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: white;
}

.input-left {
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: white;
}

.input-error-message {
  color: red;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/base/input/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,gCAAgC;EAChC,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB","sourcesContent":[".input-container {\n  border-radius: 8px;\n  background-color: white;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 50px;\n  overflow: hidden;\n}\n.input-container-no-error {\n  border: 1px solid #e3e5e5;\n  transition-duration: 0.5s;\n}\n.input-container-has-error {\n  border: 1px solid #ff0000;\n  transition-duration: 0.5s;\n}\n\n.input-content {\n  border: 0px solid #e3e5e5;\n  width: 100%;\n  padding-inline: 12px;\n  padding-block: 8px;\n  border-radius: 8px;\n  font-size: 16px;\n  font-family: \"Cabin\", sans-serif;\n  height: 50px;\n  outline: none;\n}\n\n.input-right {\n  position: absolute;\n  right: 0px;\n  top: 0px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 48px;\n  background-color: white;\n}\n\n.input-left {\n  position: absolute;\n  left: 0px;\n  top: 0px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 48px;\n  background-color: white;\n}\n\n.input-error-message {\n  color: red;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
