import { useNavigate } from "react-router-dom";
import MST from "../../components";
import VoucherHistoryList from "./VoucherHistoryList";
import "./style.css";
function VoucherHistoryPage() {
  const navigate = useNavigate();
  return (
    <MST.Container title="Lịch sử áp dụng Voucher">
      <div className="voucher-content">
        <VoucherHistoryList />
      </div>
    </MST.Container>
  );
}
export default VoucherHistoryPage;
