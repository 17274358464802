import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import store from "../app/services/store";

import { setConfigAxios } from "../app/fetch";
import CONST from "../app/services/const";
import InitComponent from "../components/InitComponent";
import ChangePasswordPage from "./change-password/ChangePasswordPage";
import CustomerDetailPage from "./customer-detail/CustomerDetailPage";
import CustomerPage from "./customer/CustomerPage";
import DashBoardPage from "./dashboard/DashBoardPage";
import GeneralSettingPage from "./general-setting/GeneralSettingPage";
import HomePage from "./home/HomePage";
import KeywordEditPage from "./keyword-edit/KeywordEditPage";
import KeywordPage from "./keyword/KeywordPage";
import LocalServerCreatePage from "./local-server-setting/LocalServerCreatePage";
import LocalServicePage from "./local-server-setting/LocalServerPage";
import LoginPage from "./login/LoginPage";
import NewsCreatePage from "./news-create/NewsCreatePage";
import NewsEditPage from "./news-edit/NewsEditPage";
import NewsPage from "./news/NewsPage";
import NotificationCreatePage from "./notification-create/NotificationCreatePage";
import NotificationEditPage from "./notification-edit/NotificationEditPage";
import NotificationPage from "./notifications/NotificationPage";
import OrderDetailPage from "./order-detail/OrderDetailPage";
import OrderPage from "./order/OrderPage";
import PageNotFound from "./page-not-found/PageNotFoundPage";
import PartnerSettingPage from "./partner-setting/PartnerSettingPage";
import PaymentSettingPage from "./payment-setting/PaymentSettingPage";
import ServiceCreatePage from "./service-create/ServiceCreatePage";
import ServiceEditPage from "./service-edit/ServiceEditPage";
import ServicePage from "./service/ServicePage";
import SupportAccountCreatePage from "./support-account-setting/SupportAccountCreatePage";
import SupportAccountPage from "./support-account-setting/SupportAccountPage";
import TopicEditPage from "./topic-edit/TopicEditPage";
import TopicPage from "./topic/TopicPage";
import VoucherCreatePage from "./voucher-create/VoucherCreatePage";
import VoucherEditPage from "./voucher-edit/VoucherEditPage";
import VoucherHistoryPage from "./voucher-history/VoucherHistoryPage";
import VoucherPage from "./voucher/VoucherPage";
import ServiceGroupsPage from "./service-groups/ServiceGroupsPage";

import ApiKeyPage from "./api-key/ApiKeyPage";
import ContentPromptPage from "./content-prompt/ContentPromptPage";
import AddPromotionForm from "./promotions/PromotionAdd";
import PromotionPage from "./promotions/PromotionPage";
import VisitPage from "./visit/VisitPage";
import NewsAccountPage from "./news-account-setting/NewsAccountPage";
import NewsAccountCreatePage from "./news-account-setting/NewsAccountCreatePage";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: "services",
        errorElement: <PageNotFound />,
        children: [
          {
            path: "dashboard",
            element: <DashBoardPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "visit",
            element: <VisitPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "services",
            element: <ServicePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "services-groups",
            element: <ServiceGroupsPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "services/create",
            element: <ServiceCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "services/edit/:id",
            element: <ServiceEditPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "customers/:id",
            element: <CustomerDetailPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "customers",
            element: <CustomerPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "orders",
            element: <OrderPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "orders/:id",
            element: <OrderDetailPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "notifications",
            element: <NotificationPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "notifications/create",
            element: <NotificationCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "notifications/edit/:id",
            element: <NotificationEditPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/general",
            element: <GeneralSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/payment",
            element: <PaymentSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/partner",
            element: <PartnerSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/local-server",
            element: <LocalServicePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/local-server/create",
            element: <LocalServerCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/local-server/edit/:id",
            element: <LocalServerCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/api-key",
            element: <ApiKeyPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/support-account",
            element: <SupportAccountPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/news-account",
            element: <NewsAccountPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/news-account/create",
            element: <NewsAccountCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/news-account/edit/:id",
            element: <NewsAccountCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/support-account/create",
            element: <SupportAccountCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/support-account/edit/:id",
            element: <SupportAccountCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "vouchers",
            element: <VoucherPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "vouchers/create",
            element: <VoucherCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "vouchers/edit/:id",
            element: <VoucherEditPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "voucher-histories",
            element: <VoucherHistoryPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "voucher-histories/:id",
            element: <VoucherHistoryPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "news",
            element: <NewsPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "news/create",
            element: <NewsCreatePage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "news/edit/:id",
            element: <NewsEditPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "content-prompt",
            element: <ContentPromptPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "promotions",
            element: <PromotionPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "promotions/create",
            element: <AddPromotionForm />,
            errorElement: <PageNotFound />,
          },
          {
            path: "promotions/:id/edit",
            element: <AddPromotionForm />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "keyword",
        errorElement: <PageNotFound />,
        children: [
          {
            path: "dashboard",
            element: <DashBoardPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/general",
            element: <GeneralSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/payment",
            element: <PaymentSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/partner",
            element: <PartnerSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "topics",
            element: <TopicPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "keywords",
            element: <KeywordPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "topics/edit/:id",
            element: <TopicEditPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "keywords/edit/:id",
            element: <KeywordEditPage />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "system",
        errorElement: <PageNotFound />,
        children: [
          {
            path: "change-password",
            element: <ChangePasswordPage />,
            errorElement: <PageNotFound />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <PageNotFound />,
  },
]);

const accessToken = localStorage.getItem(CONST.STORAGE.ACCESS_TOKEN);
setConfigAxios(accessToken);

function App() {
  return (
    <Provider store={store}>
      <InitComponent />
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
