// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.local-service-content {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
}

.service-create-content-container {
  display: flex;
}

.service-create-content-left {
  display: flex;
  flex-direction: column;
  flex: 5 1;
}
.service-create-content-right {
  margin-left: 16px;
  flex: 2 1;
}

.service-create-content {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 20px;
}
.service-create-title {
  color: var(--Dark-600, #090a0a);

  /* Large/Normal/Bold */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}
.service-create-one-field {
  margin-top: 20px;
}
.service-create-one-field-name {
  margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/news-account-setting/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;AACT;AACA;EACE,iBAAiB;EACjB,SAAO;AACT;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,+BAA+B;;EAE/B,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".local-service-content {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n}\n\n.service-create-content-container {\n  display: flex;\n}\n\n.service-create-content-left {\n  display: flex;\n  flex-direction: column;\n  flex: 5;\n}\n.service-create-content-right {\n  margin-left: 16px;\n  flex: 2;\n}\n\n.service-create-content {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n  margin-bottom: 20px;\n}\n.service-create-title {\n  color: var(--Dark-600, #090a0a);\n\n  /* Large/Normal/Bold */\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 24px; /* 133.333% */\n}\n.service-create-one-field {\n  margin-top: 20px;\n}\n.service-create-one-field-name {\n  margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
