// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tcs-table-container {
  border-spacing: 0px;
  width: 100%;
}
.tcs-td-base {
  border: 1px solid #f7f9fa;
  padding: 8px 12px 8px 12px;
}

.tcs-td-head {
  color: var(--Dark-100, #72777a);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.tcs-table-no-data {
  color: #72777a;
  padding: 20px;
  text-align: center;
}
.tcs-td-border {
  border: 1px solid #f7f9fa;
}
.tcs-delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tcs-cog-btn {
  border-width: 0px;
  border-radius: 50px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
  margin-left: 4px;
}
.tcs-tr {
  transition-duration: 0.5s;
}
.tcs-tr:hover {
  background-color: #cccccc10;
}

.btn-description {
  border: 0px;
  color: #ff8900;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/payment-setting/DepDisTable/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,cAAc;EACd,uBAAuB;AACzB","sourcesContent":[".tcs-table-container {\n  border-spacing: 0px;\n  width: 100%;\n}\n.tcs-td-base {\n  border: 1px solid #f7f9fa;\n  padding: 8px 12px 8px 12px;\n}\n\n.tcs-td-head {\n  color: var(--Dark-100, #72777a);\n  font-size: 14px;\n  line-height: 16px;\n  text-transform: uppercase;\n}\n\n.tcs-table-no-data {\n  color: #72777a;\n  padding: 20px;\n  text-align: center;\n}\n.tcs-td-border {\n  border: 1px solid #f7f9fa;\n}\n.tcs-delete-btn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.tcs-cog-btn {\n  border-width: 0px;\n  border-radius: 50px;\n  display: flex;\n  padding: 8px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  flex-shrink: 0;\n  cursor: pointer;\n  margin-left: 4px;\n}\n.tcs-tr {\n  transition-duration: 0.5s;\n}\n.tcs-tr:hover {\n  background-color: #cccccc10;\n}\n\n.btn-description {\n  border: 0px;\n  color: #ff8900;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
