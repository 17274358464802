import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerActions,
  CustomerSelectors,
} from "../../app/services/customer/customer.slice";
import { useParams } from "react-router-dom";

import Chart from "react-apexcharts";
import Datepicker from "react-tailwindcss-datepicker";
import { MenuItem, Select } from "@mui/material";

function CustomerDetailAggregate() {
  const params = useParams();
  const aggregate = useSelector(CustomerSelectors.aggregate);
  const dispatch = useDispatch();
  const customerID = useMemo(() => {
    return params?.id || "";
  }, [params]);

  const [type, setType] = useState("order");
  const title = useMemo(() => {
    return type == "order"
      ? "Số đơn"
      : type == "price"
      ? "Số tiền"
      : "Số lượng";
  }, [type]);

  useEffect(() => {
    dispatch(
      CustomerActions.getAggregate({
        startDate: undefined,
        endDate: undefined,
        customerID,
      })
    );
  }, []);

  const showData = useMemo(() => {
    let series = [];
    let labels = [];

    aggregate.map((e) => {
      labels.push(e?._id || "");
      series.push(
        type == "order"
          ? e?.totalOrders
          : type == "price"
          ? e?.totalPrice
          : e?.totalQuantity
      );
    });

    return {
      series,
      labels,
    };
  }, [aggregate, type]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const handleValueChange = (e) => {
    setStartDate(e.startDate);
    setEndDate(e.endDate);
  };

  useEffect(() => {
    dispatch(
      CustomerActions.getAggregate({
        startDate,
        endDate,
        customerID,
      })
    );
  }, [startDate, endDate]);

  return (
    <div className="flex flex-wrap justify-center items-start gap-12">
      <div className="flex-1 min-w-[600px]">
        <div className="w-full mb-8">
          <Datepicker
            value={{
              startDate,
              endDate,
            }}
            useRange={false}
            popoverDirection={`down`}
            containerClassName={`border border-slate-300 rounded-md relative`}
            onChange={handleValueChange}
            primaryColor="#ffbd70"
          />
        </div>
        <div className={`relative max-h-[400px] overflow-auto`}>
          <table className="w-full">
            <thead>
              <tr>
                <th className="sticky top-0 border border-slate-200 bg-[#FF8900] p-2 text-white w-[50px] rounded-tl-lg overflow-hidden">
                  #
                </th>
                <th className="sticky top-0 border border-slate-200 bg-[#FF8900] p-2 text-white w-[200px]">
                  Tên dịch vụ
                </th>
                <th className="sticky top-0 border border-slate-200 bg-[#FF8900] p-2 text-white w-[200px]">
                  Số đơn
                </th>
                <th className="sticky top-0 border border-slate-200 bg-[#FF8900] p-2 text-white w-[200px]">
                  Số lượng
                </th>
                <th className="sticky top-0 border border-slate-200 bg-[#FF8900] p-2 text-white w-[50px] rounded-tr-lg overflow-hidden">
                  Tổng tiền
                </th>
              </tr>
            </thead>
            <tbody>
              {aggregate?.length > 0 ? (
                aggregate.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td className="p-2 border border-slate-200 text-center">
                        {i + 1}
                      </td>
                      <td className="p-2 border border-slate-200">{e._id}</td>
                      <td className="p-2 border border-slate-200">
                        {e.totalOrders}
                      </td>
                      <td className="p-2 border border-slate-200">
                        {e.totalQuantity}
                      </td>
                      <td className="p-2 border border-slate-200 text-center">
                        {e.totalPrice}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="p-2 border border-slate-200 text-center"
                    colSpan={5}
                  >
                    Hiện không có dữ liệu
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex-1 flex flex-col justify-center">
        <h3 className="text-center capitalize font-semibold text-2xl pb-4">
          Thống kê theo {title}
        </h3>
        <Select
          title={"Thống kê theo " + title}
          onChange={(e) => {
            if (e.target.value.length) {
              setType(e.target.value);
            }
          }}
          value={type}
        >
          <MenuItem key={`order`} value={`order`}>
            Số đơn
          </MenuItem>
          <MenuItem key={`price`} value={`price`}>
            Số tiền
          </MenuItem>
          <MenuItem key={`quantity`} value={`quantity`}>
            Số lượng
          </MenuItem>
        </Select>
        <div className={`min-w-[400px]`}>
          {aggregate && (
            <Chart
              type="pie"
              width={"100%"}
              height={`${aggregate.length > 0 ? `600` : `100`}`}
              series={showData.series}
              options={{
                labels: showData?.labels,
                noData: { text: "Hiện không có dữ liệu" },
                legend: {
                  horizontalAlign: "left",
                  position: "bottom",
                  formatter: (val) => {
                    return val.length > 40 ? val.substring(0, 40) + "..." : val;
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerDetailAggregate;
