import AppReducer from "./app/app.slice.js";
import CustomerReducer from "./customer/customer.slice.js";
import KeywordReducer from "./keyword/keyword.slice.js";
import LoginReducer from "./login/login.slice.js";
import NotificationReducer from "./notification/notification.slice.js";
import OrderReducer from "./order/order.slice.js";
import PartnerReducer from "./partner/partner.slice.js";
import ServiceReducer from "./service/service.slice.js";
import SettingReducer from "./setting/setting.slice.js";
import TopicReducer from "./topic/topic.slice.js";
import DashBoardReducer from "./dashboard/dashboard.slice.js";
import LocalServerReducer from "./local-server/local-server.slice.js";
import SupportAccountReducer from "./support-account/support-account.slice.js";
import VoucherReducer from "./voucher/voucher.slice.js";
import NewsReducer from "./news/news.slice.js";
import PromotionReducer from "./promotions/promotion.slice.js";
import CheckLinkReducer from "./check-link/check-link.slice.js";
import ContentPromptReducer from "./content-prompt/content-prompt.slice";
import ApiKeyReducer from "./api-key/api-key.slice";
import VisitReducer from "./visit/visit.slice";
import VipLevelReducer from "./vip-level/slice.js";
import NewsAccountReducer from "./news-account/news-account.slice.js";
import ServicePricesReducer from "./service-prices/serivce.slice.js";

const reducer = {
  app: AppReducer,
  login: LoginReducer,
  customer: CustomerReducer,
  checklink: CheckLinkReducer,
  order: OrderReducer,
  service: ServiceReducer,
  setting: SettingReducer,
  notification: NotificationReducer,
  topic: TopicReducer,
  keyword: KeywordReducer,
  partner: PartnerReducer,
  dashboard: DashBoardReducer,
  localServer: LocalServerReducer,
  supportAccount: SupportAccountReducer,
  voucher: VoucherReducer,
  news: NewsReducer,
  promotion: PromotionReducer,
  contentPrompt: ContentPromptReducer,
  apiKey: ApiKeyReducer,
  visit: VisitReducer,
  vipLevel: VipLevelReducer,
  newsAccount: NewsAccountReducer,
  servicePrices: ServicePricesReducer,
};

export default reducer;
