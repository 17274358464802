import { delay, put, select, takeLatest } from "redux-saga/effects";
import { VoucherActions, VoucherSelectors } from "./voucher.slice";
import { toast } from "react-toastify";
import VoucherRequest from "./voucher.request";
import ServiceRequest from "../service/service.request";
import { ServiceActions } from "../service/service.slice";

function* VoucherSaga() {
  yield takeLatest(VoucherActions.getVouchers, getVouchers);
  yield takeLatest(VoucherActions.getVoucherHistories, getVoucherHistories);
  yield takeLatest(VoucherActions.getCreateInfo, getCreateInfo);
  yield takeLatest(VoucherActions.getVoucherById, getVoucherById);
  yield takeLatest(VoucherActions.createVoucher, createVoucher);
  yield takeLatest(VoucherActions.setSearch, setSearch);
  yield takeLatest(VoucherActions.setSearchHistories, setSearchHistories);
  yield takeLatest(VoucherActions.edit, edit);
  yield takeLatest(VoucherActions.delete, deleteVoucher);
  yield takeLatest(VoucherActions.getAllVoucherCodes, getAllVoucherCodes);
  yield takeLatest(VoucherActions.deleteVoucherCode, deleteVoucherCode);
  yield takeLatest(
    VoucherActions.getVoucherCodesBySearch,
    getVoucherCodesBySearch
  );
  yield takeLatest(
    VoucherActions.getQuantityVoucherCodes,
    getQuantityVoucherCodes
  );
}

export default VoucherSaga;

function* getVouchers({ payload }) {
  try {
    yield delay(100);
    const pagination = yield select(VoucherSelectors.pagination);
    const search = yield select(VoucherSelectors.search);
    const rs = yield VoucherRequest.getVouchers({
      code: search.length === 0 ? undefined : search,
      page: payload.page || pagination.page,
      limit: 10,
      search: payload.search,
      "service[in]": payload["service[in]"]?.length
        ? payload["service[in]"].join()
        : undefined,
    });
    if (rs.status === "success") {
      yield put(VoucherActions.setVouchers(rs.data.vouchers));
      yield put(VoucherActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getAllVoucherCodes({ payload }) {
  try {
    yield delay(100);

    const pagination = yield select(VoucherSelectors.pagination);
    const body = {
      page: pagination.page,
      limit: 10,
      sort: '-createdAt,code',
    };
    const rs = yield VoucherRequest.getAllVoucherCodes(payload.id, body);
    if (rs.status === "success") {
      yield put(VoucherActions.setAllVoucherCodes(rs.data.voucherCodes));
      yield put(VoucherActions.setPagination(rs.data.pagination));
      const rsCopy = yield VoucherRequest.getAllVoucherCodes(payload.id, {
        page: 1,
        limit: rs.data.pagination.total,
      });

      yield put(VoucherActions.setVoucherCodesCopy(rsCopy.data.voucherCodes));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getQuantityVoucherCodes({ payload }) {
  try {
    yield delay(100);
    const rs = yield VoucherRequest.generateVoucherCodes(payload);
    if (rs.status === "success") {
      toast.success("Thêm voucher code thành công");
      yield put(
        VoucherActions.getAllVoucherCodes({
          id: payload.voucher,
        })
      );
      payload.onSuccess();
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

function* getVoucherCodesBySearch({ payload }) {
  try {
    yield delay(100);
    const { page, limit } = yield select(VoucherSelectors.pagination);
    const rs = yield VoucherRequest.searchVoucherByStatusAndDate({
      page,
      limit,
      ...payload,
    });

    if (rs.status === "success") {
      yield put(VoucherActions.setVoucherCodesBySearch(rs.data.voucherCodes));
      yield put(VoucherActions.setPagination(rs.data.pagination));
      const rsCopy = yield VoucherRequest.searchVoucherByStatusAndDate(
        payload.id,
        {
          page: 1,
          limit: rs.data.pagination.total,
        }
      );
      yield put(VoucherActions.setVoucherCodesCopy(rsCopy.data.voucherCodes));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getVoucherHistories({ payload = undefined }) {
  try {
    yield delay(100);
    const pagination = yield select(VoucherSelectors.pagination);
    //search theo services
    const services = yield select(VoucherSelectors.servicesSearchHistory);
    const servicesParam = Array.isArray(services) ? services.join(",") : "";
    const rs = yield VoucherRequest.getVoucherHistories({
      search: payload?.length === 0 ? undefined : payload,
      page: pagination.page,
      pageSize: pagination.pageSize,
      service: servicesParam,
    });

    if (rs.success) {
      yield put(VoucherActions.setVoucherHistories(rs.data.voucher_histories));
      yield put(VoucherActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getVoucherById({ payload }) {
  try {
    yield delay(100);
    const rs = yield VoucherRequest.getVoucherById(payload.id);
    if (rs.status === "success") {
      yield put(VoucherActions.setVoucherDetail(rs.data.voucher));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getCreateInfo() {
  try {
    yield delay(100);
    const rsServicePackages = yield ServiceRequest.getServices();
    if (rsServicePackages.success) {
      yield put(
        ServiceActions.setServices(
          rsServicePackages.data.servicePackages.map((x) => {
            return {
              name: x.name,
              value: x._id,
            };
          })
        )
      );
    }
  } catch (error) {
    toast.error(error);
  }
}

function* createVoucher({ payload }) {
  try {
    yield delay(100);
    const { onSuccess, body } = payload;
    const rs = yield VoucherRequest.create(body);
    if (rs.status === "success") {
      onSuccess(rs.data._id);
    } else {
      throw new Error(rs.message || "Có lỗi khi tạo mã voucher");
    }
  } catch (error) {
    console.log({ error });

    let errorMessage = "Có lỗi khi tạo mã voucher";

    //lấy error message
    if (error.response && error.response.data) {
      errorMessage =
        error.response.data.message ||
        error.response.data.error ||
        errorMessage;
    } else if (error.message) {
      errorMessage = error.message;
    }

    toast.error(errorMessage);
  }
}

function* edit({ payload }) {
  try {
    yield delay(100);
    const { id, body, onSuccess } = payload;
    const rs = yield VoucherRequest.edit(id, body);
    if (rs.status === "success") {
      // yield put(VoucherActions.getVoucherById(id));
      onSuccess();
    } else {
      toast.error(rs.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

function* deleteVoucher({ payload }) {
  yield delay(100);
  const { id, onSuccess } = payload;
  yield VoucherRequest.delete(id);
  onSuccess();
  try {
  } catch (error) {
    toast.error(error);
  }
}

function* deleteVoucherCode({ payload }) {
  yield delay(100);
  const { id, onSuccess } = payload;
  yield VoucherRequest.deleteVoucherCode(id);
  onSuccess();
  try {
  } catch (error) {
    toast.error(error);
  }
}

function* setSearch({ payload }) {
  try {
    yield delay(300);
    const pagination = yield select(VoucherSelectors.pagination);
    //search theo services
    const services = yield select(VoucherSelectors.servicesSearch);
    const servicesParam = Array.isArray(services) ? services.join(",") : "";
    const rs = yield VoucherRequest.getVouchers({
      code: payload,
      page: pagination.page,
      pageSize: pagination.pageSize,
      services: servicesParam,
    });
    if (rs.success) {
      yield put(VoucherActions.setVouchers(rs.data.vouchers));
      yield put(VoucherActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    yield put(VoucherActions.setVouchers([]));
    toast.error(error);
  }
}
function* setSearchHistories({ payload }) {
  try {
    yield delay(300);
    const pagination = yield select(VoucherSelectors.pagination);
    //search theo services
    const services = yield select(VoucherSelectors.servicesSearchHistory);
    const servicesParam = Array.isArray(services) ? services.join(",") : "";
    const rs = yield VoucherRequest.getVoucherHistories({
      search: payload,
      page: pagination.page,
      pageSize: pagination.pageSize,
      service: servicesParam,
    });
    if (rs.success) {
      yield put(VoucherActions.setVoucherHistories(rs.data.voucher_histories));
      yield put(VoucherActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    yield put(VoucherActions.setVoucherHistories([]));
    toast.error(error);
  }
}
