import React from "react";

function StatusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.75736 12.2426C1.41421 9.89946 1.41421 6.10047 3.75736 3.75732M12.2426 3.75732C14.5858 6.10047 14.5858 9.89946 12.2426 12.2426M5.64298 10.357C4.34123 9.05524 4.34123 6.94469 5.64298 5.64294M10.357 5.64294C11.6588 6.94469 11.6588 9.05524 10.357 10.357M8.66667 7.99997C8.66667 8.36816 8.36819 8.66663 8 8.66663C7.63181 8.66663 7.33333 8.36816 7.33333 7.99997C7.33333 7.63178 7.63181 7.3333 8 7.3333C8.36819 7.3333 8.66667 7.63178 8.66667 7.99997Z"
        stroke="#23C16B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default StatusIcon;
