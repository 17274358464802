import React, { useEffect, useMemo } from "react";
import "./style.css";

function TabService({ list, contentList, resetTabTrigger, activeTab }) {
  const currentIndex = activeTab !== undefined ? activeTab : 0;

  useEffect(() => {
    // Không cần setCurrentIndex nữa vì chúng ta sử dụng prop activeTab
  }, [resetTabTrigger]);

  const renderContent = useMemo(() => {
    return contentList[currentIndex];
  }, [currentIndex, contentList]);

  return (
    <div>
      <div className="tab-container">
        {list.map((item, index) => {
          return (
            <OneItem
              key={index}
              item={item}
              currentIndex={currentIndex}
              index={index}
            />
          );
        })}
      </div>
      {renderContent}
    </div>
  );
}

export default TabService;

const OneItem = ({ item, currentIndex, index }) => {
  return (
    <div
      onClick={() => {
        if (currentIndex !== index) item.onClick();
      }}
    >
      <div className="tab-one-item">
        <div
          className={
            currentIndex === index
              ? "tab-one-item-label-active"
              : "tab-one-item-label-unactive"
          }
        >
          {item.name}
        </div>
        <div
          className={
            currentIndex === index
              ? "tab-one-item-line-active"
              : "tab-one-item-line-unactive"
          }
        />
      </div>
    </div>
  );
};
