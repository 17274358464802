import React from "react";

function IdIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.6667 4.66667C10.6667 6.13943 9.4728 7.33333 8.00004 7.33333C6.52728 7.33333 5.33337 6.13943 5.33337 4.66667C5.33337 3.19391 6.52728 2 8.00004 2C9.4728 2 10.6667 3.19391 10.6667 4.66667Z"
        stroke="#72777A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00004 9.33333C5.42271 9.33333 3.33337 11.4227 3.33337 14H12.6667C12.6667 11.4227 10.5774 9.33333 8.00004 9.33333Z"
        stroke="#72777A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IdIcon;
