import qs from "qs";
import SysFetch from "../../fetch";

const ContentPromptRequest = {
  createContentPrompt: (newsData) => {
    return SysFetch.post(`content-prompt`, newsData);
  },
  postNewsContentPrompt: (newsData) => {
    return SysFetch.post(`content-prompt/post-news`, newsData);
  },
  getAllContentPrompt: (body) => {
    return SysFetch.get(
      `content-prompt?${qs.stringify(body, { encode: false })}`
    );
  },
  updateContentPrompt: ({ id, input }) => {
    return SysFetch.put(`content-prompt/${id}`, input);
  },
  deleteContentPrompt: (id) => {
    return SysFetch.delete(`content-prompt/${id}`);
  },
  getContentPromptById: (id) => {
    return SysFetch.get(`content-prompt/${id}`);
  },
};

export default ContentPromptRequest;
