import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import Modal from "../../components/base/modal/Modal";
import MST from "../../components";

const VIP_LEVELS = [0, 1, 2, 3];

const VipLevel = ({ isOpen, onClose, vipPrices, setVipPrices, basePrice }) => {
  const [priceIncreases, setPriceIncreases] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
  });
  const [calculatedPrices, setCalculatedPrices] = useState({
    0: basePrice,
    1: basePrice,
    2: basePrice,
    3: basePrice,
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      const initialPrices = VIP_LEVELS.reduce((acc, level) => {
        const vipPrice = vipPrices.find((vp) => vp.level === level);
        acc[level] = vipPrice ? vipPrice.increasePercentage : 0;
        return acc;
      }, {});
      setPriceIncreases(initialPrices);
      updateCalculatedPrices(initialPrices);
    } else {
      setError("");
    }
  }, [isOpen, vipPrices, basePrice]);

  const updateCalculatedPrices = (increases) => {
    const newCalculatedPrices = VIP_LEVELS.reduce((acc, level) => {
      const increase = parseFloat(increases[level]) || 0;
      acc[level] = basePrice * (1 + increase / 100);
      return acc;
    }, {});
    setCalculatedPrices(newCalculatedPrices);
  };

  const validatePriceIncrease = (increase, level) => {
    if (increase === "") return true;

    const increaseValue = parseFloat(increase);
    if (isNaN(increaseValue) || increaseValue < 0) {
      setError(`Cấp VIP ${level}: Giá trị tăng không được âm`);
      return false;
    }

    setError("");
    return true;
  };

  const handlePriceIncreaseChange = (e, level) => {
    const newValue = e.target.value;
    if (validatePriceIncrease(newValue, level)) {
      const newPriceIncreases = { ...priceIncreases, [level]: newValue };
      setPriceIncreases(newPriceIncreases);
      updateCalculatedPrices(newPriceIncreases);
    }
  };

  const handleSave = () => {
    const updatedVipPrices = VIP_LEVELS.map((level) => ({
      level,
      increasePercentage: parseFloat(priceIncreases[level]) || 0,
    }));
    setVipPrices(updatedVipPrices);
    onClose();
  };

  const renderContent = (
    <div className="bg-white p-2 w-[424px] max-w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">
          Cấu hình giá khách hàng VIP
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors"
        >
          <Close className="h-6 w-6" />
        </button>
      </div>

      <div className="bg-orange-100 rounded-lg p-4 mb-6">
        <p className="text-orange-800 font-semibold text-center">
          Giá gốc: {basePrice?.toLocaleString()} VND
        </p>
      </div>

      <div className="space-y-2">
        {VIP_LEVELS.map((level) => (
          <div
            key={level}
            className="grid grid-cols-3 items-center bg-gray-50 px-4 py-2 rounded-lg"
          >
            <span className="text-lg font-semibold text-gray-700">
              VIP {level}
            </span>
            <div className="relative max-w-[100px]">
              <MST.Input
                type="number"
                value={priceIncreases[level]}
                onChange={(e) => handlePriceIncreaseChange(e, level)}
                min={0}
                step={0.1}
              />
              <span className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-500">
                %
              </span>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-500">Giá sau thay đổi</p>
              <p className="font-semibold text-green-600">
                {calculatedPrices[level]?.toLocaleString()} VND
              </p>
            </div>
          </div>
        ))}
      </div>

      {error && (
        <p className="mt-4 text-red-500 text-center text-sm">{error}</p>
      )}

      <div className="mt-6 flex justify-center">
        <MST.Button onClick={handleSave} disabled={!!error}>
          Lưu thay đổi
        </MST.Button>
      </div>
    </div>
  );

  return <Modal isShow={isOpen} onHide={onClose} content={renderContent} />;
};

export default VipLevel;
