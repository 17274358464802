import React from 'react';

const PartiallyCanceledIcon = ({ size = 24, color = 'currentColor', secondaryColor = '#F3F4F6' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {/* Main circle */}
      <circle cx="12" cy="12" r="10" fill={secondaryColor} />
      
      {/* Pie slice representing partial cancellation */}
      <path
        d="M12 2 A10 10 0 0 1 22 12 L12 12 Z"
        fill={color}
      />
      
      {/* Slash for cancellation */}
      <line x1="18" y1="6" x2="6" y2="18" stroke={color} strokeWidth="2" />
      
      {/* Order lines */}
      <line x1="8" y1="8" x2="16" y2="8" stroke={color} strokeWidth="1.5" />
      <line x1="8" y1="12" x2="16" y2="12" stroke={color} strokeWidth="1.5" />
      <line x1="8" y1="16" x2="12" y2="16" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default PartiallyCanceledIcon;