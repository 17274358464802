import { Box, Modal } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  PromotionActions,
  PromotionSelectors,
} from "../../app/services/promotions/promotion.slice";
import MST from "../../components";
import Pagination from "../../components/base/pagination/Pagination";
import PromotionOptions from "./PromotionOption";
import PromotionSearch from "./PromotionSearch";
import PromotionSwitch from "./PromotionSwitch";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: 320,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 8,
};
function PromotionList() {
  const apiOrigin = process.env.REACT_APP_SC_BACKEND_API ? new URL(process.env.REACT_APP_SC_BACKEND_API).origin : '';
  const [modalStatus, handleModal] = useState({
    open: false,
    promotion: {
      _id: "",
      title: "",
      content: "",
      path: "",
      image: "",
      createdAt: "",
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const promotions = useSelector(PromotionSelectors.promotionList);
  const pagination = useSelector(PromotionSelectors.pagination);
  const thead = [
    {
      name: "Title",
      style: { width: 20 },
      className: "",
    },
    {
      name: "Content",
    },
    {
      name: "Path",
      style: {
        width: 50,
      },
    },

    {
      name: "Ảnh",
      style: {
        width: 110,
      },
    },
    {
      name: "Ẩn Promotion",
      style: {
        width: 70,
      },
    },
    {
      name: "Ngày tạo",
    },
    {
      name: "Thao tác",
      style: {
        width: 100,
      },
    },
  ];
  useEffect(() => {
    dispatch(PromotionActions.getPromotions());
  }, [pagination.page, dispatch, navigate]);

  useEffect(() => {
    dispatch(PromotionActions.getPromotions());
    return () => {
      dispatch(PromotionActions.resetSession());
    };
  }, [dispatch, navigate]);
  const genRenderList = useCallback(() => {
    return promotions.map((x, index) => {
      return [
        { value: x.title, style: { textAlign: "center" } },
        { value: x.content, style: { textAlign: "center" } },
        { value: x.path, style: { textAlign: "center" } },
        {
          value: (() => {
            try {
              return (
                <img className="w-16 h-16 object-cover m-auto" src={`${apiOrigin}/${x.image}`} />
              );
            } catch (error) {
              return [];
            }
          })(),
          style: { margin: "auto" },
        },
        {
          value: (() => {
            try {
              return <PromotionSwitch id={x._id} isHidden={x.is_hidden} />;
            } catch (error) {
              return [];
            }
          })(),
          style: { margin: "auto" },
        },
        {
          value: moment(x.createdAt).format("DD/MM/YYYY"),
          style: { width: 100 },
        },
        {
          value: <PromotionOptions id={x._id} handleModal={handleModal} />,
        },
      ];
    });
  }, [promotions, navigate, dispatch]);
  const onChangePage = (page) => {
    dispatch(PromotionActions.setPagination({ ...pagination, page }));
  };
  return (
    <>
      <div className="p-4">
        <div className="mb-2">
          <PromotionSearch />
        </div>
        <MST.Table head={thead} body={genRenderList()} />
        <div className="voucher-pagination">
          <Pagination
            onChange={onChangePage}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalPage={pagination.totalPage}
            total={pagination.total}
          />
        </div>
      </div>
      <Modal
        open={modalStatus.open}
        onClose={() => handleModal({ open: false, promotion: {} })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="relative w-full h-full overflow-hidden rounded-lg">
            <img
              src={`${apiOrigin}/${modalStatus?.promotion?.image}`}
              alt={modalStatus?.promotion?.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 text-white p-4">
              <h2 className="text-2xl font-bold">
                {modalStatus?.promotion?.title}
              </h2>
              <p>{modalStatus?.promotion?.content}</p>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default PromotionList;
