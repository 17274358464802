import React from "react";

const SVGEmoji = (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
          fill="#0F0F0F"
        ></path>{" "}
        <path
          d="M16.4472 13.1056C16.9412 13.3526 17.1414 13.9532 16.8944 14.4472L16 14C16.8944 14.4472 16.8946 14.4468 16.8944 14.4472L16.8928 14.4504L16.8908 14.4543L16.8857 14.4644L16.8706 14.493C16.8585 14.5158 16.8421 14.5458 16.8214 14.5821C16.78 14.6544 16.7212 14.7523 16.6445 14.8672C16.4924 15.0954 16.2643 15.3999 15.9571 15.7071C15.3481 16.3161 14.3588 17 13 17C11.8272 17 10.9251 16.7056 10.3028 16.3944C9.99328 16.2397 9.75496 16.0818 9.5875 15.9563C9.5037 15.8934 9.43735 15.8384 9.38837 15.7955C9.36387 15.7741 9.34367 15.7557 9.32778 15.7408L9.30713 15.7211L9.29922 15.7134L9.29585 15.7101L9.29432 15.7085L9.2936 15.7078C9.29324 15.7075 9.2929 15.7071 9.99207 15.0079L9.2936 15.7078C8.90307 15.3173 8.90237 14.6834 9.29289 14.2929C9.68007 13.9057 10.3057 13.9024 10.697 14.2829L10.7054 14.2904C10.7189 14.3022 10.7463 14.3253 10.7875 14.3562C10.87 14.4182 11.0067 14.5103 11.1972 14.6056C11.5749 14.7944 12.1728 15 13 15C13.6412 15 14.1519 14.6839 14.5429 14.2929C14.7357 14.1001 14.8825 13.9046 14.9804 13.7578C15.0288 13.6852 15.0637 13.6268 15.0849 13.5898C15.0954 13.5713 15.1024 13.5584 15.1059 13.5519L15.1081 13.5478C15.3566 13.0575 15.9549 12.8594 16.4472 13.1056Z"
          fill="#0F0F0F"
        ></path>{" "}
        <path
          d="M14 9C13.4477 9 13 9.44771 13 10C13 10.5523 13.4477 11 14 11H16C16.5523 11 17 10.5523 17 10C17 9.44771 16.5523 9 16 9H14Z"
          fill="#0F0F0F"
        ></path>{" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 20.9932C7.03321 20.9932 3.00683 16.9668 3.00683 12C3.00683 7.03321 7.03321 3.00683 12 3.00683C16.9668 3.00683 20.9932 7.03321 20.9932 12C20.9932 16.9668 16.9668 20.9932 12 20.9932Z"
          fill="#0F0F0F"
        ></path>{" "}
      </g>
    </svg>
  );
};

export default SVGEmoji;
