import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vipPrices: [],
  isLoading: false,
  error: null,
};

const VipLevelSlice = createSlice({
  name: "vipLevel",
  initialState,
  reducers: {
    getVipPrices: (state) => {
      state.isLoading = true;
    },
    getVipPricesSuccess: (state, { payload }) => {
      state.vipPrices = payload;
      state.isLoading = false;
      state.error = null;
    },
    getVipPricesFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    updateVipPrices: (state) => {
      state.isLoading = true;
    },
    updateVipPricesSuccess: (state, { payload }) => {
      state.vipPrices = payload;
      state.isLoading = false;
      state.error = null;
    },
    updateVipPricesFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    deleteVipPrices: (state) => {
      state.isLoading = true;
    },
    deleteVipPricesSuccess: (state) => {
      state.vipPrices = [];
      state.isLoading = false;
      state.error = null;
    },
    deleteVipPricesFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const VipLevelActions = VipLevelSlice.actions;
export const VipLevelSelectors = {
  vipPrices: (state) => state.vipLevel.vipPrices,
  isLoading: (state) => state.vipLevel.isLoading,
  error: (state) => state.vipLevel.error,
};

const VipLevelReducer = VipLevelSlice.reducer;
export default VipLevelReducer;
