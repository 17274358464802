import React from "react";
import MST from "../../components";
import SearchIcon from "../../images/icons/SearchIcon";
import { useDispatch, useSelector } from "react-redux";

import {
  ApiKeyActions,
  ApiKeySelectors,
} from "../../app/services/api-key/api-key.slice";

const ApiKeySearch = () => {
  const dispatch = useDispatch();
  const keySearch = useSelector(ApiKeySelectors.keySearch);

  return (
    <div className="search-service-container">
      <MST.Input
        value={keySearch}
        onChange={(e) => {
          dispatch(ApiKeyActions.setKeySearch(e.target.value));
        }}
        placeholder={"Nhập key hoặc phân loại"}
        right={
          <div className="service-search-btn">
            <SearchIcon />
          </div>
        }
      />
    </div>
  );
};

export default ApiKeySearch;
