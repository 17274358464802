import { useDispatch } from "react-redux";
import VoucherCreatePage from "../voucher-create/VoucherCreatePage";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { VoucherActions } from "../../app/services/voucher/voucher.slice";

function VoucherEditPage() {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getDetailVoucher();
    }
    return () => {
      dispatch(VoucherActions.setVoucherDetail(undefined));
    };
  }, [params]);

  const getDetailVoucher = () => {
    dispatch(VoucherActions.getVoucherById({ id: params.id }));
  };
  return <VoucherCreatePage />;
}

export default VoucherEditPage;
