import SysFetch from "../../../app/fetch";

const createCKEditorUploadAdapter = (loader) => {
  return {
    upload: async () => {
      try {
        const file = await loader.file;
        const formData = new FormData();
        formData.append("file", file);

        const response = await SysFetch.post(
          "uploads/image/cloudinary",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.success) {
          return {
            default: response.data.url,
          };
        } else {
          throw new Error(response.message || "Upload failed");
        }
      } catch (error) {
        console.error("Upload error:", error);
        throw error;
      }
    },
    abort: () => {
      // Xử lý hủy upload nếu cần
    },
  };
};

export { createCKEditorUploadAdapter };
