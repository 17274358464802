import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../components/base/pagination/Pagination";
import Table from "../../components/base/table/Table";

import "./style.css";
import { NewsActions, NewsSelectors } from "../../app/services/news/news.slice";
import NewsOptions from "./NewsOptions";
import NewsSearch from "./NewsSearch";

function NewsList() {
  const dispatch = useDispatch();

  const newsList = useSelector(NewsSelectors.newsList);
  const pagination = useSelector(NewsSelectors.pagination);
  const newsCategory = useSelector(NewsSelectors.newsCategory);
  const keySearch = useSelector(NewsSelectors.keySearch);

  useEffect(() => {
    dispatch(NewsActions.getAllNews());
  }, [dispatch, newsCategory, pagination.page, keySearch]);

  const thead = [
    {
      name: "STT",
      style: { width: 20 },
      className: "",
    },
    {
      name: "ẢNH",
      style: { width: 300, height: 50 },
    },
    {
      style: {
        textAlign: "left",
      },
      name: "Tiêu đề",
    },

    {
      name: "Tác giả",
      style: { width: 120 },
    },

    {
      name: "Danh mục",
      style: { width: 120 },
    },

    {
      name: "trạng thái",
      style: { width: 120 },
    },
    {
      name: "thao tác",
      style: { width: 100 },
    },
  ];

  const genDataTable = () => {
    try {
      return newsList.map((x, index) => {
        return [
          { value: (pagination.page - 1) * pagination.pageSize + (index + 1) },
          {
            value: (() => {
              try {
                return <img className="" src={`${x?.coverImage?.url}`} />;
              } catch (error) {
                return [];
              }
            })(),
          },
          {
            style: {
              textAlign: "left",
            },
            value: x.title,
          },
          {
            value: `${x?.author?.username}`,
          },
          {
            value: `${x?.category?.name}`,
          },
          {
            value: x?.isPublished ? "Đăng tải" : "Lưu trữ",
          },

          {
            value: <NewsOptions id={x._id} />,
          },
        ];
      });
    } catch (error) {
      return [];
    }
  };

  return (
    <div className="news-list">
      <NewsSearch />
      <Table head={thead} body={genDataTable()} />
      <div className="service-pagination">
        <Pagination
          {...pagination}
          onChange={(page) => {
            dispatch(
              NewsActions.setPagination({
                ...pagination,
                page,
              })
            );
          }}
        />
      </div>
    </div>
  );
}

export default NewsList;

export const sGenColor = (content) => {
  switch (content) {
    case "active":
      return "#23C16B";
    case "unactive":
      return "#72777A";
  }
};
