import React from "react";
import MST from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerActions,
  CustomerSelectors,
} from "../../app/services/customer/customer.slice";

const CustomerBalanceSort = () => {
  const sortBalance = useSelector(CustomerSelectors.sortBalance);
  const dispatch = useDispatch();

  return (
    <div className="flex items-center space-x-2">
      <span className="text-base font-medium text-gray-700">
        Sắp xếp số dư:
      </span>
      <MST.Button
        onClick={() =>
          dispatch(
            CustomerActions.setSortBalance(
              sortBalance === "asc" ? "desc" : "asc"
            )
          )
        }
      >
        {sortBalance === "asc" ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-4"
            >
              <path
                fillRule="evenodd"
                d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-sm">Tăng dần</span>
          </>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-4"
            >
              <path
                fillRule="evenodd"
                d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                clipRule="evenodd"
              />
            </svg>

            <span className="text-sm">Giảm dần</span>
          </>
        )}
      </MST.Button>
    </div>
  );
};

export default CustomerBalanceSort;
