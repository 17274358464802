import React from "react";

function VoucherIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 8V7C21 6.44772 20.5523 6 20 6H4C3.44772 6 3 6.44772 3 7V8C4.10457 8 5 8.89543 5 10C5 11.1046 4.10457 12 3 12V13C3 13.5523 3.44772 14 4 14H20C20.5523 14 21 13.5523 21 13V12C19.8954 12 19 11.1046 19 10C19 8.89543 19.8954 8 21 8Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6V18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 6V18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default VoucherIcon;
