import React, { useEffect, useState } from "react";
import Modal from "../../components/base/modal/Modal";
import MST from "../../components";
import { useDispatch } from "react-redux";
import { NewsActions } from "../../app/services/news/news.slice";

function NewsCategoryModal({ categorySelected, setCategorySelected }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [isShow, setIsShow] = useState(false);

  const onHide = () => setIsShow(false);
  const onShow = () => setIsShow(true);

  useEffect(() => {
    return () => {
      setName("");
    };
  }, [isShow]);

  const onCreate = () => {
    dispatch(
      NewsActions.createNewsCategory({
        name,
        onSuccess: (rs) => {
          setCategorySelected({
            value: rs?._id,
            name: rs?.name,
          });
          // dispatch(NewsActions.getCreateInfo());
          dispatch(NewsActions.getNewsCategories());

          onHide();
        },
      })
    );
  };

  const renderContent = (
    <div>
      <div className="modal-header">Thêm danh mục</div>
      <div className="modal-body">
        <div className="mb-8">Tên danh mục</div>
        <MST.Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nhập tên danh mục"
        />
      </div>
      <div className="modal-footer">
        <div className="d-flex jc-between">
          <div />
          <MST.Button onClick={onCreate}>Xác nhận</MST.Button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <MST.Button onClick={onShow}>Thêm danh mục</MST.Button>
      <Modal isShow={isShow} onHide={onHide} content={renderContent} />
    </div>
  );
}

export default NewsCategoryModal;
