import React from "react";
import { ChatProvider } from "./ChatProvider";
import ChatBox from "./ChatBox";
import ChatList from "./ChatList";

const Chat = () => {
  return (
    <ChatProvider>
      <ChatList />
      <ChatBox />
    </ChatProvider>
  );
};

export default Chat;
