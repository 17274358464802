import { toast } from "react-toastify";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import NewsAccountRequest from "./news-account.request";
import { NewsAccountActions, NewsAccountSelectors } from "./news-account.slice";

function* NewsAccountSaga() {
  yield takeLatest(NewsAccountActions.getNewsAccountList, getNewsAccountList);
  yield takeLatest(NewsAccountActions.getNewsAccountById, getNewsAccountById);
  yield takeLatest(NewsAccountActions.delete, deleteNewsAccount);
  yield takeLatest(NewsAccountActions.create, create);
  yield takeLatest(NewsAccountActions.update, update);
}

export default NewsAccountSaga;

function* update({ payload }) {
  try {
    yield delay(100);
    const { onSuccess, body, id } = payload;
    const rs = yield NewsAccountRequest.update(id, body);
    if (rs.success) {
      onSuccess();
    } else {
      throw rs.message;
    }
  } catch (error) {
    toast.error(error.toString());
  }
}

function* deleteNewsAccount({ payload }) {
  try {
    yield delay(100);
    const { id, onSuccess } = payload;
    const rs = yield NewsAccountRequest.delete(id);
    if (rs.success) {
      onSuccess();
    } else {
      throw rs.message;
    }
  } catch (error) {
    toast.error(error);
  }
}

function* create({ payload }) {
  try {
    yield delay(100);
    const { onSuccess, body } = payload;
    const rs = yield NewsAccountRequest.create(body);
    if (rs.success) {
      onSuccess(rs.data._id);
    } else {
      throw rs.message;
    }
  } catch (error) {
    toast.error(error.toString());
  }
}

function* getNewsAccountList({ payload }) {
  try {
    yield delay(100);
    const pagination = yield select(NewsAccountSelectors.pagination);

    const rs = yield NewsAccountRequest.gets({
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
    console.log("🚀 ~ rs:", rs)

    if (rs.success) {
      yield put(NewsAccountActions.setNewsAccountList(rs.data.newsRoles));
      yield put(NewsAccountActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getNewsAccountById({ payload }) {
  try {
    yield delay(100);
    const rs = yield NewsAccountRequest.get(payload.id);

    if (rs.success) {
      yield put(NewsAccountActions.setNewsAccountById(rs.data.newsRole));
    }
  } catch (error) {
    toast.error(error);
  }
}