import { call, put, select, takeLatest } from "redux-saga/effects";
import { getVisits, getVisitsSuccess, getVisitsFailure } from "./visit.slice";
import VisitRequest from "./visit.request";
import { format } from "date-fns";

function* fetchVisitsSaga() {
  try {
    const state = yield select((state) => state.visit);
    const { dateRange, compareDate, compareWithDate } = state;

    const params = {};
    if (dateRange.startDate) {
      params.startDate = format(new Date(dateRange.startDate), "yyyy-MM-dd");
    }
    if (dateRange.endDate) {
      params.endDate = format(new Date(dateRange.endDate), "yyyy-MM-dd");
    }
    if (compareDate) {
      params.compareDate = format(new Date(compareDate), "yyyy-MM-dd");
    }
    if (compareWithDate) {
      params.compareWithDate = format(new Date(compareWithDate), "yyyy-MM-dd");
    }

    const response = yield call(VisitRequest.getVisits, params);
    yield put(getVisitsSuccess(response));
  } catch (error) {
    yield put(getVisitsFailure());
    console.error("Error fetching visits:", error);
  }
}

export default function* visitSaga() {
  yield takeLatest(getVisits.type, fetchVisitsSaga);
}
