import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newsList: [],
  newsCategories: [],
  newsCategory: "",
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  keySearch: "",
  newsDetail: undefined,
  newsTags: [],
  loading: false,
  error: null,
};

const NewsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    createNews: (state) => {
      state.loading = true;
      state.error = null;
    },

    getAllNews: (state, { payload }) => {
      state.loading = true;
      state.error = null;
    },
    getAllNewsSuccess: (state, { payload }) => {
      state.loading = false;
      state.newsList = payload;
    },
    getAllNewsFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    updateNews: (state) => {
      state.loading = false;
      state.error = null;
    },
    updateNewsFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getNewsById: (state) => {
      state.loading = true;
      state.error = null;
    },
    getNewByIdSuccess: (state, { payload }) => {
      state.newsDetail = payload;
      state.loading = false;
    },
    getNewByIdFailure: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    deleteNews: (state, { payload }) => {},
    createNewsCategory: (state, { payload }) => {},

    getNewsCategories: (state, { payload }) => {},
    getNewsCategoriesSuccess: (state, { payload }) => {
      state.newsCategories = payload;
    },
    deleteNewsCategory: (state, { payload }) => {},
    setNewsCategory: (state, { payload }) => {
      state.newsCategory = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    setKeySearch: (state, { payload }) => {
      state.keySearch = payload;
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
    },
    // Thêm các action khác nếu cần
  },
});

const NewsReducer = NewsSlice.reducer;
export default NewsReducer;

export const NewsActions = NewsSlice.actions;
export const NewsSelectors = {
  newsList: (state) => state.news.newsList,
  loading: (state) => state.news.loading,
  error: (state) => state.news.error,
  newsCategories: (state) => state.news.newsCategories,
  newsCategory: (state) => state.news.newsCategory,
  newsDetail: (state) => state.news.newsDetail,
  pagination: (state) => state.news.pagination,
  keySearch: (state) => state.news.keySearch,
};
