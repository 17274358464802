import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channelId: null,
  channelInfo: null,
  loading: false,
  error: null,
};

const CheckLinkSlice = createSlice({
  name: "checklink",
  initialState,
  reducers: {
    checkLinkYoutube: (state) => {
      state.loading = true;
      state.error = null;
    },
    setChannelId: (state, { payload }) => {
      state.channelId = payload;
    },
    setChannelInfo: (state, { payload }) => {
      state.channelInfo = payload;
      state.loading = false;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    resetState: () => initialState,
  },
});

const CheckLinkReducer = CheckLinkSlice.reducer;
export default CheckLinkReducer;

export const CheckLinkActions = CheckLinkSlice.actions;

export const CheckLinkSelectors = {
  channelId: (state) => state.checklink.channelId,
  channelInfo: (state) => state.checklink.channelInfo,
  loading: (state) => state.checklink.loading,
  error: (state) => state.checklink.error,
};
