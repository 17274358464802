// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topic-create-content {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 20px;
}
.topic-create-title {
  font-size: 18px;
}
.topic-create-one-field {
  margin-top: 20px;
}
.topic-create-one-field-name {
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/topic-edit/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".topic-create-content {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n  margin-bottom: 20px;\n}\n.topic-create-title {\n  font-size: 18px;\n}\n.topic-create-one-field {\n  margin-top: 20px;\n}\n.topic-create-one-field-name {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
