import SysFetch from "../../fetch";
import qs from "qs";
const PromotionRequest = {
  getPromotion: (body) => {
    return SysFetch.get(`/promotion?${qs.stringify(body, { encode: false })}`);
  },

  getPromotionById: (id) => SysFetch.get(`/promotion/${id}`),

  createPromotion: (body) => {
    return SysFetch.post(`/promotion`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  updatePromotion: (id, body) => {
    return SysFetch.put(`/promotion/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  deletePromotion: (id) => {
    return SysFetch.delete(`/promotion/${id}`);
  },
};

export default PromotionRequest;
