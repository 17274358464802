import axios from "axios";
import SysFetch from "../../fetch";

const CheckLinkRequest = {
  checkLinkYoutube: (link) => {
    return SysFetch.post(`check/youtube`, { url: link });
  },
  getChannelInfo: (channelId) => {
    return axios.get(`https://www.googleapis.com/youtube/v3/channels`, {
      params: {
        part: "snippet,statistics",
        id: channelId,
        key: "AIzaSyBLnmhk1tDoY-eYTozw9IPBIoxV8pOkTm8",
      },
    });
  },
};

export default CheckLinkRequest;
