import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NewsAccountActions, NewsAccountSelectors } from "../../app/services/news-account/news-account.slice";
import MST from "../../components";
import Pagination from "../../components/base/pagination/Pagination";
import NewsAccountOptions from "./NewsAccount.Options";

function NewsAccountList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newsAccountList = useSelector(NewsAccountSelectors?.list);
  const pagination = useSelector(NewsAccountSelectors.pagination);

  useEffect(() => {
    dispatch(NewsAccountActions.getNewsAccountList());
  }, [pagination.page, navigate, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(NewsAccountActions.resetSession());
    };
  }, [navigate, dispatch]);

  const thead = [
    {
      name: "STT",
      style: { width: 20 },
      className: "",
    },
    {
      name: "Username",
      style: {
        textAlign: "left",
      },
    },
    {
      name: "Thao tác",
      style: { width: 100 },
    },
  ];

  const genRenderList = useCallback(() => {
    return (newsAccountList || []).map((x, index) => {
      return [
        { value: (pagination.page - 1) * pagination.pageSize + (index + 1) },
        {
          value: x?.username,
          style: {
            textAlign: "left",
          },
        },
        {
          value: <NewsAccountOptions id={x._id} />,
        },
      ];
    });
  }, [newsAccountList, pagination]);

  const onChangePage = (page) => {
    dispatch(
      NewsAccountActions.setPagination({
        ...pagination,
        page,
      })
    );
  };

  return (
    <div>
      <MST.Table head={thead} body={genRenderList()} />
      <div className="order-pagination">
        <Pagination
          onChange={onChangePage}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalPage={pagination.totalPage}
          total={pagination.total}
        />
      </div>
    </div>
  );
}

export default NewsAccountList;