import qs from "qs";
import SysFetch from "../../fetch";

const NewsRequest = {
  createNews: (newsData) => {
    return SysFetch.post(`news`, newsData);
  },
  getAllNews: (body) => {
    return SysFetch.get(`news?${qs.stringify(body, { encode: false })}`);
  },
  updateNews: ({ id, newsData }) => {
    return SysFetch.put(`news/${id}`, newsData);
  },
  deleteNews: (id) => {
    return SysFetch.delete(`news/${id}`);
  },
  getNewsById: (id) => {
    return SysFetch.get(`news/${id}`);
  },
  getNewsCategories: () => {
    return SysFetch.get("news-category");
  },
  createNewsCategory: (name) => {
    return SysFetch.post("news-category", {
      name,
    });
  },
  deleteNewsCategory: (id) => {
    return SysFetch.delete(`news-category/${id}`);
  },
};

export default NewsRequest;
