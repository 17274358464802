import React, { useMemo } from "react";
import Modal from "../../components/base/modal/Modal";
import DashBoardLeftTotalCustomer from "../../components/dashboard/DashBoardLeft.Total.Customer";
import InboxArrowDown from "../../components/dashboard/icon/InboxArrowDown";
import SVGInboxIcon from "../../components/dashboard/icon/inboxIcon";
import InboxStackIcon from "../../components/dashboard/icon/inboxStackIcon";
import SVGWaitingIcon from "../../components/dashboard/icon/inboxWaitingIcon";
import PartiallyCanceledIcon from "../../components/dashboard/icon/partiallyCancelledIcon";
import ProcessingIcon from "../../components/dashboard/icon/processingIcon";
import TrashIcon from "../../components/dashboard/icon/trashIcon";

function DashBoardLeftModal({ item, setIsShow, isShow }) {
  const onHide = () => {
    setIsShow(false);
  };

  const contentModal = useMemo(() => {
    return (
      <>
        <div className="dash-board-left-modal">
          <div className="dash-board-left-modal-title">
            <div>
              <SVGInboxIcon width={14} height={14} />
            </div>
            <div>Chi tiết số lượng đơn hàng theo trạng thái</div>
          </div>
          <div
            onClick={(e) => {
              setIsShow(false);
            }}
            className="dash-board-left-modal-icon"
          >
            X
          </div>
        </div>
        <div className="dash-board-left-modal-popup">
          <div>
            <DashBoardLeftTotalCustomer
              item={item?.completed}
              title={"Tổng đơn hoàn thành"}
              icon={<InboxStackIcon width={20} height={20} color="#FFFFFF" />}
              classList="dash-board-left-modal-popup-childe"
              classListTitle="dash-board-left-modal-popup-title"
            />
          </div>
          <div>
            <DashBoardLeftTotalCustomer
              item={item?.running}
              title={"Tổng đơn đang chạy"}
              icon={<InboxArrowDown width={20} height={20} color="#FFFFFF" />}
              classList="dash-board-left-modal-popup-childe"
              classListTitle="dash-board-left-modal-popup-title"
            />
          </div>
        </div>
        <div className="dash-board-left-modal-popup">
          <div>
            <DashBoardLeftTotalCustomer
              item={item?.pending}
              title={"Tổng đơn đang chờ"}
              icon={<SVGWaitingIcon width={30} height={30} color="#FF8900" />}
              classList="dash-board-left-modal-popup-childe"
              classListTitle="dash-board-left-modal-popup-title"
            />
          </div>
          <div>
            <DashBoardLeftTotalCustomer
              item={item?.processing}
              title={"Tổng đơn đang xử lý"}
              icon={<ProcessingIcon size={32} color="#007bff" />}
              classList="dash-board-left-modal-popup-childe"
              classListTitle="dash-board-left-modal-popup-title"
            />
          </div>
        </div>
        <div className="dash-board-left-modal-popup">
          <div>
            <DashBoardLeftTotalCustomer
              item={item?.partially_cancelled}
              title={"Tổng đơn đã hủy 1 phần"}
              icon={<PartiallyCanceledIcon size={32} color="#FF6347" secondaryColor="#FFE4E1"/>}
              classList="dash-board-left-modal-popup-childe"
              classListTitle="dash-board-left-modal-popup-title"
            />
          </div>
          <div>
            <DashBoardLeftTotalCustomer
              item={item?.cancelled}
              title={"Tổng đơn đã hủy"}
              icon={<TrashIcon width={20} height={20} color="#FFFFFF" />}
              classList="dash-board-left-modal-popup-childe"
              classListTitle="dash-board-left-modal-popup-title"
            />
          </div>
        </div>
      </>
    );
  }, [item]);

  return (
    <div className="d-flex jc-center">
      <Modal
        content={contentModal}
        isShow={isShow}
        onHide={onHide}
        classlist="modal-overlay-edit"
        classlistModalContent="modal-content-edit"
      />
    </div>
  );
}

export default DashBoardLeftModal;
