// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-detail-info-container {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
}
.customer-detail-label {
  color: var(--Dark-100, #72777a);
  line-height: 20px; /* 125% */
  margin-inline: 8px;
}
.customer-detail-block {
  color: var(--Dark-100, #ff5247);
  line-height: 20px; /* 125% */
  margin-inline: 8px;
}
.customer-detail-balance {
  color: var(--Primary-500, #ff8900);

  line-height: 24px; /* 150% */
}
.customer-detail-btn-container {
  position: relative;
}
.customer-detail-btn-area {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
}
.customer-detail-title {
  font-size: 18px;
  margin-bottom: 20px;
}

.customer-detail-modal-one-field {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/customer-detail/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,+BAA+B;EAC/B,iBAAiB,EAAE,SAAS;EAC5B,kBAAkB;AACpB;AACA;EACE,+BAA+B;EAC/B,iBAAiB,EAAE,SAAS;EAC5B,kBAAkB;AACpB;AACA;EACE,kCAAkC;;EAElC,iBAAiB,EAAE,SAAS;AAC9B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,aAAa;AACf;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".customer-detail-info-container {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n}\n.customer-detail-label {\n  color: var(--Dark-100, #72777a);\n  line-height: 20px; /* 125% */\n  margin-inline: 8px;\n}\n.customer-detail-block {\n  color: var(--Dark-100, #ff5247);\n  line-height: 20px; /* 125% */\n  margin-inline: 8px;\n}\n.customer-detail-balance {\n  color: var(--Primary-500, #ff8900);\n\n  line-height: 24px; /* 150% */\n}\n.customer-detail-btn-container {\n  position: relative;\n}\n.customer-detail-btn-area {\n  position: absolute;\n  right: 0px;\n  bottom: 0px;\n  display: flex;\n}\n.customer-detail-title {\n  font-size: 18px;\n  margin-bottom: 20px;\n}\n\n.customer-detail-modal-one-field {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
