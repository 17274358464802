import { useDispatch, useSelector } from "react-redux";
import MST from "../../components";
import Pagination from "../../components/base/pagination/Pagination";
import {
  VoucherActions,
  VoucherSelectors,
} from "../../app/services/voucher/voucher.slice";
import { useEffect, useCallback } from "react";
import moment from "moment/moment";
import VoucherHistorySearch from "./VoucherHistorySearch";

function VoucherHistoryList() {
  const dispatch = useDispatch();
  const voucherHistoryList = useSelector(VoucherSelectors.voucherHistoryList);
  const pagination = useSelector(VoucherSelectors.pagination);

  useEffect(() => {
    dispatch(VoucherActions.getVoucherHistories());
  }, [pagination.page, dispatch]);

  const thead = [
    {
      name: "STT",
      style: { width: 20 },
      className: "",
    },
    {
      name: "Code",
      style: { width: 120 },
    },
    {
      name: "Khách hàng",
      style: {
        width: 150,
      },
    },

    {
      name: "Thời gian sử dụng",
      style: {
        width: 100,
      },
    },
    {
      name: "Dịch vụ sử dụng",
      style: {
        width: 100,
      },
    },
  ];

  const genRenderList = useCallback(() => {
    return voucherHistoryList.map((x, index) => {
      return [
        { value: (pagination.page - 1) * pagination.pageSize + (index + 1) },
        { value: x.voucher.code, style: { textAlign: "center" } },
        { value: x.user.email, style: { width: 150 } },
        {
          value: moment(x.createdAt).format("DD/MM/YYYY"),
          style: { width: 100 },
        },
        {
          value: x.service.name,
          style: { width: 280 },
        },
      ];
    });
  }, [voucherHistoryList, dispatch]);

  return (
    <div>
      <div className="mb-2"><VoucherHistorySearch /></div>
      <MST.Table head={thead} body={genRenderList()} />
      <div className="voucher-pagination">
        <Pagination
          {...pagination}
          onChange={(page) => {
            dispatch(
              VoucherActions.setPagination({
                ...pagination,
                page,
              })
            );
          }}
        />
      </div>
    </div>
  );
}

export default VoucherHistoryList;

export const sGenColor = (content) => {
  switch (content) {
    case "expired":
      return "red";
    case "active":
      return "#23C16B";
    case "unactive":
      return "#72777A";
  }
};
