import { all, call } from "redux-saga/effects";
import CustomerSaga from "./customer/customer.saga";
import LoginSaga from "./login/login.saga";
import OrderSaga from "./order/order.saga";
import ServiceSaga from "./service/service.saga";
import SettingSaga from "./setting/setting.saga";
import NotificationSaga from "./notification/notification.saga";
import TopicSaga from "./topic/topic.saga";
import KeywordSaga from "./keyword/keyword.saga";
import PartnerSaga from "./partner/partner.saga";
import DashBoardSaga from "./dashboard/dashboard.saga";
import LocalServerSaga from "./local-server/local-server.saga";
import SupportAccountSaga from "./support-account/support-account.saga";
import VoucherSaga from "./voucher/voucher.saga";
import NewsSaga from "./news/news.saga";
import PromotionSaga from "./promotions/promotion.saga";
import CheckLinkSaga from "./check-link/check-link.saga";
import ContentPromptSaga from "./content-prompt/content-prompt.saga";
import ApiKeySaga from "./api-key/api-key.saga";
import VisitSaga from "./visit/visit.saga";
import VipLevelSaga from "./vip-level/saga";
import NewsAccountSaga from "./news-account/news-account.saga";
function* rootSaga() {
  yield all([
    call(LoginSaga),
    call(CustomerSaga),
    call(CheckLinkSaga),
    call(OrderSaga),
    call(ServiceSaga),
    call(SettingSaga),
    call(NotificationSaga),
    call(TopicSaga),
    call(KeywordSaga),
    call(PartnerSaga),
    call(DashBoardSaga),
    call(LocalServerSaga),
    call(SupportAccountSaga),
    call(VoucherSaga),
    call(NewsSaga),
    call(PromotionSaga),
    call(ContentPromptSaga),
    call(ApiKeySaga),
    call(VisitSaga),
    call(VipLevelSaga),
    call(NewsAccountSaga),
  ]);
}

export default rootSaga;
