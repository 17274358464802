import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

function CustomDatePicker({ label, selectedDate, onChange, minDate,maxDate, disabled }) {
  return (
    <div className="datepicker-container">
      {label && <label className="datepicker-label">{label}</label>}
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        className="datepicker-input"
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
      />
    </div>
  );
}

export default CustomDatePicker;
