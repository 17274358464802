import React, { useState, useEffect, Suspense, useMemo } from "react";
import { lazy } from "react";

import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import MST from "../../components";

import Select from "../../components/base/select/Select";
import SysFetch from "../../app/fetch";
import "./style.css";
import { NewsActions, NewsSelectors } from "../../app/services/news/news.slice";
import NewsCategoryModal from "./NewsCategoryModal";
import { toast } from "react-toastify";
import { useFirstRender } from "../../app/hooks";
import Skeleton from "../../components/base/skeleton/Skeleton";
import LanguageSelect, {
  languages,
} from "../../components/table-create-service/LanguageSelect";

const Editor = lazy(() => import("../../components/base/editor/Editor"));

function Loading() {
  return (
    <p>
      <i>Loading...</i>
    </p>
  );
}

function NewsCreatePage() {
  const initError = {
    title: "",
    summary: "",
    coverImage: "",
    content: "",
  };
  const [errorMessage, setErrorMessage] = useState(initError);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const newsCategories = useSelector(NewsSelectors.newsCategories);

  const loading = useSelector(NewsSelectors.loading);
  const newsDetail = useSelector(NewsSelectors.newsDetail);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [summary, setSummary] = useState("");

  const [isPublished, setIsPublished] = useState(false);
  const [isHot, setIsHot] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [categorySelected, setCategorySelected] = useState("");
  const [selectedLangTitle, setSelectedLangTitle] = useState("en");
  const [selectedLangSummary, setSelectedLangSummary] = useState("en");
  const [selectedLangContent, setSelectedLangContent] = useState("en");

  const [loadingImage, setLoadingImage] = useState(false);

  const validate = (callback) => {
    const tempEM = {
      title: "",
      summary: "",
      coverImage: "",
      content: "",
      category: "",
    };

    let errorCount = 0;
    if (!title) {
      errorCount++;
      tempEM.title = "Tiêu đề không được để trống";
    }

    if (title.length >= 200) {
      errorCount++;
      tempEM.title = "Tiêu đề không được quá 200 từ";
    }

    if (!summary) {
      errorCount++;
      tempEM.summary = "Tóm tắt tin không được để trống";
    }

    if (summary.length >= 500) {
      errorCount++;
      tempEM.summary = "Tóm tắt tin không được quá 500 từ";
    }
    if (!coverImage) {
      errorCount++;
      tempEM.coverImage = "Ảnh bìa không được để trống";
    }

    if (!content) {
      errorCount++;
      tempEM.content = "Content không được để trống";
    }

    if (!categorySelected) {
      errorCount++;
      tempEM.category = "Category không được để trống";
    }

    if (errorCount === 0) {
      callback();
    } else {
      setErrorMessage(tempEM);
    }
  };

  const handleCoverImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        setLoadingImage(true);
        const response = await SysFetch.post(
          "uploads/image/cloudinary",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.success) {
          setLoadingImage(false);
          setCoverImage(response.data);
        } else {
          setLoadingImage(false);
          console.error("Error uploading cover image:", response.message);
        }
      } catch (error) {
        setLoadingImage(false);
        toast.error("Error uploading cover image");
        console.error("Error uploading cover image:", error);
      }
    }
  };

  const handleSubmit = () => {
    const newsData = {
      title,
      content,
      summary,
      isPublished,
      isHot,
      coverImage,
      category: categorySelected?.value,
    };

    if (id) {
      dispatch(
        NewsActions.updateNews({
          id,
          newsData,
          onSuccess: () => {
            navigate("/services/news");
          },
        })
      );
    } else {
      dispatch(
        NewsActions.createNews({
          newsData,
          onSuccess: () => {
            navigate("/services/news");
          },
        })
      );
    }
  };

  const onChangePublish = () => {
    setIsPublished((prevStatus) => !prevStatus);
  };

  const onChangeHot = () => {
    setIsHot((prevStatus) => !prevStatus);
  };

  // Set Data Edit
  useEffect(() => {
    if (newsDetail) {
      setTitle(newsDetail.title);
      setContent(newsDetail?.content);
      setSummary(newsDetail.summary);
      setIsPublished(newsDetail.isPublished);
      setIsHot(newsDetail.isHot);
      setCoverImage(newsDetail.coverImage);
      if (newsCategories) {
        setCategorySelected(
          newsCategories.find((item) => item.value === newsDetail.category._id)
        );
      }
    }
  }, [newsDetail, newsCategories]);

  // Get News Categories, Tags
  useEffect(() => {
    if (newsCategories.length === 0) {
      dispatch(NewsActions.getNewsCategories());
    }
  }, [dispatch, newsCategories.length]);

  if (loading) {
    return <Loading />;
  }

  return (
    <MST.Container
      title={newsDetail ? "Cập nhật tin tức" : "Tạo mới Tin tức"}
      right={
        <div className="d-flex">
          <MST.Button
            onClick={() => navigate("/services/news")}
            type="outlined"
            className="mr-8"
          >
            Cancel
          </MST.Button>
          <MST.Button onClick={(e) => validate(handleSubmit)}>
            {id ? "Update" : "Create"}
          </MST.Button>
        </div>
      }
    >
      <div className="news-create-content-container">
        <div className="news-create-content-left">
          <div className="news-create-content">
            <div className="news-create-title">{"Tin tức"}</div>
            <div className="news-create-one-field">
              <div className="flex flex-1 flex-col">
                <div className="news-create-one-field-name">
                  {"Tiêu đề tin"}
                  <span className="color-red"> *</span>
                </div>
                <div>
                  <LanguageSelect
                    selectedLang={selectedLangTitle}
                    onSelectLanguage={setSelectedLangTitle}
                  />
                  {languages.map(
                    (lang) =>
                      selectedLangTitle === lang && (
                        <MST.Input
                          errorMessage={errorMessage?.title}
                          placeholder="Nhập tiêu đề"
                          maxLength={50}
                          value={
                            typeof title !== "object" &&
                            selectedLangTitle === "en"
                              ? title || ""
                              : title?.[lang] || ""
                          }
                          onChange={(e) => {
                            setErrorMessage({
                              ...errorMessage,
                              title: "",
                            });
                            setTitle((cur) =>
                              typeof cur === "object"
                                ? {
                                    ...cur,
                                    [lang]: e.target.value,
                                  }
                                : { en: e.target.value }
                            );
                          }}
                          key={lang + "edit news title"}
                        />
                      )
                  )}
                </div>
              </div>
            </div>

            <div className="news-create-one-field">
              <div className="flex flex-1 flex-col">
                <div className="news-create-one-field-name">
                  {"Tóm tắt"}
                  <span className="color-red"> *</span>
                </div>
                <div>
                  <LanguageSelect
                    selectedLang={selectedLangSummary}
                    onSelectLanguage={setSelectedLangSummary}
                  />
                  {languages.map(
                    (lang) =>
                      selectedLangSummary === lang && (
                        <MST.Input
                          errorMessage={errorMessage?.summary}
                          placeholder="Nhập tóm tắt tin"
                          value={
                            typeof summary !== "object" &&
                            selectedLangSummary === "en"
                              ? summary || ""
                              : summary?.[lang] || ""
                          }
                          onChange={(e) => {
                            setErrorMessage({
                              ...errorMessage,
                              summary: "",
                            });
                            setSummary((cur) =>
                              typeof cur === "object"
                                ? {
                                    ...cur,
                                    [lang]: e.target.value,
                                  }
                                : { en: e.target.value }
                            );
                          }}
                          key={lang + "edit news summary"}
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="news-create-content">
            <div className="news-create-title">{"Nhập nội dung"}</div>

            <div className="news-create-one-field">
              <div className="flex flex-1 flex-col">
                <div className="news-create-one-field-name">Ảnh bìa</div>

                <MST.Input
                  type="file"
                  errorMessage={errorMessage?.coverImage}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      coverImage: "",
                    });
                    handleCoverImageChange(e);
                  }}
                />

                {loadingImage ? <Skeleton.Image /> : ""}
                {coverImage && (
                  <img
                    src={coverImage?.url}
                    alt="Cover Preview"
                    className="mt-3  object-cover sm:w-96 max-h-[200px]"
                  />
                )}
              </div>
            </div>
            <div className="news-create-one-field">
              <div className="flex flex-1 flex-col">
                <div className="news-create-one-field-name">{"Nội dung"}</div>

                <div
                  className={
                    errorMessage?.content
                      ? "news-create-editor input-container-has-error"
                      : "news-create-editor"
                  }
                >
                  <LanguageSelect
                    selectedLang={selectedLangContent}
                    onSelectLanguage={setSelectedLangContent}
                  />
                  {newsDetail?.content &&
                    languages.map(
                      (lang) =>
                        selectedLangContent === lang && (
                          <Suspense
                            fallback={<Loading></Loading>}
                            key={lang + "create news content"}
                          >
                            {" "}
                            <Editor
                              placeholder="Nhập mô tả"
                              onBlur={(eventInfo, editor) => {
                                const data = editor.getData();
                                if (isEmpty(data)) {
                                  editor.setData("");
                                  return;
                                }

                                setContent((cur) =>
                                  typeof cur === "object"
                                    ? {
                                        ...cur,
                                        [lang]: data,
                                      }
                                    : { en: data }
                                );
                              }}
                              onChange={(eventInfo, editor) => {
                                setErrorMessage({
                                  ...errorMessage,
                                  content: "",
                                });
                              }}
                              data={
                                typeof content !== "object" &&
                                selectedLangContent === "en"
                                  ? content || ""
                                  : content?.[lang] || ""
                              }
                            />
                          </Suspense>
                        )
                    )}

                  {!id &&
                    languages.map(
                      (lang) =>
                        selectedLangContent === lang && (
                          <Suspense
                            fallback={<Loading></Loading>}
                            key={lang + "edit news content"}
                          >
                            <Editor
                              placeholder="Nhập mô tả"
                              onBlur={(eventInfo, editor) => {
                                const data = editor.getData();
                                if (isEmpty(data)) {
                                  editor.setData("");
                                  return;
                                }

                                setContent((cur) => ({
                                  ...cur,
                                  [lang]: data,
                                }));
                              }}
                              onChange={(eventInfo, editor) => {
                                setErrorMessage({
                                  ...errorMessage,
                                  content: "",
                                });
                              }}
                              data={
                                typeof content !== "object" &&
                                selectedLangContent === "en"
                                  ? content || ""
                                  : content?.[lang] || ""
                              }
                            />
                          </Suspense>
                        )
                    )}

                  {errorMessage?.content && (
                    <div className={`text-right`}>
                      <span className={`input-error-message`}>
                        {errorMessage.content}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="news-create-content-right">
          <div className="news-create-content">
            <div className="news-create-title mb-2">Trạng thái</div>

            <div className="news-create-one-field block">
              <div className="news-create-one-field-name">
                {"Phát hành Tin tức"}
              </div>
              <MST.Switch enable={isPublished} onClick={onChangePublish} />
            </div>
            <div className="news-create-one-field block">
              <div className="news-create-one-field-name">{"Tin Nổi Bật"}</div>
              <MST.Switch enable={isHot} onClick={onChangeHot} />
            </div>
          </div>
          <div className="news-create-content">
            <div className="news-create-title">{"Phân loại"}</div>

            <div className="news-create-one-field">
              <div className="flex flex-1 flex-col">
                <div className="news-create-one-field-name">Chọn danh mục</div>
                <div>
                  <Select.Simple
                    placeholder="Chọn danh mục"
                    canRemove
                    onRemove={(value) => {
                      dispatch(
                        NewsActions.deleteNewsCategory({
                          id: value,
                          onSuccess: () => {
                            toast.success("Xóa danh mục thành công");
                            // dispatch(NewsActions.getAllNews());
                          },
                        })
                      );
                    }}
                    validate={errorMessage?.category ? true : false}
                    errorTitle={errorMessage?.category}
                    selected={categorySelected}
                    setSelected={(e) => {
                      setErrorMessage({
                        ...errorMessage,
                        category: "",
                      });

                      setCategorySelected(e);
                    }}
                    width={"100%"}
                    data={newsCategories}
                  />
                </div>
                <div className="mt-2">
                  <NewsCategoryModal
                    categorySelected={categorySelected}
                    setCategorySelected={setCategorySelected}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MST.Container>
  );
}

export default NewsCreatePage;
