import SysFetch from "../../fetch";
import qs from "qs";

const VisitRequest = {
  getVisits: (params) => {
    return SysFetch.get(
      `/visit?${qs.stringify(params, {
        encode: true,
      })}`
    );
  },
};

export default VisitRequest;
