import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CONST from "../../app/services/const";
import {
  ServiceActions,
  ServiceSelectors,
} from "../../app/services/service/service.slice";
import { formatPriceVND } from "../../app/utils/format";
import Pagination from "../../components/base/pagination/Pagination";
import Table from "../../components/base/table/Table";
import ServiceDeleteModal from "./Service.Options";
import ServiceSearch from "./ServiceSearch";
import { useLocation } from "react-router-dom";
import "./style.css";

function ServiceList() {
  const dispatch = useDispatch();
  const serviceList = useSelector(ServiceSelectors.serviceList);
  const pagination = useSelector(ServiceSelectors.pagination);
  const isFetching = useSelector(ServiceSelectors.isFetching);
  const location = useLocation();
 
 
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    dispatch(ServiceActions.setCurrentPage(searchParams.get("query")))
  }, [location.search, dispatch]);


  useEffect(() => {
    dispatch(ServiceActions.getServices());
    dispatch(ServiceActions.getScriptGroupCodeList());
  }, [pagination.page, dispatch]);

  const thead = [
    {
      name: "STT",
      style: { width: 20 },
      className: "",
    },
    {
      name: "ẢNH",
      style: { width: 30 },
    },
    {
      style: {
        textAlign: "left",
      },
      name: "Tên dịch vụ",
    },
    {
      name: "Giá dịch vụ",
      style: { width: 120 },
    },
    {
      name: "nhóm",
      style: { width: 120 },
    },
    {
      name: "Đơn vị xử lý",
      style: { width: 120 },
    },
    {
      name: "trạng thái",
      style: { width: 120 },
    },
    {
      name: "thao tác",
      style: { width: 100 },
    },
  ];

  const genDataTable = () => {
    try {
      return serviceList.map((x, index) => {
        return [
          { value: (pagination.page - 1) * pagination.pageSize + (index + 1) },
          {
            value: (() => {
              try {
                return (
                  <img
                    className="service-list-icon"
                    src={`${CONST.URL.API}/images/services/${x.scriptGroupCode}.png`}
                  />
                );
              } catch (error) {
                return [];
              }
            })(),
          },
          {
            style: {
              textAlign: "left",
            },
            value: x.name,
          },
          {
            value: `${formatPriceVND(x.originPrice)}đ`,
          },
          {
            value: x.scriptGroupCode,
          },
          {
            value: x?.partnerCode === "local" ? "Nội bộ" : x?.partnerCode,
          },
          {
            value: x.status ? "Sẵn sàng bán" : "Ngừng bán",
            style: {
              color: sGenColor(x.status ? "active" : "unactive"),
            },
          },
          {
            value: <ServiceDeleteModal id={x._id} />,
          },
        ];
      });
    } catch (error) {
      return [];
    }
  };
  return (
    <div>
      <ServiceSearch />
      {isFetching ? (
        <div className="border-gray-300 h-8 w-8 pt-5 m-auto animate-spin rounded-full border-2 border-t-blue-500" />
      ) : (
        <Table head={thead} body={genDataTable()} />
      )}
      <div className="service-pagination">
        <Pagination
          {...pagination}
          onChange={(page) => {
            dispatch(
              ServiceActions.setPagination({
                ...pagination,
                page,
              })
            );
          }}
        />
      </div>
    </div>
  );
}

export default ServiceList;

export const sGenColor = (content) => {
  switch (content) {
    case "active":
      return "#23C16B";
    case "unactive":
      return "#72777A";
  }
};
