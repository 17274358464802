import React, { useState } from "react";
import MST from "../../components";
import SearchIcon from "../../images/icons/SearchIcon";

function DashBoardRightSearch({ setSearch }) {
  const [value, setValue] = useState();
  const handleChange = (e) => {
    setValue(e.target.value);
    setSearch(e.target.value);
  };
  return (
    <div className="search-dashboard-container">
      <MST.Input
        value={value}
        onChange={handleChange}
        placeholder={"Nhập tên dịch vụ"}
        right={
          <div className="service-search-btn">
            <SearchIcon />
          </div>
        }
      />
    </div>
  );
}

export default DashBoardRightSearch;
