import React from "react";
import MST from "../../components";

const ContentPromptAction = ({
  input,
  errorMessage,
  handleInputChange,
  addKeyword,
  removeKeyword,
  handleSubmit,
  handleClose,
  isUpdate = false,
  isLoading = false,
  handlePost,
}) => {
  return (
    <div className="flex flex-col h-full max-h-[80vh]">
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold">
          {isUpdate ? "Cập nhật chủ đề" : "Tạo mới chủ đề"}
        </h2>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        <div className="space-y-4">
          <div>
            <div className="mb-2">
              Nhập tên chủ đề
              <span className="text-red-500"> *</span>
            </div>
            <MST.Input
              errorMessage={errorMessage?.topic}
              placeholder="Nhập chủ đề"
              maxLength={50}
              value={input.topic}
              onChange={(e) => handleInputChange("topic", e.target.value)}
            />
          </div>
          <div>
            <div className="mb-2">
              Nhập từ khóa
              <span className="text-red-500"> *</span>
            </div>
            {input.keywords.map((keyword, index) => (
              <div key={index} className="flex gap-2 items-center mb-2">
                <div className="flex-1">
                  <MST.Input
                    errorMessage={errorMessage.keywords[index]}
                    placeholder="Nhập từ khóa"
                    maxLength={50}
                    value={keyword}
                    onChange={(e) =>
                      handleInputChange("keywords", e.target.value, index)
                    }
                  />
                </div>
                {index > 0 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => removeKeyword(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#ef4444"
                      className="w-8 h-8"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))}
            <div
              className="cursor-pointer flex gap-1 items-center py-3 px-2 bg-slate-300 rounded-md w-40"
              onClick={addKeyword}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#0369a1"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                  clipRule="evenodd"
                />
              </svg>
              <div>Thêm từ khóa</div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 border-t">
        <div className="flex justify-end gap-2">
          {!isUpdate && (
            <div
              className={`bg-[#01bacf] ${
                isLoading ? "pointer-events-none opacity-40" : ""
              } px-6 flex justify-center items-center rounded-md text-slate-50 font-medium cursor-pointer`}
              onClick={handlePost}
            >
              <div>
                {isLoading ? (
                  <div className="animate-spin">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  </div>
                ) : (
                  "Tạo bài viết"
                )}
              </div>
            </div>
          )}
          <MST.Button disabled={isLoading} onClick={handleSubmit}>
            {isUpdate ? "Cập nhật" : "Thêm"}
          </MST.Button>
          <MST.Button
            disabled={isLoading}
            type="outlined"
            onClick={handleClose}
          >
            Đóng
          </MST.Button>
        </div>
      </div>
    </div>
  );
};

export default ContentPromptAction;
