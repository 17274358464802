import React from "react";
import { useState, useCallback, useRef, useEffect } from "react";
import { useChat } from "./ChatProvider";
import { Avatar, Modal, Box, Button } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearIcon from "@mui/icons-material/Clear";
import EmojiPicker from "emoji-picker-react";
import Message from "../message/Message";
import SVGEmoji from "../../images/icons/SVGEmoji";
import SVGsendMessage from "../../images/icons/SVGsendMessage";

const ChatBox = () => {
  const {
    currentChat,
    sendMessage,
    setCurrentChat,
    setActiveChats,
    closeChat,
    isSeen,
  } = useChat();
  const [newMessage, setNewMessage] = useState("");
  const [lastCursorPosition, setLastCursorPosition] = useState(0);
  const [isOpenEmoji, setIsOpenEmoji] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const textareaRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const emojiRef = useRef(null);
  const modalRef = useRef(null);
  const chatBoxRef = useRef(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  useEffect(() => {
    const handleClickOutSide = (event) => {
      if (
        emojiRef?.current &&
        emojiButtonRef?.current &&
        !emojiRef?.current.contains(event.target) &&
        !emojiButtonRef?.current.contains(event.target) &&
        isOpenEmoji
      ) {
        setIsOpenEmoji(false);
      }
      if (
        modalRef?.current &&
        !modalRef?.current.contains(event.target) &&
        isShow
      ) {
        setIsShow(false);
      }
    };
    window.addEventListener("click", handleClickOutSide);
    return () => {
      window.removeEventListener("click", handleClickOutSide);
    };
  }, [isShow, isOpenEmoji]);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const autoResizeTextarea = useCallback(() => {
    if (textareaRef?.current) {
      textareaRef.current.style.height = `30px`;
      textareaRef.current.style.height = `${textareaRef?.current?.scrollHeight}px`;
    }
  }, [textareaRef?.current]);

  useEffect(() => {
    autoResizeTextarea();
  }, [newMessage]);

  useEffect(() => {
    if (chatBoxRef && true) {
      const scrollHeight = chatBoxRef.current?.scrollHeight || 0;
      chatBoxRef.current?.scrollTo(0, scrollHeight);
    }
  }, [currentChat?.messages, isShow]);

  const handleCursorPosition = useCallback(() => {
    if (textareaRef?.current) {
      setLastCursorPosition(textareaRef.current?.selectionStart);
    }
  }, [textareaRef?.current]);

  const handleSend = (event) => {
    if (event.keyCode === 13 && newMessage?.trim().length === 0) {
      setNewMessage("");
      return;
    }

    if (event.shiftKey && event.keyCode === 13) {
      return;
    }

    if (
      (event.key === "Enter" || event.type === "click") &&
      newMessage?.trim().length > 0
    ) {
      event.preventDefault();
      setNewMessage("");
      sendMessage(newMessage);
    }
  };

  const handleCloseChat = (item) => {
    closeChat(item);
    setIsOpenModal(false);
  };

  return currentChat ? (
    <div
      ref={modalRef}
      className="max-w-[calc(100vw-50px)] origin-bottom-right duration-200 w-[400px] rounded-lg absolute bottom-0 right-7 z-20"
    >
      <div className="rounded-lg shadow-lg bg-white">
        {/* Header của đoạn chat */}
        <div className="text-white bg-[#FF8900] py-4 px-4 flex items-center rounded-t-lg justify-between">
          <div className="flex items-center">
            <Avatar
              alt={currentChat?.user?.email?.toUpperCase()}
              src="/static/images/avatar/1.jpg"
              sx={{ width: 32, height: 32 }}
            />
            <span className="ml-2">{currentChat?.user?.email}</span>
          </div>
          <div>
            <RemoveIcon
              onClick={() => {
                setCurrentChat(null);
                setActiveChats((prev) =>
                  prev.map((item) => {
                    if (
                      item?._id === currentChat._id &&
                      item?.messages[item?.messages?.length - 1]?.sender ===
                        "user"
                    ) {
                      return { ...item, status: "pending" };
                    }
                    return item;
                  })
                );
              }}
              className="cursor-pointer"
            />
            <ClearIcon
              onClick={() => setIsOpenModal((prev) => !prev)}
              className="cursor-pointer"
            />
            <Modal
              open={isOpenModal}
              onClose={() => setIsOpenModal((prev) => !prev)}
            >
              <Box sx={{ ...style, width: 400 }}>
                <h2 id="parent-modal-title">
                  Bạn có chắc muốn xóa đoạn chat này ?
                </h2>
                <div className="flex justify-around mt-5">
                  <Button
                    onClick={() => setIsOpenModal((prev) => !prev)}
                    variant="outlined"
                  >
                    Hủy Bỏ
                  </Button>
                  <Button
                    onClick={() => handleCloseChat(currentChat)}
                    variant="contained"
                  >
                    Xác Nhận
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>

        {/* Phần chat với khách hàng */}
        <div className="h-[440px] flex flex-col justify-between">
          <div
            ref={chatBoxRef}
            className="message-container p-5 overflow-y-scroll flex flex-col gap-2"
          >
            {currentChat?.messages
              .filter((message) => !message.isClosedMessage)
              .map((message, index) => (
                <Message
                  key={index}
                  message={message}
                  showTime={index === currentChat?.messages.length - 1}
                  userName={currentChat?.user?.email}
                  seen={isSeen}
                  chatId={currentChat._id}
                />
              ))}
          </div>
          <div>
            <div className="border-t-1 flex items-center w-full">
              <textarea
                ref={textareaRef}
                onChange={(e) => handleInputChange(e)}
                className="resize-none overflow-y-auto overflow-hidden border-none outline-none ml-4 w-5/6 items-center my-2 mr-1 max-h-[200px]"
                placeholder="Nhập tin nhắn của bạn"
                value={newMessage}
                style={{ height: "30px" }}
                onKeyDown={(event) => handleSend(event)}
                onInput={handleCursorPosition}
                onMouseMove={handleCursorPosition}
              />
              {newMessage ? (
                <SVGsendMessage
                  className="cursor-pointer"
                  onClick={(event) => handleSend(event)}
                />
              ) : (
                <div></div>
              )}
            </div>
            <div
              ref={emojiButtonRef}
              className="h-[44px] pl-[12px] rounded-b-lg flex items-center border-t-1 relative"
            >
              <SVGEmoji
                className="cursor-pointer"
                onClick={() => setIsOpenEmoji((prev) => !prev)}
              />

              <div
                ref={emojiRef}
                className="absolute bottom-[90px] left-0 z-50"
              >
                {isOpenEmoji && (
                  <EmojiPicker
                    open={true}
                    onEmojiClick={(event) => {
                      const selectionStart =
                        textareaRef.current?.selectionStart;

                      if (textareaRef.current) {
                        setTimeout(() => {
                          textareaRef.current?.setSelectionRange(
                            selectionStart + event.emoji.length,
                            selectionStart + event.emoji.length
                          );
                        }, 0);
                      }

                      setNewMessage(
                        (_prev) =>
                          _prev.slice(0, selectionStart) +
                          event.emoji +
                          _prev.slice(selectionStart)
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ChatBox;
