import React, { useMemo, useState } from "react";
import Modal from "../../components/base/modal/Modal";
import EditIcon from "../../components/table-create-service/icons/EditIcon";
import DeleteIcon from "../../components/table-create-service/icons/DeleteIcon";
import MST from "../../components";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ContentPromptActions } from "../../app/services/content-prompt/content-prompt.slice";
import ContentPromptAction from "./ContentPromptAction";

const ContentPromptOption = ({ id, value }) => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    topic: "",
    keywords: [],
  });
  const [input, setInput] = useState({
    topic: value?.topic || "",
    keywords: value?.keywords || [],
  });

  const onHide = () => {
    setIsShow(false);
  };

  const onShow = () => {
    setIsShow(true);
  };

  const onDelete = () => {
    dispatch(
      ContentPromptActions.deleteContentPrompt({
        id,
        onSuccess: () => {
          toast.success("Xoá chủ đề thành công");
          dispatch(ContentPromptActions.getAllContentPrompt());
          onHide();
        },
      })
    );
  };

  const validate = () => {
    let isValid = true;
    const newErrorMessage = {
      topic: "",
      keywords: [],
    };

    if (!input.topic.trim()) {
      newErrorMessage.topic = "Chủ đề không được để trống";
      isValid = false;
    }

    input.keywords.forEach((keyword, index) => {
      if (!keyword.trim()) {
        newErrorMessage.keywords[index] = "Từ khóa không được để trống";
        isValid = false;
      } else {
        newErrorMessage.keywords[index] = "";
      }
    });

    setErrorMessage(newErrorMessage);
    return isValid;
  };

  const handleInputChange = (field, value, index) => {
    if (field === "keywords") {
      const newKeywords = [...input.keywords];
      newKeywords[index] = value;
      setInput((prev) => ({ ...prev, keywords: newKeywords }));
      setErrorMessage((prev) => {
        const newKeywordErrors = [...prev.keywords];
        newKeywordErrors[index] = "";
        return { ...prev, keywords: newKeywordErrors };
      });
    } else {
      setInput((prev) => ({ ...prev, [field]: value }));
      setErrorMessage((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const addKeyword = () => {
    setInput((prev) => ({ ...prev, keywords: [...prev.keywords, ""] }));
    setErrorMessage((prev) => ({ ...prev, keywords: [...prev.keywords, ""] }));
  };

  const removeKeyword = (index) => {
    setInput((prev) => ({
      ...prev,
      keywords: prev.keywords.filter((_, i) => i !== index),
    }));
    setErrorMessage((prev) => ({
      ...prev,
      keywords: prev.keywords.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = () => {
    if (validate()) {
      dispatch(
        ContentPromptActions.updateContentPrompt({
          input,
          id,
          onSuccess: () => {
            setIsShowUpdate(false);
            dispatch(ContentPromptActions.getAllContentPrompt());
          },
        })
      );
    }
  };

  const handleClose = () => {
    setIsShowUpdate(false);
    setInput((prev) => ({
      ...prev,
      topic: value?.topic,
      keywords: value?.keywords,
    }));
    setErrorMessage((prev) => ({
      ...prev,
      topic: "",
      keywords: [],
    }));
  };

  const contentModal = useMemo(() => {
    return (
      <div>
        <div className="modal-header">Xoá chủ đề</div>
        <div className="modal-body">Bạn có muốn xoá chủ đề này?</div>
        <div className="modal-footer">
          <div className="d-flex jc-between">
            <div />
            <div className="d-flex">
              <MST.Button type="outlined" className="mr-8" onClick={onHide}>
                Huỷ
              </MST.Button>
              <MST.Button onClick={onDelete}>Xác nhận</MST.Button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [id]);

  return (
    <div className="d-flex jc-center">
      <Modal
        contentWidth={600}
        isShow={isShowUpdate}
        onHide={() => {
          handleClose();
        }}
        content={
          <ContentPromptAction
            input={input}
            errorMessage={errorMessage}
            handleInputChange={handleInputChange}
            addKeyword={addKeyword}
            removeKeyword={removeKeyword}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            isUpdate={true}
          />
        }
      />
      <button onClick={() => setIsShowUpdate(true)} className="btn-edit mr-8">
        <EditIcon />
      </button>
      <button className="btn-delete" onClick={onShow}>
        <DeleteIcon />
      </button>
      <Modal content={contentModal} isShow={isShow} onHide={onHide} />
    </div>
  );
};

export default ContentPromptOption;
