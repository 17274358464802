import qs from "qs";
import SysFetch from "../../fetch";

const ApiKeyRequest = {
  createApiKey: (newsData) => {
    return SysFetch.post(`api-key`, newsData);
  },
  getAllApiKey: (body) => {
    return SysFetch.get(`api-key?${qs.stringify(body, { encode: false })}`);
  },
  updateApiKey: ({ id, input }) => {
    return SysFetch.put(`api-key/${id}`, input);
  },
  deleteApiKey: (id) => {
    return SysFetch.delete(`api-key/${id}`);
  },
  getApiKeyById: (id) => {
    return SysFetch.get(`api-key/${id}`);
  },
};

export default ApiKeyRequest;
