import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/base/pagination/Pagination";
import Table from "../../components/base/table/Table";
import {
  ContentPromptActions,
  ContentPromptSelectors,
} from "../../app/services/content-prompt/content-prompt.slice";
import ContentPromptOption from "./ContentPromptOption";
import ContentPromptSearch from "./ContentPromptSearch";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const ContentPromptList = () => {
  const dispatch = useDispatch();
  const contentPromptList = useSelector(
    ContentPromptSelectors.contentPromptList
  );
  const pagination = useSelector(ContentPromptSelectors.pagination);
  const keySearch = useSelector(ContentPromptSelectors.keySearch);

  useEffect(() => {
    dispatch(ContentPromptActions.getAllContentPrompt());
  }, [dispatch, pagination.page, keySearch]);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const thead = [
    {
      name: "STT",
      style: { width: 20 },
      className: "",
    },
    {
      name: "Chủ đề",
      style: { width: 120 },
    },
    {
      name: "Từ khóa",
      style: { width: 120 },
    },
    {
      name: "trạng thái",
      style: { width: 120 },
    },
    {
      name: "thao tác",
      style: { width: 100 },
    },
  ];

  const handleToggle = (data) => {
    dispatch(
      ContentPromptActions.updateContentPrompt({
        input: { isActive: !data.isActive },
        id: data._id,
        onSuccess: () => {
          dispatch(ContentPromptActions.getAllContentPrompt());
        },
      })
    );
  };

  const genDataTable = () => {
    try {
      return contentPromptList.map((x, index) => {
        return [
          { value: (pagination.page - 1) * pagination.pageSize + (index + 1) },
          {
            value: `${x?.topic}`,
          },
          {
            value: x?.keywords
              .map((item, index) => <span key={index}>{item}</span>)
              .reduce(
                (prev, curr, index) => [prev, index > 0 && ", ", curr],
                []
              ),
          },
          {
            value: (
              <IOSSwitch
                sx={{ m: 1 }}
                checked={x?.isActive}
                onChange={() => handleToggle(x)}
              />
            ),
          },
          {
            value: <ContentPromptOption id={x._id} value={x} />,
          },
        ];
      });
    } catch (error) {
      return [];
    }
  };

  return (
    <div className="news-list">
      <ContentPromptSearch />
      <Table head={thead} body={genDataTable()} />
      <div className="service-pagination">
        <Pagination
          {...pagination}
          onChange={(page) => {
            dispatch(
              ContentPromptActions.setPagination({
                ...pagination,
                page,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default ContentPromptList;
