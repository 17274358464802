import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  VoucherActions,
  VoucherSelectors,
} from "../../app/services/voucher/voucher.slice";
import MST from "../../components";
import SearchIcon from "../../images/icons/SearchIcon";
import {
  PromotionActions,
  PromotionSelectors,
} from "../../app/services/promotions/promotion.slice";

function PromotionSearch() {
  const dispatch = useDispatch();
  const search = useSelector(PromotionSelectors.search);

  useEffect(() => {
    dispatch(PromotionActions.getPromotions({ search }));
  }, [search, dispatch]);

  return (
    <div>
      <div className="search-voucher-container">
        <div className="search-voucher-input">
          <MST.Input
            value={search}
            onChange={(e) =>
              dispatch(PromotionActions.setSearch(e.target.value))
            }
            placeholder={"Nhập tiêu đề promotion tìm kiếm"}
            right={
              <div className="voucher-search-btn">
                <SearchIcon />
              </div>
            }
          />
        </div>

        <div
          className="search-voucher-service"
          style={{
            alignSelf: "stretch",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
      </div>
    </div>
  );
}

export default PromotionSearch;
