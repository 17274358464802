import React, { Fragment, useState } from "react";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Box,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useChat } from "../chat/ChatProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Message = ({ message, userName, seen, showTime, chatId }) => {
  const { content, sender, timestamp, _id } = message;
  const { deleteMessage } = useChat();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isShowOption, setIsShowOption] = useState(false);

  const splitTextAndURLs = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const result = [];
    let lastIndex = 0;
    let match;
    while ((match = urlRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        result.push({
          type: "text",
          content: text.slice(lastIndex, match.index),
        });
      }

      // Tách các URL nếu chúng liền nhau
      let urls = match[0].split(/(?=https?:\/\/)/);
      urls.forEach((url) => {
        result.push({ type: "url", content: url });
      });

      lastIndex = match.index + match[0].length;
    }
    if (lastIndex < text.length) {
      result.push({ type: "text", content: text.slice(lastIndex) });
    }
    return result;
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsShowOption(false);
  };

  const handleDeleteMessage = () => {
    deleteMessage(chatId, _id);
    setIsOpenModal(false);
    handleMenuClose();
  };

  return (
    <div
      className={`w-full items-start max-w-[90%] flex gap-5 ${
        sender === "support"
          ? "self-end justify-end"
          : "justify-start"
      }`}
      onMouseEnter={() => setIsShowOption(true)}
      onMouseLeave={() =>
        setTimeout(() => {
          setIsShowOption(false);
        }, 500)
      }
    >
      {sender === "user" ? (
        <Avatar
          alt={userName?.toUpperCase()}
          src="/static/images/avatar/1.jpg"
          sx={{ width: 32, height: 32 }}
        />
      ) : null}
      <div
        className={`${
          sender === "user" ? `max-w-[calc(100%-50px)]` : `max-w-full`
        } flex flex-1 ${
          sender === "support"
            ? "justify-end items-end"
            : "justify-start items-start"
        }  flex-col relative`}
      >
        <div className="max-w-full inline-flex gap-1 items-start relative">
          {sender === "support" && isShowOption  && (
            <div className="absolute right-full top-0 -translate-x-2">
              <IconButton onClick={handleMenuOpen} size="small">
                <MoreVertIcon fontSize="small" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setIsOpenModal(true);
                    setAnchorEl(false);
                    setIsShowOption(false);
                  }}
                >
                  <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />
                  Xóa
                </MenuItem>
              </Menu>
            </div>
          )}
          <div
            title={new Date(timestamp).toLocaleString("vi-VN", {
              hour: "2-digit",
              minute: "2-digit",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
            className={`max-w-full flex-1 outline-none border-none resize-none inline-block ${
              sender === "support"
                ? "bg-[#0084ff]"
                : "flex-1 flex flex-col gap-1 bg-[#303030] mr-auto"
            } p-2 rounded-[10px] text-white`}
          >
            {content.split("\n").map((e, i) => (
              <Fragment key={i}>
                <p className="max-w-full w-full inline-block break-words">
                  {splitTextAndURLs(e).map((item, index) => {
                    if (item.type === "text") {
                      return <Fragment key={index}>{item.content}</Fragment>;
                    } else {
                      return (
                        <Fragment key={index}>
                          <a
                            target="_blank"
                            className="underline break-all text-white block"
                            href={item.content}
                            rel="noopener noreferrer"
                          >
                            {item.content}
                          </a>
                        </Fragment>
                      );
                    }
                  })}
                </p>
                {/* {i < content.split("\n").length - 1 && <br />} */}
              </Fragment>
            ))}
          </div>
        </div>

        {showTime && (
          <span
            className={`w-full text-[12px] ${
              sender === "support" ? "text-right" : "text-left"
            } text-slate-400`}
          >
            {seen && sender === "support" ? "Seen " : ""}
            {new Date(timestamp).toLocaleString("vi-VN", {
              hour: "2-digit",
              minute: "2-digit",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </span>
        )}
      </div>
      <Modal open={isOpenModal} onClose={() => setIsOpenModal((prev) => !prev)}>
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Bạn có chắc muốn xóa tin nhắn này ?</h2>
          <div className="flex justify-around mt-5">
            <Button
              onClick={() => setIsOpenModal((prev) => !prev)}
              variant="outlined"
            >
              Hủy Bỏ
            </Button>
            <Button onClick={handleDeleteMessage} variant="contained">
              Xác Nhận
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Message;
