// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker-container {
  display: flex;
  flex-direction: column;
}

.datepicker-label {
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
}

.datepicker-input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.datepicker-input:focus {
  border-color: #ff8900;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.react-datepicker {
  border: 1px solid #ff8900;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: #ff8900;
  color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #ff8900;
  color: #fff;
}

.react-datepicker__day--today {
  font-weight: bold;
  color: #fff;
}

.react-datepicker__day:hover {
  background-color: #ffbd70;
  
}

.react-datepicker__day-name{
  color: #fff;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff;
}

.react-datepicker__navigation-icon::before {
  border-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/base/date-picker/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,8CAA8C;AAChD;;AAEA;EACE,qBAAqB;EACrB,0CAA0C;EAC1C,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;;EAEE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,yBAAyB;;AAE3B;;AAEA;EACE,WAAW;AACb;;AAEA;;;EAGE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".datepicker-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.datepicker-label {\n  margin-bottom: 8px;\n  font-size: 14px;\n  color: #333;\n}\n\n.datepicker-input {\n  padding: 8px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  transition: border-color 0.3s, box-shadow 0.3s;\n}\n\n.datepicker-input:focus {\n  border-color: #ff8900;\n  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);\n  outline: none;\n}\n\n.react-datepicker {\n  border: 1px solid #ff8900;\n  border-radius: 4px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n}\n\n.react-datepicker__header {\n  background-color: #ff8900;\n  color: #fff;\n}\n\n.react-datepicker__day--selected,\n.react-datepicker__day--keyboard-selected {\n  background-color: #ff8900;\n  color: #fff;\n}\n\n.react-datepicker__day--today {\n  font-weight: bold;\n  color: #fff;\n}\n\n.react-datepicker__day:hover {\n  background-color: #ffbd70;\n  \n}\n\n.react-datepicker__day-name{\n  color: #fff;\n}\n\n.react-datepicker__current-month,\n.react-datepicker-time__header,\n.react-datepicker-year-header {\n  color: #fff;\n}\n\n.react-datepicker__navigation-icon::before {\n  border-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
