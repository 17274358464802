// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-content {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
}
.notification-search-btn {
  background-color: #ff8900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 48px;
  border-width: 1px solid white;
  border-radius: 8px;
  cursor: pointer;
}
.search-notification-container {
  padding-block: 16px;
}
.notification-pagination {
  padding-top: 16px;
}
.notification-icon-create {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.notification-list-icon {
  width: 32px;
  height: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/notifications/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".notification-content {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n}\n.notification-search-btn {\n  background-color: #ff8900;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 60px;\n  height: 48px;\n  border-width: 1px solid white;\n  border-radius: 8px;\n  cursor: pointer;\n}\n.search-notification-container {\n  padding-block: 16px;\n}\n.notification-pagination {\n  padding-top: 16px;\n}\n.notification-icon-create {\n  display: flex;\n  align-items: center;\n  margin-right: 8px;\n}\n.notification-list-icon {\n  width: 32px;\n  height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
