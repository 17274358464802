import { createSlice } from "@reduxjs/toolkit";
import { update } from "lodash";

const initialState = {
  serviceList: [],
  serviceLocalList: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  isFetching: false,
  keySearch: "",
  scriptGroupCode: "",
  serviceDetail: undefined,
  serviceGroup: [],
  serviceTags: [],
  scriptGroupCodeList: [],
  currentPage: null,
};

const ServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    resetServiceDetail: (state) => {
      state.serviceDetail = null;
    },
    getScriptGroupCodeList: (state, { payload }) => {},
    setScriptGroupCodeList: (state, { payload }) => {
      state.scriptGroupCodeList = payload;
    },
    setServiceGroup: (state, { payload }) => {
      state.serviceGroup = payload;
    },
    setServiceTags: (state, { payload }) => {
      state.serviceTags = payload;
    },
    getCreateInfo: () => {},
    createService: (state, { payload }) => {},
    edit: (state, { payload }) => {},
    getServices: () => {},
    setServices: (state, { payload }) => {
      state.serviceList = payload;
    },
    setIsFetching: (state, { payload }) => {
      state.isFetching = payload;
    },
    getServicesLocal: () => {},
    setServicesLocalList: (state, { payload }) => {
      const newData = payload.map((item) => ({
        name: item.name,
        _id: item._id,
      }));
      state.serviceLocalList = newData;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    setKeySearch: (state, { payload }) => {
      state.keySearch = payload;
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
    },
    setScriptGroupCode: (state, { payload }) => {
      state.scriptGroupCode = payload;
      state.keySearch = "";
      state.serviceList = [];
    },
    getServiceById: (state, { payload }) => {},
    setServiceDetail: (state, { payload }) => {
      state.serviceDetail = payload;
    },
    resetSession: (state) => {
      state.keySearch = "";
      state.serviceList = [];
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
    },
    delete: (state, { payload }) => {},
    block: (state, { payload }) => {},
    restore: (state, { payload }) => {},
    createServiceGroup: (state, { payload }) => {},
    createServiceTag: (state, { payload }) => {},
    deleteServiceGroup: (state, { payload }) => {},
    updateServiceGroup: (state, { payload }) => {},
  },
});

const ServiceReducer = ServiceSlice.reducer;
export default ServiceReducer;

export const ServiceActions = ServiceSlice.actions;
export const ServiceSelectors = {
  currentPage: (state) => state.service.currentPage,
  serviceList: (state) => state.service.serviceList,
  serviceLocalList: (state) => state.service.serviceLocalList,
  pagination: (state) => state.service.pagination,
  keySearch: (state) => state.service.keySearch,
  scriptGroupCode: (state) => state.service.scriptGroupCode,
  serviceDetail: (state) => state.service.serviceDetail,
  serviceGroup: (state) => state.service.serviceGroup,
  serviceTags: (state) => state.service.serviceTags,
  scriptGroupCodeList: (state) => state.service.scriptGroupCodeList,
  isFetching: (state) => state.service.isFetching,
};
