import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  ContentPromptActions,
  ContentPromptSelectors,
} from "./content-prompt.slice";
import { toast } from "react-toastify";
import ContentPromptRequest from "./content-prompt.request";

function* ContentPromptSaga() {
  yield takeLatest(
    ContentPromptActions.createContentPrompt,
    createContentPrompt
  );
  yield takeLatest(
    ContentPromptActions.postNewsByContentPrompt,
    postNewsByContentPrompt
  );
  yield takeLatest(
    ContentPromptActions.updateContentPrompt,
    updateContentPrompt
  );
  yield takeLatest(
    ContentPromptActions.getAllContentPrompt,
    getAllContentPrompt
  );
  yield takeLatest(
    ContentPromptActions.getContentPromptById,
    getContentPromptById
  );
  yield takeLatest(
    ContentPromptActions.deleteContentPrompt,
    deleteContentPrompt
  );
}

function* createContentPrompt({ payload }) {
  try {
    const { onSuccess, input } = payload;

    const response = yield call(
      ContentPromptRequest.createContentPrompt,
      input
    );
    if (response.success) {
      toast.success("Tạo chủ đề thành công");
      onSuccess();
      yield put(ContentPromptActions.setLoading(false));
    }
  } catch (error) {
    toast.error(error.response.data.message || "Có lỗi xảy ra khi tạo chủ đề");
    yield put(ContentPromptActions.setLoading(false));
  }
}

function* postNewsByContentPrompt({ payload }) {
  try {
    const { onSuccess, input } = payload;
    const response = yield call(
      ContentPromptRequest.postNewsContentPrompt,
      input
    );
    if (response.success) {
      toast.success("Đăng bài thành công");
      onSuccess();
    }
  } catch (error) {
    toast.error(error.response?.data?.message || "Có lỗi xảy ra khi đăng bài");
    yield put(ContentPromptActions.setLoading(false));
  } finally {
    yield put(ContentPromptActions.setLoading(false));
  }
}

function* updateContentPrompt({ payload }) {
  try {
    const { onSuccess, input, id } = payload;

    const response = yield call(ContentPromptRequest.updateContentPrompt, {
      input,
      id,
    });
    if (response.success) {
      toast.success("Cập nhật chủ đề thành công");
      onSuccess();
      yield put(ContentPromptActions.setLoading(false));
    }
  } catch (error) {
    toast.error(
      error.response.data.message || "Có lỗi xảy ra khi cập nhật chủ đề"
    );
    yield put(ContentPromptActions.setLoading(false));
  }
}

function* getAllContentPrompt() {
  try {
    const pagination = yield select(ContentPromptSelectors.pagination);

    const keySearch = yield select(ContentPromptSelectors.keySearch);

    const response = yield call(ContentPromptRequest.getAllContentPrompt, {
      page: pagination.page,
      pageSize: pagination.pageSize,
      search: keySearch || "",
    });

    if (response.success) {
      yield put(
        ContentPromptActions.getAllContentPromptSuccess(response.data.prompts)
      );
      yield put(ContentPromptActions.setPagination(response.data.pagination));
      yield put(ContentPromptActions.setLoading(false));
    } else {
      yield put(
        ContentPromptActions.getAllContentPromptFailure(response.message)
      );
      toast.error(response.message);
      yield put(ContentPromptActions.setLoading(false));
    }
  } catch (error) {
    toast.error(error.response.data.message || "Có lỗi xảy ra khi lấy chủ đề");
    yield put(ContentPromptActions.setLoading(false));
  }
}

function* getContentPromptById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(ContentPromptRequest.getContentPromptById, id);
    if (response.success) {
      yield put(
        ContentPromptActions.getContentPromptByIdSuccess(response.data)
      );
      yield put(ContentPromptActions.setLoading(false));
    } else {
      yield put(
        ContentPromptActions.getContentPromptByIdFailure(response.message)
      );
      toast.error(response.message);
      yield put(ContentPromptActions.setLoading(false));
    }
  } catch (error) {
    toast.error(error.response.data.message || "Có lỗi xảy ra khi lấy chủ đề");
    yield put(ContentPromptActions.setLoading(false));
  }
}

function* deleteContentPrompt({ payload }) {
  try {
    const { id, onSuccess } = payload;
    const response = yield call(ContentPromptRequest.deleteContentPrompt, id);

    if (response.success) {
      onSuccess();
      yield put(ContentPromptActions.setLoading(false));
    } else {
      toast.error(response.message);
      yield put(ContentPromptActions.setLoading(false));
    }
  } catch (error) {
    toast.error(error.response.data.message || "Có lỗi xảy ra khi xóa chủ đề");
    yield put(ContentPromptActions.setLoading(false));
  }
}

export default ContentPromptSaga;
