import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  detail: null,
};

const NewsAccountSlice = createSlice({
  name: "newsAccount",
  initialState,
  reducers: {
    create: () => {},
    update: () => {},
    delete: () => {},

    resetSession: (state) => {
      state.pagination = initialState.pagination;
      state.list = [];
      state.detail = null;
    },

    getNewsAccountList: () => {},

    setNewsAccountList: (state, { payload }) => {
      state.list = payload;
    },

    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },

    getNewsAccountById: () => {},

    setNewsAccountById: (state, { payload }) => {
      state.detail = payload;
    },
  },
});
const NewsAccountReducer = NewsAccountSlice.reducer;
export default NewsAccountReducer;
export const NewsAccountActions = NewsAccountSlice.actions;

export const NewsAccountSelectors = {
  list: (state) => state.newsAccount.list,
  pagination: (state) => state.newsAccount.pagination,
  detail: (state) => state.newsAccount.detail,
};
