import SysFetch from "../../fetch";
import qs from "qs";

const NewsAccountRequest = {
  delete: (id) => {
    return SysFetch.delete(`news-account/${id}`);
  },
  update: (id, body) => {
    return SysFetch.put(`news-account/${id}`, body);
  },
  create: (body) => {
    return SysFetch.post(`news-account`, body);
  },
  get: (id) => SysFetch.get(`news-account/${id}`),
  gets: (body) => {
    return SysFetch.get(
      `news-account?${qs.stringify(body, { encode: false })}`
    );
  },
};

export default NewsAccountRequest;
