import { useNavigate } from "react-router-dom";
import MST from "../../components";
import PlugIcon from "../../images/icons/PlugIcon";
import PromotionList from "./PromotionList";
function PromotionPage() {
  const navigate = useNavigate();
  const onCreate = () => navigate("/services/promotions/create");
  return (
    <MST.Container
      title="Danh sách promotion"
      right={
        <div>
          <MST.Button
            onClick={() => onCreate()}
            icon={
              <div className="promotion-icon-create">
                <PlugIcon />
              </div>
            }
          >
            Tạo mới Promotion
          </MST.Button>
        </div>
      }
    >
      <div className="promotion-content">
        <PromotionList />
      </div>
    </MST.Container>
  );
}
export default PromotionPage;
