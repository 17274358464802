import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useResolvedPath } from "react-router-dom";
import OneMenu from "./OneMenu";
import CustomersIcon from "./icons/CustomersIcon";
import SVGDashBoard from "./icons/DashBoardIcon";
import KeywordIcon from "./icons/KeywordIcon";
import NotificationIcon from "./icons/NotificationIcon";
import OrdersIcon from "./icons/OrdersIcon";
import ServicesIcon from "./icons/ServicesIcon";
import SettingsIcon from "./icons/SettingsIcon";
import "./style.css";
import { useSelector } from "react-redux";
import IconMenu from "./IconMenu";
import VoucherIcon from "./icons/VoucherIcon";
import NewsIcon from "./icons/NewsIcon";
import PromotionIcon from "./icons/PromotionIcon";

function MainMenu() {
  const navigate = useNavigate();
  const { pathname } = useResolvedPath();

  const [currentTab, setCurrentTab] = useState("services");
  const [currentSubTab, setCurrentSubTab] = useState("services");
  const [currentSubSubTab, setCurrentSubSubTab] = useState("services");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const userInfo = useSelector((state) => state.login.userInfo);
  const isRoleSupport = userInfo?.role === "support";
  const adminMenu = {
    services: [
      {
        name: "Thống kê",
        icon: <SVGDashBoard />,
        children: [
          {
            name: "Bảng thống kê",
            path: "/services/dashboard",
          },
          {
            name: "Lượt truy cập",
            path: "/services/visit",
          },
        ],
      },
      {
        name: "Dịch vụ",
        icon: <ServicesIcon />,
        children: [
          {
            name: "Danh sách dịch vụ",
            path: "/services/services",
          },
          {
            name: "Danh sách nhóm dịch vụ",
            path: "/services/services-groups",
          },
        ],
      },
      {
        name: "Đơn hàng",
        icon: <OrdersIcon />,
        children: [
          {
            name: "Danh sách đơn hàng",
            path: "/services/orders",
          },
        ],
      },
      {
        name: "Khách hàng",
        icon: <CustomersIcon />,
        children: [
          {
            name: "Danh sách khách hàng",
            path: "/services/customers",
          },
        ],
      },

      {
        name: "Vouchers",
        icon: <VoucherIcon />,
        children: [
          {
            name: "Danh sách voucher",
            path: "/services/vouchers",
          },
          {
            name: "Lịch sử áp dụng voucher",
            path: "/services/voucher-histories",
          },
        ],
      },
      {
        name: "Tin tức",
        icon: <NewsIcon />,
        children: [
          {
            name: "Danh sách tin tức",
            path: "/services/news/",
          },
          {
            name: "Chủ đề và từ khoá",
            path: "/services/content-prompt",
          },
        ],
      },
      {
        name: "Thông báo",
        icon: <NotificationIcon />,
        children: [
          {
            name: "Danh sách thông báo",
            path: "/services/notifications",
          },
        ],
      },
      {
        name: "Promotions",
        icon: <PromotionIcon />,
        children: [
          {
            name: "Danh sách khuyến mại",
            path: "/services/promotions",
          },
        ],
      },
      {
        name: "Cài đặt",
        icon: <SettingsIcon />,
        children: [
          {
            name: "API KEY",
            path: "/services/settings/api-key",
          },
          {
            name: "Support account",
            path: "/services/settings/support-account",
          },{
            name: "News Account",
            path: "/services/settings/news-account",
          },
          {
            name: "Local server",
            path: "/services/settings/local-server",
          },
          {
            name: "Thanh toán",
            path: "/services/settings/payment",
          },
          {
            name: "Cài đặt chung",
            path: "/services/settings/general",
          },
          {
            name: "Đối tác",
            path: "/services/settings/partner",
          },
        ],
      },
    ],
    keyword: [
      {
        name: "Thống kê",
        icon: <SVGDashBoard />,
        children: [
          {
            name: "Bảng thống kê",
            path: "/keyword/dashboard",
          },
        ],
      },
      {
        name: "Từ khóa",
        icon: <KeywordIcon />,
        children: [
          {
            name: "Quản lý từ khóa",
            path: "/keyword/keywords",
          },
          {
            name: "Quản lý chủ đề",
            path: "/keyword/topics",
          },
        ],
      },
      {
        name: "Cài đặt",
        icon: <SettingsIcon />,
        children: [
          {
            name: "Thanh toán",
            path: "/keyword/settings/payment",
          },
          {
            name: "Cài đặt chung",
            path: "/keyword/settings/general",
          },
          {
            name: "Đối tác",
            path: "/keyword/settings/partner",
          },
        ],
      },
    ],
  };
  const supportMenu = {
    services: [
      {
        name: "Đơn hàng",
        icon: <OrdersIcon />,
        children: [
          {
            name: "Danh sách đơn hàng",
            path: "/services/orders",
          },
        ],
      },
      {
        name: "Vouchers",
        icon: <VoucherIcon />,
        children: [
          {
            name: "Danh sách voucher",
            path: "/services/vouchers",
          },
          {
            name: "Lịch sử áp dụng voucher",
            path: "/services/voucher-histories",
          },
        ],
      },
      {
        name: "Tin tức",
        icon: <NewsIcon />,
        children: [
          {
            name: "Danh sách tin tức",
            path: "/services/news/",
          },
          {
            name: "Chủ đề và từ khoá",
            path: "/services/content-prompt",
          },
        ],
      },
      {
        name: "Khách hàng",
        icon: <CustomersIcon />,
        children: [
          {
            name: "Danh sách khách hàng",
            path: "/services/customers",
          },
        ],
      },
      {
        name: "Thông báo",
        icon: <NotificationIcon />,
        children: [
          {
            name: "Danh sách thông báo",
            path: "/services/notifications",
          },
        ],
      },
      {
        name: "Promotions",
        icon: <PromotionIcon />,
        children: [
          {
            name: "Danh sách khuyến mại",
            path: "/services/promotions",
          },
        ],
      },
    ],
  };
  const menuList = isRoleSupport ? supportMenu : adminMenu;

  useEffect(() => {
    setIsMenuOpen(false);
    const tempParams = pathname.split("/").filter((x) => !_.isEmpty(x));
    if (tempParams[0]) {
      setCurrentTab(tempParams[0]);
    }

    if (tempParams[1]) {
      setCurrentSubTab(tempParams[1]);
    }
    if (tempParams[2]) {
      setCurrentSubSubTab(tempParams[2]);
    } else {
      setCurrentSubSubTab("");
    }
  }, [pathname]);
  // Đóng menu khi click ra ngoài
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div onClick={() => setIsMenuOpen(!isMenuOpen)} className="icon-menu">
        {isMenuOpen ? "" : <IconMenu />}
      </div>
      <div
        className={`menu-container ${isMenuOpen ? "open" : ""}`}
        ref={menuRef}
      >
        <div className="menu-content">
          <div className="menu-list-container">
            <div className="menu-btn-conatiner">
              <div
                role="button"
                className={`menu-btn${
                  currentTab === "services" ? "-active" : ""
                }`}
                onClick={() => {
                  !isRoleSupport && navigate("/services/dashboard");
                }}
              >
                <div className="menu-btn-label">Dịch vụ</div>
              </div>
              <div
                role="button"
                className={`menu-btn${
                  currentTab === "keyword" ? "-active" : ""
                }`}
                onClick={() => {
                  !isRoleSupport && navigate("/keyword/dashboard");
                }}
              >
                <div className="menu-btn-label">Keyword Tool</div>
              </div>
            </div>
            {(menuList[`${currentTab}`] || []).length > 0 ? (
              <div className="menu-list-item-container">
                {(menuList[`${currentTab}`] || []).map((oneItem) => {
                  return (
                    <OneMenu
                      currentSubTab={currentSubTab}
                      currentSubSubTab={currentSubSubTab}
                      key={oneItem.name}
                      item={oneItem}
                    />
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MainMenu;
