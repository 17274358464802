import { createSlice } from "@reduxjs/toolkit";
import { getQuarter } from "date-fns";

const initialState = {
  voucherList: [],
  voucherHistoryList: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  voucherCodes: [],
  voucherDetail: undefined,
  serviceVoucherList: undefined,
  search: "",
  searchHistories: "",
  voucherCodesCopy: [],
  currentListPage: 1,
};

const VoucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    resetVoucherState: (state) => {
      state.currentListPage = 1;
    },
    setCurrentListPage: (state, { payload }) => {
      state.currentListPage = payload;
    },
    setVoucherCodesCopy: (state, { payload }) => {
      state.voucherCodesCopy = payload;
    },
    getVoucherCodesBySearch: (state, { payload }) => {},
    setVoucherCodesBySearch: (state, { payload }) => {
      state.voucherCodes = payload;
    },
    getAllVoucherCodes: (state, { payload }) => {},
    setAllVoucherCodes: (state, { payload }) => {
      state.voucherCodes = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    getVouchers: (state, { payload }) => {},
    setVouchers: (state, { payload }) => {
      state.voucherList = payload;
    },
    getVoucherHistories: () => {},
    setVoucherHistories: (state, { payload }) => {
      state.voucherHistoryList = payload;
    },
    setPagination: (state, { payload }) => {
      // state.pagination = payload;
      state.pagination = { ...state.pagination, ...payload };
    },
    getVoucherById: (state, { payload }) => {},
    getCreateInfo: () => {},
    createVoucher: (state, { payload }) => {},
    edit: (state, { payload }) => {},
    setVoucherDetail: (state, { payload }) => {
      state.voucherDetail = payload;
    },
    setSearch: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.search = payload;
    },
    setSearchHistories: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.searchHistories = payload;
    },
    setServicesSearch: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.servicesSearch = payload;
    },
    setServicesSearchHistory: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.servicesSearchHistory = payload;
    },
    resetSession: (state) => {
      state.voucherList = [];
      state.voucherHistoryList = [];
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.search = "";
      state.searchHistories = "";
    },
    delete: (state, { payload }) => {},
    deleteVoucherCode: (state, { payload }) => {},

    getQuantityVoucherCodes: (state, { payload }) => {},
    setQuantityVoucherCodes: (state, { payload }) => {
      state.voucherCodes = payload;
    },
  },
});

const VoucherReducer = VoucherSlice.reducer;
export default VoucherReducer;

export const VoucherActions = VoucherSlice.actions;
export const VoucherSelectors = {
  currentListPage: (state) => state.voucher.currentListPage,
  voucherCodesCopy: (state) => state.voucher.voucherCodesCopy,
  voucherCodes: (state) => state.voucher.voucherCodes,
  voucherList: (state) => state.voucher.voucherList,
  voucherHistoryList: (state) => state.voucher.voucherHistoryList,
  pagination: (state) => state.voucher.pagination,
  search: (state) => state.voucher.search,
  servicesSearch: (state) => state.voucher.servicesSearch,
  searchHistories: (state) => state.voucher.searchHistories,
  servicesSearchHistory: (state) => state.voucher.servicesSearchHistory,
  voucherDetail: (state) => state.voucher.voucherDetail,
  status: (state) => state.voucher.status,
};
