import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  NewsAccountActions,
  NewsAccountSelectors,
} from "../../app/services/news-account/news-account.slice";
import MST from "../../components";
import "./style.css";

function NewsAccountCreatePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newsAccountDetail = useSelector(NewsAccountSelectors.detail);
  const params = useParams();
  const id = params?.id;
  const isEdit = !!id;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(NewsAccountActions.getNewsAccountById({ id }));
    } else {
      dispatch(NewsAccountActions.setNewsAccountById(undefined));
    }
  }, [id, dispatch]);

  useEffect(() => {
    setUsername(newsAccountDetail?.username || "");
    setPassword(newsAccountDetail?.password || "");
  }, [newsAccountDetail]);

  const validate = (callback) => {
    const tempEM = {
      username: "",
      password: "",
    };

    let errorCount = 0;

    if (isEmpty(username)) {
      errorCount++;
      tempEM.username = "Username không được để trống";
    }
    if (isEmpty(password)) {
      errorCount++;
      tempEM.password = "Password không được để trống";
    }

    if (errorCount === 0) {
      callback();
    } else {
      setErrorMessage(tempEM);
    }
  };

  const onCreate = () => {
    dispatch(
      NewsAccountActions.create({
        onSuccess: (id) => {
          toast.success("Tạo mới news account thành công");
          navigate(`/services/settings/news-account`);
        },
        body: {
          username,
          password,
        },
      })
    );
  };

  const onEdit = () => {
    dispatch(
      NewsAccountActions.update({
        onSuccess: () => {
          toast.success("Cập nhật news account thành công");
          navigate(`/services/settings/news-account`);

        },
        id: newsAccountDetail._id,
        body: {
          username,
          password,
        },
      })
    );
  };

  return (
    <MST.Container
      title={
        newsAccountDetail
          ? "Cập nhật news account"
          : "Tạo mới news account"
      }
      right={
        <div className="d-flex">
          <MST.Button
            onClick={() => navigate("/services/settings/news-account")}
            type="outlined"
            className="mr-8"
          >
            Huỷ
          </MST.Button>
          <MST.Button
            onClick={
              newsAccountDetail
                ? () => validate(onEdit)
                : () => validate(onCreate)
            }
          >
            Lưu lại
          </MST.Button>
        </div>
      }
    >
      <div className="service-create-content-container">
        <div className="service-create-content-left">
          <div className="service-create-content">
            <div className="service-create-title">
              Thông tin news account
            </div>

            <div className="service-create-one-field">
              <div className="service-create-one-field-name">
                Username<span className="color-red"> *</span>
              </div>
              <div>
                <MST.Input
                  errorMessage={errorMessage?.username}
                  placeholder="Nhập username"
                  maxLength={225}
                  value={username}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      username: "",
                    });
                    setUsername(e.target.value);
                  }}
                  disabled={isEdit}
                />
              </div>
            </div>

            <div className="service-create-one-field">
              <div className="service-create-one-field-name">
                Password<span className="color-red"> *</span>
              </div>
              <div>
                <MST.Input
                  errorMessage={errorMessage?.password}
                  placeholder="Nhập password"
                  value={password}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      password: "",
                    });
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MST.Container>
  );
}

export default NewsAccountCreatePage;