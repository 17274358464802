import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalVisits: 0,
  allData: [],
  filteredData: {
    totalVisits: 0,
    dailyData: [],
  },
  compareData: null,
  comparisonResult: null,
  dateRange: {
    startDate: null,
    endDate: null,
  },
  compareDate: null,
  compareWithDate: null,
  isLoading: false,
};

const visitSlice = createSlice({
  name: "visit",
  initialState,
  reducers: {
    getVisits: (state) => {
      state.isLoading = true;
    },
    getVisitsSuccess: (state, action) => {
      state.isLoading = false;
      state.totalVisits = action.payload.totalVisits;
      state.allData = action.payload.allData;
      state.filteredData = action.payload.filteredData;
      state.compareData = action.payload.compareData;
      state.comparisonResult = action.payload.comparisonResult;
    },
    getVisitsFailure: (state) => {
      state.isLoading = false;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setCompareDate: (state, action) => {
      state.compareDate = action.payload;
    },
    setCompareWithDate: (state, action) => {
      state.compareWithDate = action.payload;
    },
  },
});

export const {
  getVisits,
  getVisitsSuccess,
  getVisitsFailure,
  setDateRange,
  setCompareDate,
  setCompareWithDate,
} = visitSlice.actions;

export default visitSlice.reducer;
