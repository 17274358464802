// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes home-an-container {
  from {
    transform: translateY(20px);
    opacity: 0.1;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.cn-container {
  background-color: #f2f4f5;
  padding: 24px;
  height: 100%;
  margin: 0px 12px 12px 12px;
  border-radius: 20px;
  animation: home-an-container 0.5s ease-in-out;
}

.cn-title-container {
  display: flex;
  margin: 0 28px;
  align-items: center;
  justify-content: space-between;
}

.cn-content {
  background-color: #fff;
  margin-top: 24px;
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/base/container/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,2BAA2B;IAC3B,YAAY;EACd;EACA;IACE,0BAA0B;IAC1B,UAAU;EACZ;AACF;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,0BAA0B;EAC1B,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":["@keyframes home-an-container {\n  from {\n    transform: translateY(20px);\n    opacity: 0.1;\n  }\n  to {\n    transform: translateY(0px);\n    opacity: 1;\n  }\n}\n\n.cn-container {\n  background-color: #f2f4f5;\n  padding: 24px;\n  height: 100%;\n  margin: 0px 12px 12px 12px;\n  border-radius: 20px;\n  animation: home-an-container 0.5s ease-in-out;\n}\n\n.cn-title-container {\n  display: flex;\n  margin: 0 28px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.cn-content {\n  background-color: #fff;\n  margin-top: 24px;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
