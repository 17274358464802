import { toast } from "react-toastify";
import { delay, put, select, takeLatest } from "redux-saga/effects";
import ServiceRequest from "../service/service.request";
import { ServiceActions } from "../service/service.slice";
import PromotionRequest from "./promotion.request";
import { PromotionActions, PromotionSelectors } from "./promotion.slice";

function* PromotionSaga() {
  yield takeLatest(PromotionActions.getPromotions, getPromotions);
  yield takeLatest(PromotionActions.getCreateInfo, getCreateInfo);
  yield takeLatest(PromotionActions.getPromotionById, getPromotionById);
  yield takeLatest(PromotionActions.createPromotion, createPromotion);
  yield takeLatest(PromotionActions.setSearch, setSearch);
  yield takeLatest(PromotionActions.edit, edit);
  yield takeLatest(PromotionActions.delete, deletePromotion);
}

export default PromotionSaga;

function* getPromotions({ payload = {} }) {
  try {
    const pagination = yield select(PromotionSelectors.pagination);
    const search = yield select(PromotionSelectors.search);
    const status = yield select(PromotionSelectors.status);
    const services = yield select(PromotionSelectors.servicesSearch);

    const servicesParam = Array.isArray(services) ? services.join(",") : "";

    const rs = yield PromotionRequest.getPromotion({
      search: search.length === 0 ? undefined : search,
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
    if (rs.success) {
      yield put(PromotionActions.setPromotions(rs.data.promotions));
      yield put(PromotionActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getPromotionById({ payload }) {
  try {
    yield delay(100);
    const rs = yield PromotionRequest.getPromotionById(payload.id);
    if (rs.success) {
      yield put(PromotionActions.setPromotionDetail(rs.data));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getCreateInfo() {
  try {
    yield delay(100);
    const rsServicePackages = yield ServiceRequest.getServices();
    if (rsServicePackages.success) {
      yield put(
        ServiceActions.setServices(
          rsServicePackages.data.servicePackages.map((x) => {
            return {
              name: x.name,
              value: x._id,
            };
          })
        )
      );
    }
  } catch (error) {
    toast.error(error);
  }
}

function* createPromotion({ payload }) {
  try {
    yield delay(100);
    const { onSuccess, body } = payload;
    const rs = yield PromotionRequest.createPromotion(body);
    if (rs.success) {
      onSuccess(rs.data._id);
    } else {
      throw new Error(rs.message || "Có lỗi khi tạo promotion");
    }
  } catch (error) {
    console.log({ error });

    let errorMessage = "Có lỗi khi tạo promotion";

    if (error.response && error.response.data) {
      errorMessage =
        error.response.data.message ||
        error.response.data.error ||
        errorMessage;
    } else if (error.message) {
      errorMessage = error.message;
    }

    toast.error(errorMessage);
  }
}

function* edit({ payload }) {
  try {
    yield delay(100);
    const { id, body, onSuccess } = payload;
    const rs = yield PromotionRequest.updatePromotion(id, body);
    console.log(id);

    if (rs.success) {
      yield put(PromotionActions.getPromotions());
      onSuccess();
    } else {
      throw rs.message;
    }
  } catch (error) {
    console.log(error);
    toast.error(error.toString());
  }
}

function* deletePromotion({ payload }) {
  const { id, onSuccess } = payload;
  const rs = yield PromotionRequest.deletePromotion(id);
  if (rs.success) {
    onSuccess();
  } else {
    throw rs.message;
  }
  try {
  } catch (error) {
    toast.error(error);
  }
}

function* setSearch({ payload }) {
  try {
    yield delay(300);
    const pagination = yield select(PromotionSelectors.pagination);
    const rs = yield PromotionRequest.getPromotion({
      search: payload,
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
    if (rs.success) {
      yield put(PromotionActions.setPromotions(rs.data.promotions));
      yield put(PromotionActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    yield put(PromotionActions.setPromotions([]));
    toast.error(error);
  }
}
