import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  OrderActions,
  OrderSelectors,
} from "../../app/services/order/order.slice";
import {
  ServiceActions,
  ServiceSelectors,
} from "../../app/services/service/service.slice";
import MST from "../../components";
import Modal from "../../components/base/modal/Modal";
import CloseIcon from "../../images/icons/CloseIcon";
import SearchIcon from "../../images/icons/SearchIcon";
import OrderFilterModal from "./Order.FilterModal";
import FilterIcon from "./icons/FilterIcon";
import "./style.css";
import {
  CheckLinkActions,
  CheckLinkSelectors,
} from "../../app/services/check-link/check-link.slice";
import OrderStatusFilter from "./OrderStatusFilter";
import { Button, CircularProgress, Box } from "@mui/material";

const DownIcon = ({ size = 20 }) => {
  return (
    <svg viewBox="0 0 320 512" width={size} height={size}>
      <path
        fill="currentColor"
        d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
      />
    </svg>
  );
};

const CheckIcon = ({ size = 20 }) => {
  return (
    <svg viewBox="0 0 448 512" width={size} height={size}>
      <path
        fill="currentColor"
        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
      />
    </svg>
  );
};

const TrashIcon = ({ size = 20 }) => {
  return (
    <svg viewBox="0 0 448 512" width={size} height={size}>
      <path
        fill="currentColor"
        d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z"
      />
    </svg>
  );
};

const Checkbox = ({
  id,
  label,
  value = false,
  onChange = () => {},
  size = 24,
}) => {
  return (
    <>
      <input
        id={`checkbox-${id}`}
        type="checkbox"
        value={value}
        style={{ cursor: "pointer", display: "none" }}
        onChange={() => onChange()}
      />
      <div
        style={{
          width: size,
          aspectRatio: "1",
          borderRadius: "2px",
          background: value ? "#ff8900" : "#FFF",
          cursor: "pointer",
          transition: "all 0.2s",
          border: "2px solid #ff8900",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: value ? "#FFF" : "transparent",
        }}
        onClick={() => onChange()}
      >
        <CheckIcon size={14} />
      </div>
      <label
        style={{
          fontSize: "0.8rem",
          display: "inline-block",
          width: "100%",
          cursor: "pointer",
          textTransform: "capitalize",
        }}
        htmlFor={`checkbox-${id}`}
      >
        {label}
      </label>
    </>
  );
};

const CopyIcon = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MultiSelectServicesFilter = ({ searchText = "" }) => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const services = useSelector(ServiceSelectors.serviceList);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const listCheckboxRef = useRef();
  const [selectedServices, setSelectedServices] = useState([]);

  const ref = useRef();

  useEffect(() => {
    dispatch(
      ServiceActions.setPagination({
        page: 1,
        pageSize: 999999999,
        total: 0,
        totalPage: 0,
      })
    );
    dispatch(ServiceActions.getServices());

    const handleClickOutSide = (e) => {
      if (ref && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutSide);

    return () => {
      window.removeEventListener("click", handleClickOutSide);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedServices.length == 0) {
      dispatch(OrderActions.setServicesSearch(undefined));
    } else {
      dispatch(OrderActions.setServicesSearch([...selectedServices]));
    }
    dispatch(OrderActions.getOrders(searchText));
  }, [selectedServices]);

  const handleToggle = (e) => {
    if (inputRef.current.contains(e.target)) {
      inputRef.current.focus();
      setIsOpen(true);
      return;
    }
    if (isOpen && listCheckboxRef.current.contains(e.target)) {
      inputRef.current.focus();
      return;
    }
    setIsOpen((_prev) => !_prev);
  };
  return (
    <>
      <div
        ref={ref}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxHeight: "50px",
          boxSizing: "border-box",
          padding: "0 12px",
          height: "100%",
          borderRadius: "8px",
          maxWidth: "200px",
          border: `1px solid ${isOpen ? `#ff8900` : `#e3e5e5`}`,
          transition: "all 0.2s",
        }}
        onClick={handleToggle}
      >
        <input
          style={{
            width: "100%",
            fontSize: "14px",
            marginRight: "12px",
            border: "none",
            outline: "none",
          }}
          placeholder="Chọn loại dịch vụ"
          readOnly={true}
          type="text"
          value={selectedServices.join(", ")}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: isOpen ? "#ff8900" : "#e3e5e5",
            transition: "all 0.2s",
          }}
        >
          <DownIcon />
        </div>

        <div
          ref={listCheckboxRef}
          style={{
            position: "absolute",
            top: "calc(100% + 8px)",
            left: "0",
            right: "0",
            padding: "0 0 8px 0",
            borderRadius: "8px",
            background: "#FFF",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            maxHeight: "200px",
            overflow: "auto",
            display: `${isOpen ? `block` : `none`}`,
            zIndex: 10,
          }}
        >
          <div
            style={{
              position: "sticky",
              left: 0,
              right: 0,
              top: 0,
              boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#FFF",
              padding: "2px",
            }}
          >
            <input
              ref={inputRef}
              style={{
                boxSizing: "border-box",
                width: "100%",
                fontSize: "14px",
                border: "none",
                outline: "none",
                padding: "12px 8px 8px 8px",
              }}
              placeholder="Tìm kiếm theo loại dịch vụ"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid red",
                borderRadius: "4px",
                width: "32px",
                aspectRatio: 1,
                cursor: "pointer",
                color: "#FFF",
                background: "red",
              }}
              onClick={() => setSelectedServices([])}
            >
              <TrashIcon />
            </button>
          </div>
          {services.map((item) => {
            if (
              item.name
                .toLowerCase()
                .trim()
                .includes(search.toLowerCase().trim()) ||
              item.scriptGroupCode
                .toLowerCase()
                .trim()
                .includes(search.toLowerCase().trim()) ||
              `${item.scriptGroupCode} - ${item.name}`
                .toLowerCase()
                .trim()
                .includes(search.toLowerCase().trim())
            ) {
              return (
                <div
                  style={{
                    padding: "10px 12px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "12px",
                  }}
                  key={item._id}
                >
                  <Checkbox
                    id={item._id}
                    value={selectedServices.includes(item.name)}
                    onChange={() => {
                      if (selectedServices.includes(item.name)) {
                        setSelectedServices((_prev) =>
                          _prev.filter((e) => e != item.name)
                        );
                      } else {
                        setSelectedServices((_prev) => [..._prev, item.name]);
                      }
                    }}
                    label={`${item.scriptGroupCode} - ${item.name}`}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
};

const YouTubeChannelInfo = ({ channelInfo }) => {
  const [copied, setCopied] = useState(false);

  if (!channelInfo) return null;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(channelInfo.id).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="youtube-channel-info w-full max-w-2xl mx-auto bg-gradient-to-r from-red-500 to-red-700 rounded-xl shadow-2xl overflow-hidden">
      <div className="channel-header p-2 flex items-center space-x-4">
        <img
          src={channelInfo.thumbnailUrl}
          alt={channelInfo.title}
          className="w-20 h-20 rounded-full border-4 border-white shadow-lg"
        />
        <div className="flex flex-row flex-grow justify-between">
          <div>
            <h2 className="text-2xl font-bold text-white">
              {channelInfo.title}
            </h2>
            <p className="text-red-100 text-sm">YouTube Channel</p>
          </div>
          <div className="flex items-center justify-between bg-white bg-opacity-10 rounded p-2 relative group">
            <div>
              <p className="text-xs text-red-100">Channel ID</p>
              <p className="text-sm text-white font-semibold">
                {channelInfo.id}
              </p>
            </div>
            <button
              onClick={copyToClipboard}
              className="text-white opacity-50 group-hover:opacity-100 transition-opacity duration-200 ml-2"
              title="Copy Channel ID"
            >
              <CopyIcon />
            </button>
            {copied && (
              <div className="absolute -bottom-6 left-0 right-0 text-center">
                <p className="text-xs text-green-300 bg-black bg-opacity-50 rounded py-1 px-2 inline-block">
                  Copied to clipboard!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="channel-body bg-white p-2">
        <p className="text-gray-600 text-sm mb-4 line-clamp-3">
          {channelInfo.description}
        </p>
        <div className="channel-stats grid grid-cols-3 gap-4 mb-2">
          <div className="stat-item text-center">
            <p className="text-2xl font-bold text-red-600">
              {parseInt(channelInfo.subscriberCount).toLocaleString()}
            </p>
            <p className="text-xs text-gray-500 uppercase">Subscribers</p>
          </div>
          <div className="stat-item text-center">
            <p className="text-2xl font-bold text-red-600">
              {parseInt(channelInfo.videoCount).toLocaleString()}
            </p>
            <p className="text-xs text-gray-500 uppercase">Videos</p>
          </div>
          <div className="stat-item text-center">
            <p className="text-2xl font-bold text-red-600">
              {parseInt(channelInfo.viewCount).toLocaleString()}
            </p>
            <p className="text-xs text-gray-500 uppercase">Views</p>
          </div>
        </div>
        <a
          href={`https://www.youtube.com/channel/${channelInfo.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full text-center bg-red-600 text-white py-3 rounded-lg font-semibold transition duration-300 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
        >
          Visit Channel
        </a>
      </div>
    </div>
  );
};

function OrderSearch() {
  const search = useSelector(OrderSelectors.search);
  const searchNumberOrder = useSelector(OrderSelectors.searchNumberOrder);
  const currentCustomer = useSelector(OrderSelectors.currentCustomer);
  const dispatch = useDispatch();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const onHideFilter = () => setIsShowFilter(false);
  const [link, setLink] = useState("");
  const [isShowCheckLinkModal, setIsShowCheckLinkModal] = useState(false);
  const [errorMessageCheckLinkModal, setErrorMessageCheckLinkModal] = useState({
    link: "",
  });
  const channelInfo = useSelector(CheckLinkSelectors.channelInfo);
  const loading = useSelector(CheckLinkSelectors.loading);
  const error = useSelector(CheckLinkSelectors.error);
  const isLoading = useSelector(OrderSelectors.loading);


  const validateLinkInput = (callback) => {
    const tempEM = { link: "" };
    let errorCount = 0;
    if (!link) {
      errorCount++;
      tempEM.link = "Link không được để trống";
    }
    if (errorCount === 0) {
      callback();
    } else {
      setErrorMessageCheckLinkModal(tempEM);
    }
  };

  const handleCheckLinkYoutube = () => {
    validateLinkInput(() => {
      dispatch(CheckLinkActions.checkLinkYoutube(link));
    });
  };

  const handleOpenCheckLinkModal = () => {
    setIsShowCheckLinkModal(true);
    setErrorMessageCheckLinkModal({ link: "" });
  };

  useEffect(() => {
    return () => {
      dispatch(CheckLinkActions.resetState());
    };
  }, [dispatch, isShowCheckLinkModal]);

  const checkLinkYoutubeModal = (
    <div style={{ width: "600px", height: "fit-content" }}>
      <div className="modal-header">Check link Youtube</div>
      <div className="modal-body">
        <div style={{ marginBottom: "8px" }}>Nhập link:</div>
        <p
          style={{
            display: "flex",
            flexDirection: "row",
            justifyItems: "center",
            gap: "10px",
          }}
        >
          <p style={{ width: "80%" }}>
            <MST.Input
              errorMessage={errorMessageCheckLinkModal?.link}
              type="text"
              placeholder="Nhập link check"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
            />
          </p>
          <p>
            <MST.Button onClick={handleCheckLinkYoutube} disabled={loading}>
              {loading ? "Checking..." : "Check"}
            </MST.Button>
          </p>
        </p>
      </div>
      <div className="modal-body">
        {loading && <p>Loading...</p>}
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {channelInfo && (
          <div style={{ marginTop: "-20px" }}>
            <p style={{ marginBottom: "8px", textAlign: "center" }}>
              Thông tin kênh
            </p>
            <YouTubeChannelInfo channelInfo={channelInfo} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex gap-4 items-center">
        {/* <MST.Button
          onClick={() => {
            dispatch(OrderActions.checkYouTubeSubscriberOrders());
          }}
          // type="primary"
        >
          Kiểm tra đơn hàng YouTube
        </MST.Button> */}

        <Box sx={{ m: 1, position: "relative" }}>
          <MST.Button
            disabled={isLoading}
            onClick={() => {
              dispatch(OrderActions.checkYouTubeSubscriberOrders());
            }}
          >
            Kiểm tra đơn hàng YouTube
          </MST.Button>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                // color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>

        <MST.Button onClick={handleOpenCheckLinkModal}>
          Check link YouTube
        </MST.Button>
        <div style={{ marginRight: "10px" }}>
          <OrderStatusFilter />
        </div>
        <div
          style={{
            marginLeft: "12px",
          }}
        >
          {currentCustomer ? (
            <MST.Button
              onClick={() => {
                dispatch(OrderActions.setCurrentCustomer(undefined));
                dispatch(OrderActions.getOrders());
              }}
              type="outlined"
              icon={
                <div className="d-flex ai-center mr-8">
                  <CloseIcon />
                </div>
              }
            >
              <span className="text-primary">{currentCustomer.email}</span>
            </MST.Button>
          ) : (
            <MST.Button
              style={{
                height: 50,
              }}
              onClick={() => {
                setIsShowFilter(true);
              }}
              type="outlined"
              icon={
                <div className="d-flex ai-center mr-8">
                  <FilterIcon />
                </div>
              }
            >
              Bộ lọc
            </MST.Button>
          )}
        </div>
      </div>
      <div className="search-order-container">
        <OrderFilterModal isShow={isShowFilter} onHide={onHideFilter} />
        <div className="search-order-input">
          <MST.Input
            value={search}
            onChange={(e) => dispatch(OrderActions.setSearch(e.target.value))}
            placeholder={"Nhập từ khóa, mã đơn hàng, link để tìm kiếm"}
            right={
              <div className="service-search-btn">
                <SearchIcon />
              </div>
            }
          />
        </div>
        <div className="search-order-input">
          <MST.Input
            type="Number"
            value={searchNumberOrder}
            onChange={(e) =>
              dispatch(OrderActions.setSearchNumberOrder(e.target.value))
            }
            placeholder={"Nhập số thứ tự để tìm kiếm"}
            right={
              <div className="service-search-btn">
                <SearchIcon />
              </div>
            }
          />
        </div>

        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MultiSelectServicesFilter searchText={search} />
        </div>
      </div>
      <div>
        <Modal
          content={checkLinkYoutubeModal}
          isShow={isShowCheckLinkModal}
          onHide={() => setIsShowCheckLinkModal(false)}
        />
      </div>
    </div>
  );
}

export default OrderSearch;
