import { takeLatest, call, put } from "redux-saga/effects";
import { CheckLinkActions } from "./check-link.slice";
import CheckLinkRequest from "./check-link.request";

function* CheckLinkSaga() {
  yield takeLatest(CheckLinkActions.checkLinkYoutube.type, checkLinkYoutube);
}

function* checkLinkYoutube({ payload }) {
  try {
    const response = yield call(CheckLinkRequest.checkLinkYoutube, payload);
    if (response.success && response.data.channelId) {
      yield put(CheckLinkActions.setChannelId(response.data.channelId));
      yield call(getChannelInfo, response.data.channelId);
    } else {
      yield put(CheckLinkActions.setError("Không thể lấy được Channel ID"));
    }
  } catch (error) {
    yield put(CheckLinkActions.setError(error.message));
  }
}

function* getChannelInfo(channelId) {
  try {
    const response = yield call(CheckLinkRequest.getChannelInfo, channelId);
    if (response.data.items && response.data.items.length > 0) {
      const channelInfo = response.data.items[0];
      yield put(
        CheckLinkActions.setChannelInfo({
          id: channelInfo.id,
          title: channelInfo.snippet.title,
          description: channelInfo.snippet.description,
          thumbnailUrl: channelInfo.snippet.thumbnails.default.url,
          subscriberCount: channelInfo.statistics.subscriberCount,
          videoCount: channelInfo.statistics.videoCount,
          viewCount: channelInfo.statistics.viewCount,
        })
      );
    } else {
      yield put(CheckLinkActions.setError("Không tìm thấy thông tin kênh"));
    }
  } catch (error) {
    yield put(CheckLinkActions.setError("Lỗi khi lấy thông tin kênh"));
  }
}

export default CheckLinkSaga;
