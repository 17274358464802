// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-setting-content {
}
.general-setting-general-container {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.general-setting-support-container {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.general-setting-title {
  color: var(--Dark-600, #090a0a);
  font-size: 16px;
  line-height: 24px; /* 150% */
  font-weight: 500;
}
.general-setting-input-small-description {
  color: var(--Dark-100, #72777a);
  text-align: center;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
}
.general-setting-required {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/pages/general-setting/style.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,iBAAiB,EAAE,SAAS;EAC5B,gBAAgB;AAClB;AACA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,eAAe;EACf,iBAAiB,EAAE,aAAa;AAClC;AACA;EACE,UAAU;AACZ","sourcesContent":[".general-setting-content {\n}\n.general-setting-general-container {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n  margin-bottom: 20px;\n}\n\n.general-setting-support-container {\n  background-color: white;\n  padding: 24px;\n  border-radius: 12px;\n  margin-bottom: 20px;\n}\n\n.general-setting-title {\n  color: var(--Dark-600, #090a0a);\n  font-size: 16px;\n  line-height: 24px; /* 150% */\n  font-weight: 500;\n}\n.general-setting-input-small-description {\n  color: var(--Dark-100, #72777a);\n  text-align: center;\n  font-size: 14px;\n  line-height: 20px; /* 142.857% */\n}\n.general-setting-required {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
