import React from "react";

const SVGsendMessage = (props) => {
  return (
    <svg
      width="35px"
      height="35px"
      viewBox="-3.6 -3.6 31.20 31.20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#f2f2f2"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0">
        <rect
          x="-3.6"
          y="-3.6"
          width="31.20"
          height="31.20"
          rx="4.68"
          fill="#0966d2"
          strokeWidth="0"
        ></rect>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M9.51002 4.23001L18.07 8.51001C21.91 10.43 21.91 13.57 18.07 15.49L9.51002 19.77C3.75002 22.65 1.40002 20.29 4.28002 14.54L5.15002 12.81C5.37002 12.37 5.37002 11.64 5.15002 11.2L4.28002 9.46001C1.40002 3.71001 3.76002 1.35001 9.51002 4.23001Z"
          stroke="#ffffff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
        <path
          d="M5.44 12H10.84"
          stroke="#ffffff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
};

export default SVGsendMessage;
