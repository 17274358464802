import React, { useState } from "react";
import MST from "../../components";
import Modal from "../../components/base/modal/Modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { OrderActions } from "../../app/services/order/order.slice";

const OrderRefundAction = ({
  open,
  setOpen,
  isLoading = false,
  order = {},
  setOrder,
}) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");

  const handleSubmit = () => {
    if (!input || input < 0) {
      toast.warn("Vui nhập lại số tiền hoàn");
      return;
    }
    const data = {
      amount: Number(input),
      code: order?.code,
      userId: order?.customer?._id,
    };
    dispatch(
      OrderActions.handleRefundOrder({
        data,
        onSuccess: () => {
          setOpen(false);
          setOrder({});
          setInput("");
        },
      })
    );
  };

  const handleClose = () => {
    setOpen(false);
    setOrder({});
    setInput("");
  };

  const ActionContent = (
    <div className="flex flex-col h-full max-h-[80vh]">
      <div className="p-4">
        <h2 className="text-lg font-semibold">
          Hoàn tiền cho đơn hàng ({order?.code}) - ({order?.customer?.email})
        </h2>
      </div>
      <div>
        <div className="mb-2">
          Nhập số tiền hoàn
          <span className="text-red-500"> *</span>
        </div>
        <MST.Input
          value={input.amount}
          placeholder="Nhập số tiền hoàn..."
          type="number"
          onChange={(e) => setInput(e.target.value)}
          right={<div className="mr-8">VND</div>}
        />
      </div>
      <div className="flex gap-4 items-center justify-end mt-10">
        <MST.Button disabled={isLoading} type="outlined" onClick={handleClose}>
          Đóng
        </MST.Button>
        <MST.Button disabled={isLoading} onClick={handleSubmit}>
          Hoàn tiền ngay
        </MST.Button>
      </div>
    </div>
  );

  return (
    <Modal
      contentWidth={600}
      isShow={open}
      onHide={handleClose}
      content={ActionContent}
    />
  );
};
export default OrderRefundAction;
