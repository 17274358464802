import { StarIcon } from "@heroicons/react/24/solid";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CustomerActions,
  CustomerSelectors,
} from "../../app/services/customer/customer.slice";
import { formatPriceVND } from "../../app/utils/format";
import MST from "../../components";
import CustomerDetailAggregate from "./CustomerDetail.Aggregate";
import CustomerDetailPaymentActivityList from "./CustomerDetail.PaymentActivityList";
import CustomerDetailRecharge from "./CustomerDetail.Recharge";
import UpdateVipLevelModal from "./CustomerDetail.UpdateVipLevelModal";
import CustomerDetailSearch from "./CustomerDetailSearch";
import BlockIcon from "./icons/BlockIcon";
import IdIcon from "./icons/IdIcon";
import MoneyIcon from "./icons/MoneyIcon";
import PhoneIcon from "./icons/PhoneIcon";
import ReasonIcon from "./icons/ReasonIcon";
import RestoreIcon from "./icons/RestoreIcon";
import StatusIcon from "./icons/StatusIcon";
import "./style.css";

function CustomerDetailPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const customerDetail = useSelector(CustomerSelectors.customerDetail);
  const userInfo = useSelector((state) => state.login.userInfo);

  const isRoleSupport = userInfo?.role === "support";

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isShowConform, setIsShowConform] = useState(false);
  const [isShowModalUpdateLevel, setIsShowModalUpdateLevel] = useState(false);

  const [tab, setTab] = useState(1);
  const handleStartDateChange = (date) => {
    if (date === null) {
      setStartDate(null);
      setEndDate(null);
    } else {
      const newStartDate = setDateWithTime(date, 0, 0, 0, 0);
      setStartDate(newStartDate);
      if (endDate < newStartDate) {
        setEndDate(setDateWithTime(newStartDate, 23, 59, 59, 999));
      }
    }
  };
  const setDateWithTime = useCallback(
    (date, hours, minutes, seconds, milliseconds) => {
      const newDate = new Date(date);
      newDate.setHours(hours, minutes, seconds, milliseconds);
      return newDate;
    },
    []
  );
  const handleEndDateChange = (date) => {
    if (date === null) {
      setEndDate(null);
    } else {
      setEndDate(setDateWithTime(date, 23, 59, 59, 999));
    }
  };
  useEffect(() => {
    getDetail();
    dispatch(CustomerActions.setSearchCodeOrder(""));
    dispatch(CustomerActions.setEndDate(""));
    dispatch(CustomerActions.setStartDate(""));
    return () => {
      dispatch(
        CustomerActions.setPaginationPaymentActivities({
          page: 1,
          pageSize: 10,
          total: 0,
          totalPage: 0,
        })
      );
    };
  }, []);

  const getDetail = () => {
    dispatch(CustomerActions.getCustomerById(id));
  };
  const handleFilterDay = () => {
    dispatch(CustomerActions.setEndDate(endDate));
    dispatch(CustomerActions.setStartDate(startDate));
  };
  const renderHandleDetailCustomer = (
    <div className="customer-detail-btn-container">
      <div className="customer-detail-btn-area">
        <MST.Button
          style={{ marginRight: 8 }}
          onClick={() => setIsShowModalUpdateLevel(true)}
        >
          Thay đổi cấp độ VIP
        </MST.Button>
        <CustomerDetailRecharge id={customerDetail?._id} />
        {customerDetail?.status !== "blocked" ? (
          <MST.Button
            onClick={() => setIsShowConform(true)}
            icon={
              <div className="d-flex" style={{ marginRight: 8 }}>
                <BlockIcon />
              </div>
            }
            style={{
              backgroundColor: "#FF5247",
            }}
          >
            Khóa tài khoản
          </MST.Button>
        ) : (
          <MST.Button
            type="outline"
            onClick={() => setIsShowConform(true)}
            icon={
              <div className="d-flex" style={{ marginRight: 8 }}>
                <RestoreIcon />
              </div>
            }
          >
            Khôi phục tài khoản
          </MST.Button>
        )}
      </div>
    </div>
  );

  return (
    <MST.Container title={"Chi tiết khách hàng"}>
      <div className="customer-detail-info-container">
        <div className="d-flex ai-center">
          <span className="customer-detail-title">{customerDetail?.email}</span>
        </div>
        <div className="d-flex ai-center pt-10">
          <IdIcon />
          <span className="customer-detail-label">{customerDetail?._id}</span>
        </div>
        <div className="d-flex ai-center pt-10">
          <PhoneIcon />
          <span className="customer-detail-label">
            {customerDetail?.phoneNumber}
          </span>
        </div>
        <div className="d-flex ai-center pt-10">
          <StatusIcon />
          <span className="customer-detail-label">
            {cdGenLabel(customerDetail?.status)}
          </span>
        </div>
        <div className="d-flex ai-center pt-10">
          <MoneyIcon />
          <span className="customer-detail-label">Số dư hiện tại:</span>
          <span className="customer-detail-balance">
            {formatPriceVND(customerDetail?.balance)}đ
          </span>
        </div>
        <div className="d-flex ai-center pt-10">
          <StarIcon width={18} height={18} color="#FF8900" />
          <span className="customer-detail-label">Cấp VIP:</span>
          <span className="customer-detail-balance">
            {customerDetail?.vipLevel || 0}
          </span>
        </div>
        {customerDetail?.status !== "blocked" ? (
          <></>
        ) : (
          <div className="d-flex ai-center pt-10">
            <ReasonIcon />
            <span className="customer-detail-block">
              Tài khoản bị khóa vì lý do: {customerDetail?.reasonBlock}
            </span>
          </div>
        )}

        {!isRoleSupport ? renderHandleDetailCustomer : <></>}
      </div>
      <div className="mt-20 customer-detail-info-container">
        <CustomerDetailSearch />
        <div className="flex justify-start gap-5 items-center">
          <MST.CustomDatePicker
            label=""
            selectedDate={startDate}
            maxDate={endDate}
            onChange={handleStartDateChange}
          />
          <MST.CustomDatePicker
            label=""
            selectedDate={endDate}
            minDate={startDate}
            onChange={handleEndDateChange}
          />
          <MST.Button
            className="service-search-btn max-h-[42px]"
            onClick={handleFilterDay}
          >
            Lọc
          </MST.Button>
        </div>
        <div className="flex justify-start items-center gap-4">
          <span
            className={`customer-detail-title cursor-pointer duration-200 ${
              tab === 1 ? "opacity-100" : "opacity-50"
            }`}
            onClick={() => {
              if (tab !== 1) {
                setTab(1);
              }
            }}
          >
            Biến động số dư
          </span>
          <span
            className={`customer-detail-title cursor-pointer duration-200 ${
              tab === 2 ? "opacity-100" : "opacity-50"
            }`}
            onClick={() => {
              if (tab !== 2) {
                setTab(2);
              }
            }}
          >
            Thống kê dịch vụ
          </span>
        </div>
        <div className="w-full overflow-x-hidden">
          <div
            className={`w-[200%] bg-white inset-0 duration-200 flex justify-start items-start ${
              tab === 1 ? `translate-x-0` : `-translate-x-1/2`
            }`}
          >
            {tab === 1 ? (
              <div className="w-1/2 mr-auto">
                <CustomerDetailPaymentActivityList
                  setIsShowConform={setIsShowConform}
                  isShowConform={isShowConform}
                  id={id}
                  customerDetail={customerDetail}
                />
              </div>
            ) : (
              <div className="w-1/2 ml-auto">
                <CustomerDetailAggregate />
              </div>
            )}
          </div>
        </div>
        <MST.Modal
          isShow={isShowModalUpdateLevel}
          content={
            <UpdateVipLevelModal
              id={id}
              customerDetail={customerDetail}
              onHide={() => setIsShowModalUpdateLevel(false)}
            />
          }
          onHide={() => {
            setIsShowModalUpdateLevel(false);
          }}
        />
      </div>
    </MST.Container>
  );
}

export default CustomerDetailPage;

export function cdGenColor(label) {
  switch (label) {
    case "success":
      return "#23C16B";
    default:
      return "red";
  }
}
export function cdGenLabel(label) {
  switch (label) {
    case "recharge":
      return "Nạp tiền";
    case "order":
      return "Đặt hàng";
    case "success":
      return "Thành công";
    case "active":
      return "Đang hoạt động";
    case "blocked":
      return "Đã khoá";
    default:
      return label;
  }
}
